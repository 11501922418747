import React, { useState } from "react";
import { Initiative } from "@s360/common-models/initiative";
import InitiativeInfo from "./InitiativeInfo/InitiativeInfo";
import InitiativeDetails from "./InitiativeDetails/InitiativeDetails";
import { IonButton, IonModal, IonIcon, IonAlert, IonLabel, IonPopover, IonItem, IonList } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import "../css/InitiativeList.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/app.scss';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { stringShortenerEllipsis } from '../helper/StringShortenerEllipsis';
import { dateConverter } from '../helper/DateConverter';
import DisplayTable from "./DisplayTable";

const getCategoryColor = (category: string) => {
  switch (category) {
    case 'Economy': {
      return 'blue';
    }
    case 'Governance': {
      return 'blue';
    }
    case 'Environment': {
      return 'green';
    }
    case 'Collaboration': {
      return 'purple';
    }
    default: {
      return 'orange';
    }
  }
};


const columnWidths: string[] = ['55%', '10%', '20%', '8%', '6%', '1%']
const columnIds: string[] = [
  'initiative-status',
  'initiative-category',
  'initiative-target-date',
  'initiative-progress-bar',
  'initiative-progress-value',
  'initiative-edit-settings',
]

const getTableTitleStyle = (status: string) => {
  switch (status) {
    case 'IN PROGRESS': {
      return ["white", "green"];
    }
    case 'NEW': {
      return ["green", "#ccffdc"];
    }
    case 'REVIEWED': {
      return ["#f84ed9", "#ccffdc"];
    }
    case 'SCOPED': {
      return ["#ffaf0f", "#ccffdc"];
    }
    case 'ON HOLD': {
      return ["#999192", "#ccffdc"];
    }
    case 'IMPLEMENTED': {
      return ["blue", "#ccffdc"];
    }
    case 'CANCELLED': {
      return ["darkred", "#ccffdc"];
    }
    default: {
      return ["green", "#ccffdc"];
    }
  }
}

const getProgressValue = (initiative: Initiative) => {
  let progress: number = 0;
  switch (initiative.trackingStatus) {
    case 'In Progress':
      progress = initiative.progress ? Number(initiative.progress.replace('%', '')) : 0;
      break;
    case 'Implemented':
      progress = 100;
      break;
  }
  return progress;
};

const getCategoryLabel = (initiative: Initiative) => {
  return (
    <IonLabel id="initiative-category-label"
      style={{ backgroundColor: getCategoryColor(initiative.category + '') }}>
      {initiative.category === "Economy" ? "Governance" : initiative.category}
    </IonLabel>
  )
};

const checkTargetDateDue = (date: Date) => {
  return (new Date() >= date);
};

const getTargetDateStyle = (date: Date) => {
  let style = 'dark';
  if (checkTargetDateDue(date)) style = 'danger';
  return style;
}

const getTargetDateUI = (initiative: Initiative) => {
  if (!initiative.targetDate) return (<IonLabel id="no-target-date">——</IonLabel>);
  let targetDate = new Date(initiative.targetDate);
  return (<IonLabel color={getTargetDateStyle(targetDate)}>{dateConverter(targetDate)}</IonLabel>);
};

const getInitiativeProgressValue = (initiative: Initiative): React.ReactElement => {
  return (<IonLabel>{getProgressValue(initiative)}%</IonLabel>)
}

const getInitiativeProgressBar = (initiative: Initiative): React.ReactElement => {
  return (<ProgressBar striped variant="info" now={getProgressValue(initiative)} />)
}

const getInitiativeTargetDate = (initiative: Initiative): React.ReactElement => {
  return (<>{getTargetDateUI(initiative)}</>)
}

const getInitiativeCategory = (initiative: Initiative): React.ReactElement => {
  return (<>{getCategoryLabel(initiative)}</>)
}


const InitiativesList: React.FC<{
  displayedInitiatives: Initiative[];
  editAction: (initiative: Initiative) => void;
  deleteAction: (Initiative: Initiative) => void;
  userIsAdmin: boolean;
  initiativeFetchRequest: boolean;
  isLoggedIn: () => boolean;
  status: string;
  shownItems: number;
  setShownItems: (status: string, items: number) => void;
}> = (props) => {
  const titleMaxLength: number = 60;
  const maximumDisplayedInitiatives: number = 3;
  const [showModal, setShowModal] = useState(false);
  const [targetInitiative, setTargetInitiative] = useState<Initiative | undefined>(undefined);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined,
  });
  const columnHeaders: string[] = [props.status, "CATEGORY", "TARGET DATE", "PROGRESS", "", ""]

  const generateShowMoreItems = () => {
    return (props.displayedInitiatives.length >= 3 &&
      (<IonLabel className="text-right" id="show-more"
        onClick={() => {
          props.setShownItems(props.status, props.displayedInitiatives.length <= maximumDisplayedInitiatives ? 0 : maximumDisplayedInitiatives)
        }}>
        {props.displayedInitiatives.length <= maximumDisplayedInitiatives ? "Show All" : "Show Less"}
      </IonLabel>)
    )
  }

  const getInitiativeEditSettings = (initiative: Initiative): React.ReactElement => {
    return (<>
      {props.userIsAdmin && (<td>
        <IonButton color="light" onClick={(e) => {
          setTargetInitiative(initiative);
          setShowPopover({ open: true, event: e.nativeEvent });
        }
        }>
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </IonButton>
        <IonPopover key={initiative.id + ''}
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
          showBackdrop={false}
        >
          <IonList key={initiative.id + ''}>
            <IonItem key={initiative.id + '_show'} button onClick={() => {
              setShowPopover({ open: false, event: undefined })
              setShowModal(true);
            }}>Show Detail</IonItem>
            {props.userIsAdmin && (
              <IonItem key={initiative.id + '_edit'} button onClick={() => {
                setShowPopover({ open: false, event: undefined })
                
                props.editAction(targetInitiative!);
              }}>Edit</IonItem>
            )}
            {props.userIsAdmin && (
              <IonItem key={initiative.id + '_del'} button onClick={() => {
                setShowPopover({ open: false, event: undefined })
                setShowDeleteAlert(true);
              }}>Delete</IonItem>
            )}
          </IonList>
        </IonPopover>
      </td>)}
    </>)
  }

  const getInitiativeTitle = (initiative: Initiative): React.ReactElement => {
    return (
      <IonLabel id="initiative-item-title" className="titleToolTip text-left"
        onClick={() => {
          setTargetInitiative(initiative);
          setShowModal(true);
        }}>
        {stringShortenerEllipsis(initiative.title, titleMaxLength)}
        <span className="titleToolTipText">{initiative.title}</span>
         - {initiative.id}
      </IonLabel>
    )
  }

  const rowInfoGetters: ((initiative: Initiative) => React.ReactElement)[] = [
    getInitiativeTitle,
    getInitiativeCategory,
    getInitiativeTargetDate,
    getInitiativeProgressBar,
    getInitiativeProgressValue,
    getInitiativeEditSettings,
  ]


  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <DisplayTable
        items={props.displayedInitiatives}
        headers={columnHeaders}
        rowInfoGetters={rowInfoGetters}
        customColumnId={columnIds}
        customWidth={columnWidths}
        tableId='initiative-table'
        customTextColor={getTableTitleStyle(props.status)[0]}
        customBackgroundColor={getTableTitleStyle(props.status)[1]}
      >
      </DisplayTable>
      {generateShowMoreItems()}
      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        backdropDismiss={false}
        cssClass="initiative-modal"
      >
        <InitiativeInfo
          closeAction={closeModal}
          initiative={targetInitiative!}
        ></InitiativeInfo>
      </IonModal>
      <IonAlert
        isOpen={showDeleteAlert}
        backdropDismiss={false}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Are you sure you want to delete this initiative?"}
        subHeader={targetInitiative?.title}
        buttons={[
          {
            text: "Confirm",
            handler: () => {
              if (targetInitiative) {
                props.deleteAction(targetInitiative);
              }
            }
          },
          {
            text: "Cancel",
            handler: () => {
              setShowDeleteAlert(false);
            }
          }
        ]}
      />

    </>
  );
};

export default InitiativesList;
