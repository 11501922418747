import { IdeaRequest } from '@s360/common-models/ideaRequest';
import Axios from "axios";
import { Plugins } from '@capacitor/core';
import { Idea } from '@s360/common-models/idea';
import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { Roles } from '@s360/common-models/roles';
import { HttpResponse } from '@s360/common-models/httpResponse';
import { IdeaStatus } from '@s360/common-models/ideaStatus';
import Cookies from 'js-cookie';

const { Storage } = Plugins;

export class IdeaService {
    private authorizationToken: string;
    constructor() {
        this.authorizationToken = this.getAuthorizationToken();
    }

    getAuthorizationToken = (): string => {
        let token = Cookies.get('token') + '';
        return token;
      }
    
    private async getObject() : Promise<AuthenticationResponse> {
        return new Promise<AuthenticationResponse>(async (resolve, reject)=>{
             Storage.get({key: 'sessionParams'}).then(res=>{
                 resolve(JSON.parse(res.value!))
             }).catch(error=>{
                 reject(error);
             })
        })
    }
    public async getIdeas(): Promise<HttpResponse> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request: IdeaRequest = {
                    companyID: value.companyID,
                    userRole: Roles.Admin
                }
                const getIdeaURL = process.env.REACT_APP_API_GET_IDEAS_URL || "";
                Axios.post(getIdeaURL, request, config).then((value)=>{
                    let result : HttpResponse = { body: value.data, statusCode: value.status, headers: value.headers};
                    resolve(result);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }

    public async submitIdea(newIdea: Idea): Promise<any> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                newIdea.companyID = value.companyID;
                const submitIdeaURL = process.env.REACT_APP_API_SUBMIT_IDEA_URL || "";
                Axios.post(submitIdeaURL, newIdea, config).then((value)=>{
                    resolve(value);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }

    public async rejectIdea(idea: Idea): Promise<any> {
        return this.updateIdeaStatus(idea, IdeaStatus.Rejected);
    }

    private updateIdeaStatus(idea: Idea, ideaStatus: IdeaStatus): Promise<any> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                idea.companyID = value.companyID;
                idea.ideaStatus = ideaStatus;
                idea.lastModifiedBy = {
                    firstName: value.firstName,
                    lastName: value.lastName,
                    emailAddress: value.emailAddress
                }
                const updateURL = process.env.REACT_APP_API_UPDATE_IDEA_STATUS_URL || "";
                Axios.post(updateURL, idea, config).then((value)=>{
                    resolve(value);
                }).catch((error)=> {
                    reject(error);
                })
            })
        })   
    }

    public async acceptIdea(idea: Idea): Promise<any> {
        return this.updateIdeaStatus(idea, IdeaStatus.Accepted);
    }

    public async getIdeaCounters(api: string): Promise<HttpResponse> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request: IdeaRequest = {
                    companyID: value.companyID,
                    userRole: Roles.Admin
                }
                let getIdeaURL: string = api || "";
                Axios.post(getIdeaURL, request, config).then((value)=>{
                    let result : HttpResponse = { body: value.data, statusCode: value.status, headers: value.headers};
                    resolve(result);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }

    public async getTotalIdeas(): Promise<HttpResponse> {
        return this.getIdeaCounters(process.env.REACT_APP_API_GET_TOTAL_IDEAS_URL!);
    }

    public async getCurrentMonthTotalIdeas(): Promise<HttpResponse> {
        return this.getIdeaCounters(process.env.REACT_APP_API_GET_CURRENT_MONTH_TOTAL_IDEAS_URL!);
    }
    
    public async getTotalAcceptedIdeas(): Promise<HttpResponse> {
        return this.getIdeaCounters(process.env.REACT_APP_API_GET_TOTAL_ACCEPTED_IDEAS_URL!);
    }

    public async getCurrentMonthTotalAcceptedIdeas(): Promise<HttpResponse> {
        return this.getIdeaCounters(process.env.REACT_APP_API_GET_CURRENT_MONTH_TOTAL_ACCEPTED_IDEAS_URL!);
    }
}