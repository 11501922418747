import {
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButtons,
    IonAlert,
    IonNote,
    IonLoading,
    IonToast
} from "@ionic/react";
import { BcorpIdea } from "@s360/common-models/bcorp-idea";
import { closeOutline } from "ionicons/icons";
import React, { ChangeEvent, RefObject, useRef, useState } from "react";
import { BcorpIdeaService } from "../../services/BcorpIdeaService";
import { FileService } from "../../services/FileService";
import { BcorpUploadModalProps } from './BcorpUploadModalProps';

const BcorpUploadModal: React.FC<BcorpUploadModalProps> = ({ closeAction}: BcorpUploadModalProps) => {
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [isExiting, setIsExiting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false)
    const [bcorpIdeas, setBcorpIdeas] = useState<BcorpIdea[]>([]);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const fileInputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

    const [errors, setErrors] = useState<string[]>([]);

    const fileService: FileService = new FileService();
    const bcorpIdeaService: BcorpIdeaService = new BcorpIdeaService();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
        clearErrorMessages();
        setIsUploaded(false);

        if (!event.target.files) {
            addErrorMessage(`No file was uploaded`);
            return;
        }

        const bcorpFile: File = event.target.files[0];

        //TODO clean up validation (make this look tidier)
        if (!bcorpFile) {
            addErrorMessage(`Unable to read file, upload a file to import improvement report`)
            return;
        }

        fileService.validateFileSize(bcorpFile, 30)
            .catch((error: Error) => {
                addErrorMessage(error.message)
            });

        bcorpIdeaService.xlsxToBcorpIdeas(bcorpFile)
            .then((bcorpIdeas: BcorpIdea[]) => {
                setBcorpIdeas(bcorpIdeas);
            }).catch((error: Error) => {
                addErrorMessage(error.message);
            });

        setCanSubmit(true);
    }

    const handleOnSubmitClick = (): void => {
        setIsLoading(true);

        const bcorpJson: string = JSON.stringify(bcorpIdeas);
        bcorpIdeaService.publishBcorpIdea(bcorpJson)
            .then((() => {
                setIsUploaded(true);
                handleExit();
            }))
            .catch((error: Error) => {
                clearErrorMessages();
                addErrorMessage(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleOnExitClick = (): void => {
        setIsExiting(true);
    }

    const handleOnDidDismiss = (): void => {
        setIsExiting(false);
    }

    const handleExit = () => {
        closeAction();
        clearInput();
    }

    const handleOnClear = (): void => {
        clearInput();
    }

    const clearInput = (): void => {
        setCanSubmit(false);
        clearErrorMessages();
        fileInputRef.current!.value = '';
    }

    const addErrorMessage = (errorMessage: string): void => {
        setErrorMessages((errors: string[]) => errors.concat(errorMessage));
        setCanSubmit(false);
    }

    const clearErrorMessages = (): void => {
        setErrorMessages((errors: string[]) => errors = []);
    }

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>
                        Upload Bcorp Ideas
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            disabled={!canSubmit}
                            onClick={handleOnSubmitClick}
                        >
                            Submit
                        </IonButton>
                        <IonButton
                            onClick={handleOnClear}
                        >Clear</IonButton>
                        <IonButton
                            onClick={handleOnExitClick}
                        >
                            <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid style={{ margin: "1rem" }}>
                    <IonRow className="max-width w-100">
                        <input
                            type="file"
                            accept="
                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                                    application/vnd.ms-excel,
                                    .csv"
                            ref={fileInputRef}
                            onChange={handleOnChange}
                        />
                    </IonRow>
                    {errorMessages &&
                        errorMessages.map((error: string, index: number) => (
                            <IonNote key={index} color="danger">
                                <li>{error}</li>
                            </IonNote>
                        ))}
                </IonGrid>
                <IonAlert
                    isOpen={isExiting}
                    header={"Are you sure you want to exit?"}
                    onDidDismiss={handleOnDidDismiss}
                    buttons={[
                        {
                            text: "Okay",
                            handler: handleExit,
                        },
                        {
                            text: "Cancel",
                            role: "cancel",
                        },
                    ]}
                />
                <IonToast
                    isOpen={isUploaded}
                    duration={1000}
                    position="top"
                    color="success"
                    header={"Upload successful, processing B Corp Ideas"}
                    onDidDismiss={handleOnDidDismiss}
                />
            </IonContent>
            <IonLoading
                isOpen={isLoading}
                onDidDismiss={() => setIsLoading(false)}
                message={'Please wait...'}
            />
        </>
    )
}


export default BcorpUploadModal;