import { RegisterResponse } from "@s360/common-models/apiResponses";
import axios from "axios";
import { Company } from '@s360/common-models/company'

export class RegisterService {
    public register(newCompany: Company): Promise<RegisterResponse> {
        const config = {
          headers: {
            "Content-Type": "application/json"
          }
        }
        return new Promise(async (resolve, reject) => {
          const registerAPIURL = 
            process.env.REACT_APP_API_REGISTER_URL || "";
          
          try {
            const response : RegisterResponse= await axios.post(registerAPIURL, newCompany , config);
            resolve(response);
          } catch (e) {
            console.error(e);
            reject(e);
          }

        });
      }
}