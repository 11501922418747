import React, { useState } from "react";
import {
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonText,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import { nameAndDescription } from "@s360/common-models/constants";
import { helpCircleOutline } from "ionicons/icons";
import CSS from "csstype";
import './UserIonSelect.css'

const UserIonSelect: React.FC<{
  setValue?: (value: any) => void;
  value?: any;
  options: Array<any>;
  labelText?: string;
  errorMessage?: string;
  disabled?: boolean;
  extraInfo?: nameAndDescription[];
  grayOptions?: string[];
  required?: boolean;
  className?: string;
  id?: string;
}> = (props) => {
  const [showAlert, setAlert] = useState(false);

  const generateOptions = (options: string[]) => {
    let optionsItem = options.map((option) => (
      <IonSelectOption class={props.grayOptions && props.grayOptions.find(s => s === option) ? 'gray-option' : undefined} key={option} value={option} >
        {option}
      </IonSelectOption>
    ));

    return <div>{optionsItem}</div>;
  };

  const generateAlertContent = (options: nameAndDescription[]) => {
    let content = "";
    options.forEach((option, index) => {
      content += option.Name + ":<br>" + option.Desc;
      if (index != options.length - 1) {
        content += "<br><br>";
      }
    });

    return content;
  };

  var iconStyle: CSS.Properties = {
    cursor: "pointer",
    margin: 0,
  };

  return (
    <>
      <IonLabel position="fixed">
        <h2>
          {props.labelText}
          {props.required && <IonText color="danger">*</IonText>}

          {props.extraInfo && (
            <IonText
              color="danger"
              onClick={(e) => {
                setAlert(true);
              }}
              style={iconStyle}
            >
              {<IonIcon icon={helpCircleOutline}> </IonIcon>}
            </IonText>
          )}
          <IonNote color="danger">{props.errorMessage}</IonNote>
        </h2>
      </IonLabel>

      <IonSelect
        disabled={props.disabled}
        interface="popover"
        value={props.value}
        onIonChange={(e) => props.setValue!(e.detail.value!)}
        className={props.className ? props.className : (props.grayOptions && props.grayOptions.find(s => s === props.value) ? "black-border gray-select" : "black-border")}
        id={props.id ? props.id : undefined}
      >
        {generateOptions(props.options)}
      </IonSelect>
      <IonAlert
        isOpen={showAlert}
        backdropDismiss={false}
        onDidDismiss={() => setAlert(false)}
        header={"Extra info on Tracking Status"}
        message={props.extraInfo ? generateAlertContent(props.extraInfo) : ""}
        buttons={[
          {
            text: "Ok",
            role: "cancel",
          },
        ]}
      />
    </>
  );
};

export default UserIonSelect;
