import React, { useState, useEffect } from 'react';
import { IonItem, IonPage, IonInput, IonLabel, IonButton, IonHeader, IonToolbar, IonContent, IonTitle, IonLoading, IonAlert } from '@ionic/react';
import { useParams } from 'react-router-dom';
import '../css/app.scss';
import { ResetPassword } from '@s360/common-models/resetPassword'
import { ResetService } from '../services/ResetService';
import Cookies from 'js-cookie';
import { Plugins } from '@capacitor/core';
import { AuthenticationResponse } from '@s360/common-models/authenticationResponse';
import { setEmailAddress } from '../data/user/user.actions';
import { HttpStatus} from '@s360/common-models/httpStatus';
const Cryptr = require('cryptr');

const { Storage } = Plugins;

const ChangePassword: React.FC = () => {

    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [retypePassword, setRetypePassword] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [validPassword, setValidPassword] = useState<boolean>(false);
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
    const [incorrectPasswordError, setIncorrectPasswordError] = useState<string>("");
    const [passwordMismatchError, setPasswordMismatchError] = useState<string>("");
    const handle: any = useParams();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [source, setSource] = useState<string>("");
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [responseStatus, setResponseStatus] = useState<HttpStatus>(HttpStatus.Unauthorized); 


    useEffect(() => {
        setSource(handle['source']);;
    }, [])

    document.onkeypress = (e) => {
        if (e.keyCode == 13 && canSubmit) {
            resetSubmit();
        }
    }
    useEffect(() => {
        if (currentPassword !== "" && newPassword === retypePassword && (newPassword !== "" && retypePassword !== "")) {
            setPasswordMatch(true);
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
            setPasswordMatch(false);
        }
        if (!validPassword) {
            setIncorrectPasswordError("The password must be of minimum eight characters containing at least one letter, one number and one special character")
        } else {
            setIncorrectPasswordError("");
        }

        if (!passwordMatch) {
            setPasswordMismatchError("The passwords do not match");
        }
        else {
            setPasswordMismatchError("");
        }
        newPassword &&
            newPassword.search(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)
            ? setValidPassword(false)
            : setValidPassword(true);
    })

    async function getObject(): Promise<AuthenticationResponse> {
        return new Promise<AuthenticationResponse>(async (resolve, reject) => {
            await Storage.get({ key: 'sessionParams' }).then(res => {
                resolve(JSON.parse(res.value!))
            }).catch(error => {
                reject(error);
            })
        })
    }

    async function resetSubmit() {
        setShowLoading(true);
        await getObject().then(async (value: AuthenticationResponse) => {
            const emailAddress = value.emailAddress;
            const cryptr = new Cryptr(process.env.REACT_APP_CRYPTR_KEY || "");
            const encryptedEmail = cryptr.encrypt(emailAddress);
            const resetDetails: ResetPassword = {
                currentPassword: currentPassword,
                newPassword: newPassword,
                emailAddress: encryptedEmail
            }
            const resetService = new ResetService();
            await resetService.reset(resetDetails).then((value: any) => {
                if (value.status === 200) {
                    const reset = 'reset';
                    setAlertMessage("Password changed successfully. Do you want to go back to the console?");
                    setResponseStatus(HttpStatus.OK);
                    setShowAlert(true);
                    //Cookies.set('passwordReset', reset, { domain: process.env.REACT_APP_DOMAIN });
                    setShowLoading(false);
                    if (source === 'initiativePortal') {
                        const consoleUrl = process.env.REACT_APP_CONSOLE_URL!;
                        window.open(consoleUrl, '_self')
                    }
                    else if (source === 'cc') {
                        const ccUrl = process.env.REACT_APP_CC_URL!;
                        window.open(ccUrl, '_self');
                    }
                }
            }).catch(error => {
                if(error.status===HttpStatus.Unauthorized){
                    setResponseStatus(HttpStatus.Unauthorized);
                    setAlertMessage("The current password provided is incorrect");
                    setShowLoading(false);
                    setShowAlert(true);
                }
                
            })
        })

    }

    async function logout() {
        Cookies.remove('token', { domain: process.env.REACT_APP_DOMAIN });
        Cookies.remove('cid', { domain: process.env.REACT_APP_DOMAIN });
        await Storage.clear();
        setEmailAddress();
        window.open('/login/initiativePortal', "_self");
    }

    return (
        <IonPage id='login-registration-page'>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonTitle>Change Password</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <form className="login-registration-form ion-padding">
                    <IonItem>
                        <IonLabel position="stacked" color='primary'>Current Password:</IonLabel>
                        <IonInput name="currentPassword" type="password" value={currentPassword} onIonChange={e => setCurrentPassword(e.detail.value!)}></IonInput>
                    </IonItem>
                    <br />
                    <IonItem>
                        <IonLabel position="stacked" color='primary'>New Password:</IonLabel>
                        <IonInput name="newPassword" type="password" value={newPassword} onIonChange={e => setNewPassword(e.detail.value!)}></IonInput>
                    </IonItem>
                    <div style={{ color: "red", fontSize: "70%" }}>
                        <p>{!validPassword && incorrectPasswordError}</p>
                    </div>
                    <br />
                    <IonItem>
                        <IonLabel position="stacked" color='primary'>Retype Password:</IonLabel>
                        <IonInput name="retypePassword" type="password" value={retypePassword} onIonChange={e => setRetypePassword(e.detail.value!)}></IonInput>
                    </IonItem>
                    <div style={{ color: "red", fontSize: "70%" }}>
                        <p>{!passwordMatch && retypePassword !== "" && passwordMismatchError}</p>
                    </div>
                    <br />
                    <IonButton disabled={!canSubmit} onClick={() => {
                        resetSubmit();
                    }} expand="block">Submit</IonButton>
                </form>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => {
                        setShowLoading(false);
                    }}
                    message={'Please wait while we reset your password.....'}
                />
            </IonContent>

            <IonAlert
                cssClass='my-loading-class'
                isOpen={showAlert}
                backdropDismiss={false}
                onDidDismiss={() => setShowAlert(false)}
                header={alertMessage}
                buttons={[
                    {
                        text: responseStatus === HttpStatus.OK ? "Back to console" : "Try again",
                        handler: () => {
                            responseStatus === HttpStatus.OK ? window.open(process.env.REACT_APP_CONSOLE_URL, "_self") : setShowAlert(false);
                            setCurrentPassword("");
                            setNewPassword("");
                            setRetypePassword("");
                        }
                    },
                    {
                        text: "Logout",
                        handler: () => {
                           logout();
                        }
                    }
                ]}
            />
        </IonPage>
    )
}

export default ChangePassword;