export function stringShortenerEllipsis(
  sentence: string,
  length: number
  ): string {
  /* Shortens a string based on specified length of characters */
  if (sentence.length > length) {
    return sentence.substring(0, length) + " ...";
  } else {
    return sentence.substring(0, length);
  }
};