import 'bootstrap/dist/css/bootstrap.min.css';
import { IonGrid, IonLabel } from "@ionic/react";
import DisplayTable from "./DisplayTable";
import { stringShortenerEllipsis } from "../helper/StringShortenerEllipsis";
import { dateToLocale } from "../helper/DateConverter";
import { UserActivityLog } from "@s360/common-models/activityLog";
import React from 'react';
import { UserCognitoLog } from '@s360/common-models/user';

const AuthActivityList: React.FC<{
  userActivityList: UserActivityLog[];
  userCognitoList: UserCognitoLog[];
  cognito: boolean;
  activated: boolean;
}> = (props) => {

  const userActivityListHeaders: string[] = [
    "USERNAME",
    "DATE",
    "REMOTE IP ADDRESS",
    "BROWSER",
  ]

  const userActivityListColumnIds: string[] = [
    "activity-log-username",
    "activity-log-date",
    "activity-log-ip",
    "activity-log-browser"
  ]

  const userActivityListColumnWidths: string[] = ['20%', '25%', '20%', '35%']

  const userCognitoListHeaders: string[] = [
    "USERNAME",
    props.activated ? "ACTIVATION DATE" : "CREATION DATE",
  ]

  const userCognitoListColumnIds: string[] = [
    "activity-log-username",
    "activity-log-date",
  ]

  const userCognitoListColumnWidths: string[] = ['50%', '50%']


  const getAuthActivityUsername = (authActivity: UserActivityLog | UserCognitoLog): React.ReactElement => {
    return (
      <IonLabel>
        {stringShortenerEllipsis(authActivity.emailAddress, 50)}
      </IonLabel>
    )
  }

  const getUserActivityDate = (userActivityLog: UserActivityLog): React.ReactElement => {
    return (
      <IonLabel>
        {dateToLocale({ dateNumber: userActivityLog.date, time: true })}
      </IonLabel>
    )
  }

  const getUserActivityIP = (userActivityLog: UserActivityLog): React.ReactElement => {
    return (
      <IonLabel>
        {userActivityLog.remoteIPAddress}
      </IonLabel>
    )
  }

  const getUserActivityBrowser = (userActivityLog: UserActivityLog): React.ReactElement => {
    return (
      <IonLabel>
        {stringShortenerEllipsis(userActivityLog.browser, 50)}
      </IonLabel>
    )
  }

  const getUserCognitoDate = (userCognitoLog: UserCognitoLog): React.ReactElement => {
    const date: number = props.activated ? userCognitoLog.lastModifiedDate : userCognitoLog.creationDate;
    return (
      <IonLabel>
        {dateToLocale({ dateNumber: date, time: true })}
      </IonLabel>
    )
  }
  
  const rowUserActivityInfoGetters: ((userActivityLog: UserActivityLog) => React.ReactElement)[] = [
    getAuthActivityUsername,
    getUserActivityDate,
    getUserActivityIP,
    getUserActivityBrowser
  ]

  const rowUserCognitoInfoGetters: ((userCognitoLog: UserCognitoLog) => React.ReactElement)[] = [
    getAuthActivityUsername,
    getUserCognitoDate,
  ]

  return (
    <IonGrid>
      {(props.userCognitoList.length > 0 || props.userActivityList.length > 0) &&
      (<DisplayTable
        items={props.cognito ? props.userCognitoList : props.userActivityList}
        headers={props.cognito ? userCognitoListHeaders : userActivityListHeaders}
        rowInfoGetters={props.cognito ? rowUserCognitoInfoGetters : rowUserActivityInfoGetters}
        customColumnId={props.cognito ? userCognitoListColumnIds : userActivityListColumnIds}
        customWidth={props.cognito ? userCognitoListColumnWidths : userActivityListColumnWidths}
        tableId='auth-activity-table'
        customTextColor="black"
        customBackgroundColor="000000"
        customTitleStyle={true}
      />)}
      {props.userCognitoList.length === 0 &&
      props.userActivityList.length === 0 &&
      (<div className="alert alert-secondary no-activity" role="alert">
        No logged information available.
      </div>)}
    </IonGrid>
  );
};

export default AuthActivityList;
