import { utils, read, WorkBook, WorkSheet } from "xlsx";
import { trimmer } from "../helper/trimmer";

export class FileService {
    public validateFileSize(file: File, maxMb: number): Promise<boolean> {
        return new Promise<boolean> ((resolve) => {
            const fileSizeKb: number  = file.size / 1024;
            const fileSizeMb: number  = fileSizeKb / 1024;
            if (fileSizeMb < maxMb) {
                resolve(true);
            }

            throw new Error(`File to large to be uploaded (${trimmer(fileSizeMb)} MB), must be less than ${maxMb} MB`);
        });
    }

    /* eslint-disable no-undef */
    public xlsxToType<T>(file: File): Promise<T[]> {
        return new Promise<T[]>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const data: any = event.target.result;
                const workbook: WorkBook = read(data, { type: `array` });
                const sheetName: string = workbook.SheetNames[0];
                const worksheet: WorkSheet = workbook.Sheets[sheetName];
                const result: T[] = utils.sheet_to_json<T>(worksheet);
                resolve(result);
            };

            try {
                reader.readAsArrayBuffer(file);
            }
            catch (error: any) {
                reject(error);
            }
        });
    }
}

