import {
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    IonAlert
} from "@ionic/react";
import React, { ChangeEvent, useRef, useState } from "react";
import { InitiativeAttachmentProps } from "./InitiativeAttachmentsProps";
import { FileIcon, DefaultExtensionType, defaultStyles } from 'react-file-icon';
import { dateToLocale } from "../../helper/DateConverter";
import * as uuid from 'uuid';

import '../../css/Table.css';
import { FileService } from "../../services/FilesService";
import prettyBytes from "pretty-bytes";
import { AttachmentService } from "../../services/AttachmentService";
import { Attachment } from "@s360/common-models/attachment";
import { AttachmentStatus } from "@s360/common-models/attachment-status";


const InitiativeAttachment: React.FC<InitiativeAttachmentProps> = (props) => {
    const [error, setError] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);
    const [deleteFileNames, setDeleteFileNames] = useState<string[]>([]);

    const uploadInput = useRef<HTMLInputElement>(null);

    const fileService: FileService = new FileService();
    const attachmentService: AttachmentService = new AttachmentService();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const inputFiles = event.target.files

        if (!inputFiles) {
            displayError("No files where uploaded")
            return
        }

        const filesArray: File[] = Array.from(inputFiles);

        filesArray.map((file: File) => {
            const newAttachment: Attachment = {
                id: uuid.v4(),
                createdAt: Date.now(),
                attachmentStatus: AttachmentStatus.NEW,

                name: file.name,
                sizeBytes: file.size,
                fileType: file.type,
                userLastModified: file.lastModified,
            }
            addAttachment(newAttachment, file);
        });

        clearInput();
    }

    const addAttachment = (newAttachment: Attachment, newFile: File): void => {
        const attachmentExists: boolean = props.attachments.some((attachment: Attachment) => (
            attachment.name === newAttachment.name
        ));

        if (attachmentExists) {
            displayError(`${newAttachment.name} already exists`);
            return
        }

        fileService.validateFileSize(newFile, 30)
            .then(() => {
                props.addAttachment(newAttachment);
                setUploadFiles((files: File[]) => files.concat(newFile))
            })
            .catch((error: Error) => {
                displayError(error.message);
            });
    }

    const displayError = (newErrorMessage: string): void => {
        setError(newErrorMessage);
        setIsError(true)
    }

    const handleRemove = (targetAttachment: Attachment): void => {
        props.removeAttachment(targetAttachment);
        setDeleteFileNames((files: string[]) => files.concat(targetAttachment.name))
        clearInput();
    }

    const clearInput = (): void => {
        uploadInput.current!.value = '';
    }

    const save = (): void => {
        props.retrieveInputs();
        uploadFiles.map((file: File) => {
            uploadFileToS3(file)
                .catch((error: Error) => {
                    console.log(error)
                    displayError('Error has occurred uploading the files')
                });
        });

        deleteFileNames.map((fileName: string) => {
            deleteFileFromS3(fileName)
                .catch((error: Error) => {
                    console.log(error);
                    displayError(`Error occurred when deleting ${fileName}`);
                });
        })
    }

    const uploadFileToS3 = (file: File): Promise<string> => {
        return new Promise<string>((resolve) => {
            attachmentService.getPutSignedUrl(props.initiativeID, file.name)
                .then((result) => {
                    const attachmentKey: string = result.key;
                    attachmentService.uploadAttachment(result.uploadUrl, file)
                        .then(() => {
                            resolve(attachmentKey)
                        })
                        .catch((error: Error) => {
                            displayError(error.message);
                        })
                })
                .catch(() => {
                    displayError('Unable to upload files please try again');
                })
        })
    }

    const deleteFileFromS3 = (fileName: string) => {
        return new Promise<void>((resolve) => {
            attachmentService.deleteAttachment(props.initiativeID, fileName)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    displayError(`Error occurred when deleting ${fileName}`)
                })
        })
    }
    return (
        <>
            <IonGrid>
                <IonRow className="ion-padding-bottom">
                    <IonCol size="10">
                        {/* Ionic does not support file uploads in the current version 6.20.1, Input is required here */}
                        <input
                            ref={uploadInput}
                            type="file"
                            onChange={handleOnChange}
                            accept="
                                image/*,
                                video/*,
                                application/*"
                            multiple
                            hidden
                        />
                        <IonButton
                            color="light"
                            onClick={() => {
                                // @ts-ignore
                                uploadInput?.current?.click();
                            }}
                        >
                            Upload Attachments
                        </IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton
                            className="float-right"
                            shape="round"
                            onClick={save}
                            type="button"
                        >
                            Save
                        </IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton
                            className="float-right"
                            shape="round"
                            onClick={() => {
                                props.setCurrentPage(3)
                            }}>
                            Next
                        </IonButton>
                    </IonCol>
                </IonRow>
                {props.initiative?.attachments ?
                    <>
                        <IonRow className="ion-text-uppercase table-heading-light">
                            <IonCol size="5.8">File</IonCol>
                            <IonCol size="2">Author</IonCol>
                            <IonCol size="0.85">File Type</IonCol>
                            <IonCol size="1">Size</IonCol>
                            <IonCol size="1.5">Last Modified</IonCol>
                        </IonRow>
                        {props.initiative!.attachments!.map((attachment: Attachment, index: number) => (
                            <IonRow
                                key={index}
                                className="ion-align-items-center ion-margin-top"
                            >
                                <IonCol size="0.3">
                                    <FileIcon
                                        labelUppercase={true}
                                        extension={attachment.name.split('.').pop()}
                                        {...defaultStyles[attachment.name.split('.').pop() as DefaultExtensionType]}
                                    />
                                </IonCol>
                                <IonCol size="5.5">
                                    {attachment.name}
                                </IonCol>
                                <IonCol size="2">{attachment.author}</IonCol>
                                <IonCol size="0.85">{attachment.name.split('.').pop()}</IonCol>
                                <IonCol size="1">
                                    {prettyBytes(attachment.sizeBytes)}
                                </IonCol>
                                <IonCol size="1.5">{dateToLocale({ dateNumber: attachment.userLastModified, time: true })}</IonCol>
                                <IonCol>
                                    <IonButton
                                        color="danger"
                                        fill="clear"
                                        className="float-right"
                                        onClick={() => { handleRemove(attachment) }}
                                    >
                                        Remove
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        ))}
                    </> :
                    <IonRow className="ion-margin-top">
                        <IonCol
                            size="auto"
                        >
                            <IonText
                                className="alert alert-secondary"
                                role="alert">
                                There are no attachments connected to this initiative. Upload a file to add an attachment.
                            </IonText>
                        </IonCol>

                    </IonRow>
                }
            </IonGrid>
            <IonAlert
                isOpen={isError}
                backdropDismiss={false}
                onDidDismiss={() => setIsError(false)}
                header={error}
                buttons={[
                    {
                        text: "Okay",
                        handler: () => {
                            setIsError(false);
                        },
                    }
                ]}
            ></IonAlert>
        </>
    )
}

export default InitiativeAttachment;