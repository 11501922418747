

import { GeneralHttpResponse } from "@s360/common-models/apiResponses";
import axios from "axios";

import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { Plugins } from '@capacitor/core';
import { User } from "@s360/common-models/user";
const { Storage } = Plugins;

export class UserService {

  private async getObject(): Promise<AuthenticationResponse> {
    return new Promise<AuthenticationResponse>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        resolve(JSON.parse(res.value!))
      }).catch(error => {
        reject(error);
      })
    })
  }

  public getUsers(): Promise<User[]> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const getUsersURL = process.env.REACT_APP_API_READ_USERS_URL || "";
        const content = {
          companyID: value.companyID,
        };
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        axios.post(getUsersURL, content, config).then(value => {
          let users: User[] = [];
          if (value.data && value.data !== []) {
            users = value.data;
          }
          resolve(users);
        }).catch(error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }

  public addUser(newUser: User): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const addUserURL = process.env.REACT_APP_API_CREATE_USER_URL || "";
        newUser.companyID = value.companyID;
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        newUser.lastModifiedBy = {
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress,
        }
        axios.post(addUserURL, newUser, config).then(res => {
          let result: GeneralHttpResponse = { httpStatus: res.status };
          resolve(result);
        }).catch(error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }

  public updateUser(user: User): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const updateUserURL = process.env.REACT_APP_API_UPDATE_USER_URL || "";
        user.companyID = value.companyID;
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        user.lastModifiedBy = {
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress,
        }
        axios.post(updateUserURL, user, config).then(res => {
          let result: GeneralHttpResponse = { httpStatus: res.status };
          resolve(result);
        }).catch(error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }

  public deleteUser(user: User): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const deleteUserURL = process.env.REACT_APP_API_DELETE_USER_URL || "";
        user.companyID = value.companyID;
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        user.lastModifiedBy = {
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress,
        }
        axios.post(deleteUserURL, user, config).then(res => {
          let apiDataResponse: GeneralHttpResponse = {
            httpStatus: res.status
          };
          resolve(apiDataResponse);
        }).catch(error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }

  public resetUserPassword(user: User): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const resetPasswordURL = process.env.REACT_APP_API_RESET_USER_PASSWORD_URL || "";
        user.companyID = value.companyID;
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        axios.post(resetPasswordURL, user, config).then(res => {
          const result: GeneralHttpResponse = { httpStatus: res.status };
          resolve(result);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    });
  }
}