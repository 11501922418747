import React from "react";
import { IonLabel, IonInput, IonNote, IonText } from "@ionic/react";

const UserIonInput: React.FC<{
  setValue: (value: string) => void;
  value: string;
  labelText: string;
  errorMessage: string;
  maxLength: number;
  mask?: boolean;
  disabled?: boolean;
  className?: string;
  id?: string;
}> = (props) => {
  return (
    <>
      <IonLabel position="fixed">
        <h2>
          {props.labelText}
          <IonText color="danger">*</IonText>
          <IonNote color="danger">{props.errorMessage}</IonNote>
        </h2>
      </IonLabel>
      <IonInput
        id={props.id ? props.id : undefined}
        value={props.value}
        onIonChange={(e) => props.setValue(e.detail.value!.trim())}
        maxlength={props.maxLength}
        className={props.className? props.className : "black-border"}
        disabled = {props.disabled}
        type={props.mask === true ? "password" : "text"}
      ></IonInput>
    </>
  );
};

export default UserIonInput;
