import React from "react";
import { IonLabel, IonNote, IonTextarea, IonText } from "@ionic/react";

const UserIonTextarea: React.FC<{
  setValue: (value: any) => void;
  value: any;
  rows: number;
  labelText: string;
  errorMessage: string;
  mandatory: boolean;
  className?: string;
  id?: string;
  disabled?: boolean;
}> = (props) => {
  return (
    <>
      <IonLabel position="fixed">
        <h2>
          {props.labelText}
          {props.mandatory && <IonText color="danger">*</IonText>}
          <IonNote color="danger">{props.errorMessage}</IonNote>
        </h2>
      </IonLabel>
      <IonTextarea
        rows={props.rows}
        value={props.value}
        onIonChange={(e) => props.setValue(e.detail.value!.trim())}
        className={props.className ? props.className : "black-border"}
        id={props.id ? props.id : undefined}
        disabled={props.disabled}
      ></IonTextarea>
    </>
  );
};

export default UserIonTextarea;
