import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

ReactDOM.render(<App />, document.getElementById("root"));


serviceWorker.register();

