import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonPage,
  IonSearchbar,
  IonIcon,
  IonModal,
  IonToast,
} from "@ionic/react";

import { User } from "@s360/common-models/user";
import { useLocation, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import { UserService } from "../services/UserService";
import "./ReportPage.css";
import UsersList from "../components/UsersList";
import { addOutline } from "ionicons/icons";
import UserForm from "../components/UserForm";
import MultiUserForm from "../components/MultiUserForm";

interface OwnProps extends RouteComponentProps {}
interface UsersPageProps extends RouteComponentProps, UserState {}

const UsersPage: React.FC<UsersPageProps> = ({ history }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [isOpenUserDialog, setIsOpenUserDialog] = useState<boolean>(false);
  const [isOpenMultiUserDialog, setIsOpenMultiUserDialog] = useState<boolean>(false);
  const [showAddToast, setShowAddToast] = useState<boolean>(false);
  const [showAddFailedToast, setShowAddFailedToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const defaultErrorMessage: string = 'Failed to add user';
  const [refreshKey, setRefreshKey] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const location = useLocation();
  const sessionParams = location.state;
  
  const getCalculatedUsers = (text: string) => {
    if (!text || text.length<=0) return users;
    let lowerText = text.toLowerCase();
    let userList: User[] = users.filter(function(user) {
      return (user && (
        (user.firstName && user.firstName.toLowerCase().indexOf(lowerText) >= 0)
        || (user.lastName && user.lastName.toLowerCase().indexOf(lowerText) >= 0)
        || (user.emailAddress && user.emailAddress.toLowerCase().indexOf(lowerText) >= 0)
        ))
    });
    return userList
  }

  const afterAddedAction= () => {
    setShowAddToast(true)
    setRefreshKey(oldKey => oldKey +1);
  }
  
  const afterEditedAction= () => {
    setRefreshKey(oldKey => oldKey +1);
  }
  const getUsers = () => {
    const userService = new UserService();
    
    userService
      .getUsers()
      .then((userList: User[]) => {
        if (userList) {
          setUsers(userList);
        }
      })
      .catch((error) => {
        console.error("Error get users:", error);
      });
  };

  const showErrorMessage = (message?: string): void => {
    setShowAddFailedToast(true);
    setErrorMessage(message? message : defaultErrorMessage);
  }

  useEffect(() => {
    getUsers();
  }, [refreshKey]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle size="large" >Users</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
            </IonCol>
            <IonCol>
              <IonButton class="ion-float-right"
                onClick={() => setIsOpenMultiUserDialog(true)}>
                  <IonIcon slot="start" icon={addOutline} />
                  Add Multiple Users
              </IonButton>
              <IonButton class="ion-float-right"
                onClick={() => setIsOpenUserDialog(true)}>
                  <IonIcon slot="start" icon={addOutline} />
                  Add User
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <UsersList displayedUsers={getCalculatedUsers(searchText)} afterEditedAction={afterEditedAction}></UsersList>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonModal
          isOpen={isOpenMultiUserDialog}
          onDidDismiss={() => setIsOpenMultiUserDialog(false)}
          backdropDismiss={false}
        >
          <MultiUserForm
            allUsers={users}
            showToast={() => afterAddedAction()}
            showErrorToast={(message: string) => showErrorMessage(message)}
            closeAction={() => setIsOpenMultiUserDialog(false)}
          ></MultiUserForm>
      </IonModal>
      <IonModal
          isOpen={isOpenUserDialog}
          onDidDismiss={() => setIsOpenUserDialog(false)}
          backdropDismiss={false}
        >
          <UserForm
            allUsers={users}
            showToast={() => afterAddedAction()}
            showErrorToast={(message: string) => showErrorMessage(message)}
            closeAction={() => setIsOpenUserDialog(false)}
          ></UserForm>
      </IonModal>
      <IonToast
          isOpen={showAddToast}
          onDidDismiss={() => setShowAddToast(false)}
          message="User is added successfully"
          duration={5000}
          position="top"
          color="success"
      />
      <IonToast
          isOpen={showAddFailedToast}
          onDidDismiss={() => setShowAddFailedToast(false)}
          message={errorMessage}
          duration={5000}
          position="top"
          color="danger"
      />
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(UsersPage),
});
