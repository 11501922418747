import Axios from "axios";
import { Plugins } from '@capacitor/core';
import { MainObjective, MainObjectiveRequest } from '@s360/common-models/mainObjective';
import { MainObjectiveReportRequest } from '@s360/common-models/mainObjectiveReport';
import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { HttpResponse } from '@s360/common-models/httpResponse';
import Cookies from 'js-cookie';
const { Storage } = Plugins;


export class MainObjectiveService {
    private authorizationToken: string;
    constructor() {
        this.authorizationToken = this.getAuthorizationToken();
    }

    getAuthorizationToken = (): string => {
        let token = Cookies.get('token') + '';
        return token;
      }
    
    private async getObject() : Promise<AuthenticationResponse> {
        return new Promise<AuthenticationResponse>(async (resolve, reject)=>{
            Storage.get({key: 'sessionParams'}).then(res=>{
                resolve(JSON.parse(res.value!))
            }).catch(error=>{
                reject(error);
            })
        })
    }

    public async getMainObjectives(): Promise<HttpResponse> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request: MainObjectiveRequest = {
                    companyID: value.companyID,
                }
                const getMainObjectiveURL = process.env.REACT_APP_API_GET_MAIN_OBJECTIVES_URL || "";
                Axios.post(getMainObjectiveURL, request, config).then((value)=>{
                    let result : HttpResponse = { body: value.data, statusCode: value.status, headers: value.headers};
                    resolve(result);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }

    public async submitMainObjective(newMainObjective: MainObjective): Promise<any> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                newMainObjective.companyID = value.companyID;
                newMainObjective.lastModifiedBy = {
                    firstName: value.firstName,
                    lastName: value.lastName,
                    emailAddress: value.emailAddress
                }
                const submitMainObjectiveURL = process.env.REACT_APP_API_SUBMIT_MAIN_OBJECTIVE_URL || "";
                Axios.post(submitMainObjectiveURL, newMainObjective, config).then((value)=>{
                    resolve(value);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }

    public async updateMainObjective(mainObjective: MainObjective): Promise<any> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                mainObjective.companyID = value.companyID;
                mainObjective.lastModifiedBy = {
                    firstName: value.firstName,
                    lastName: value.lastName,
                    emailAddress: value.emailAddress
                }
                const updateMainObjectiveURL = process.env.REACT_APP_API_UPDATE_MAIN_OBJECTIVE_URL || "";
                Axios.post(updateMainObjectiveURL, mainObjective, config).then((value)=>{
                    resolve(value);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }

    public async deleteMainObjective(mainObjective: MainObjective): Promise<any> {
        return new Promise(async (resolve,reject) => {
            this.getObject().then(async (value) => {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                mainObjective.lastModifiedBy = {
                    firstName: value.firstName,
                    lastName: value.lastName,
                    emailAddress: value.emailAddress
                }
                const deleteURL = process.env.REACT_APP_API_DELETE_MAIN_OBJECTIVE_URL || "";
                Axios.post(deleteURL, mainObjective, config).then((value)=>{
                    resolve(value);
                }).catch((error)=> {
                    reject(error);
                })
            })         
        })
    }

    public async getMainObjectiveReport(mainObjectiveID: string): Promise<HttpResponse> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request: MainObjectiveReportRequest = {
                    companyID: value.companyID,
                    mainObjectiveID: mainObjectiveID
                }
                const getMainObjectiveReportURL = process.env.REACT_APP_API_GET_MAIN_OBJECTIVE_REPORT_URL || "";
                Axios.post(getMainObjectiveReportURL, request, config).then((value)=>{
                    let result : HttpResponse = { body: value.data, statusCode: value.status, headers: value.headers};
                    resolve(result);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }
}