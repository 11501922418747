import React, { useState, useEffect } from "react";
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText } from '@ionic/react';
import { APIService } from "../services/APIService";
import { RouteComponentProps } from "react-router";
import ForgotPasswordMessage from "../components/ForgotPasswordMessage";
import { HttpResponse } from "@s360/common-models/httpResponse";
import { useParams } from 'react-router-dom';

const ForgotPassword: React.FC<RouteComponentProps> = (props) => {
  const [email, setEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailBlankError, setEmailBlankError] = useState(false);
  const [emailInvalidError, setEmailInvalidError] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const handle : any = useParams();
  const [source, setSource] = useState<string>("");
  useEffect(()=>{
    setSource(handle['source']);
  },[])

  const sendMail = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if(!email) {
      setEmailBlankError(true);
      return;
    }

    const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if(!emailValid) {
      setEmailInvalidError(true);
      return;
    }

    try {
      const apiService = new APIService();

      // check email existance`
      
      // if (existRes.httpStatus === 404) {
      //   // requirements: do nothing
      //   props.history.push('/login', {direction: 'none'});
      //   window.open('/login','_self');
      //   return;
      // }

      // TODO: Change to HTML Mail
      // const html = "this is a test mail for reset password";
      await apiService.sendEmail(email, source).then((response: HttpResponse)=>{
        if(response.statusCode === 200){
          setSentEmail(true);
        }
      });
      

    } catch (e) {
      console.error(e);
    }


  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle size="large">Forgot Your Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
      { sentEmail 
          ? <ForgotPasswordMessage email={email} source={source}></ForgotPasswordMessage>
          : <div>
              <p className="ion-padding-start">
                Please confirm the email address you signed up with and we will send you a link to reset the password.
              </p>
              <form noValidate onSubmit={sendMail}>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked" color="primary">Email</IonLabel>
                    <IonInput name="email" type="text" value={email} spellCheck={false} autocapitalize="off" onIonChange={e => setEmail(e.detail.value!)}
                      required>
                    </IonInput>
                  </IonItem>

                  {formSubmitted && emailBlankError && <IonText color="danger">
                    <p className="ion-padding-start">
                      Email Address is required
                    </p>
                  </IonText>}

                  {formSubmitted && emailInvalidError && <IonText color="danger">
                    <p className="ion-padding-start">
                      Email Address is invalid
                    </p>
                  </IonText>}
                </IonList>

                <IonRow>
                  <IonCol>
                    <IonButton type="submit" expand="block">Send Email</IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton routerLink="/login/initiativePortal" color="light" expand="block">Cancel</IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;