

import { GeneralHttpResponse } from "@s360/common-models/apiResponses";
import axios from "axios";

import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { Plugins } from '@capacitor/core';
import { BusinessUnit } from "@s360/common-models/businessUnit";
import { RetrieveAllBusinessUnitsRequest } from "@s360/common-models/retrieveAllBusinessUnitsRequest";
const { Storage } = Plugins;

export class BusinessUnitService {

  private async getObject(): Promise<AuthenticationResponse> {
    return new Promise<AuthenticationResponse>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        resolve(JSON.parse(res.value!))
      }).catch(error => {
        reject(error);
      })
    })
  }

  public getBusinessUnit(aBusinessUnitID: string): Promise<BusinessUnit | null> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const getBusinessUnitURL = process.env.REACT_APP_API_READ_BUSINESS_UNIT_URL || "";
        const content = {
          businessUnitID: aBusinessUnitID,
        };
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        axios.post(getBusinessUnitURL, content, config).then(value => {
          let businessUnit: BusinessUnit | null;
          if (value.data) {
            businessUnit = value.data;
          } else {
            businessUnit = null;
            reject(businessUnit)
          }
          resolve(businessUnit);
        }).catch(error => {
          reject(error);
        });
      });
    });
  }

  public getAllBusinessUnits(): Promise<BusinessUnit[]> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const getAllBusinessUnitURL = process.env.REACT_APP_API_READ_ALL_BUSINESS_UNIT_URL || "";
        const content: RetrieveAllBusinessUnitsRequest = {
          companyID: value.companyID,
        };
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        axios.post(getAllBusinessUnitURL, content, config).then(value => {
          let businessUnits: BusinessUnit[] = [];
          if (value.data && value.data !== []) {
            businessUnits = value.data;
          }
          resolve(businessUnits);
        }).catch(error => {
          reject(error);
        });
      }).catch(error => {
        console.error(error)
      });
    });
  }

  public addBusinessUnit(newBusinessUnit: BusinessUnit): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const addBusinessUnitURL = process.env.REACT_APP_API_CREATE_BUSINESS_UNIT_URL || "";
        newBusinessUnit = {
          companyID: value.companyID,
          businessUnitName: newBusinessUnit.businessUnitName,
          businessUnitDescription: newBusinessUnit.businessUnitDescription,
          lastModifiedBy: {
            firstName: value.firstName,
            lastName: value.lastName,
            emailAddress: value.emailAddress
          }
        }
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        axios.post(addBusinessUnitURL, newBusinessUnit, config).then(res => {
          let result: GeneralHttpResponse = { httpStatus: res.status };
          resolve(result);
        }).catch(error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }

  public updateBusinessUnit(businessUnit: BusinessUnit): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const updateBusinessUnitURL = process.env.REACT_APP_API_UPDATE_BUSINESS_UNIT_URL || "";
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        businessUnit.lastModifiedBy = {
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress
        }
        axios.post(updateBusinessUnitURL, businessUnit, config).then(res => {
          let result: GeneralHttpResponse = { httpStatus: res.status };
          resolve(result);
        }).catch(error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }

  public deleteBusinessUnit(businessUnit: BusinessUnit): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        const deleteBusinessUnitURL = process.env.REACT_APP_API_DELETE_BUSINESS_UNIT_URL || "";
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        businessUnit.lastModifiedBy = {
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress
        }
        axios.post(deleteBusinessUnitURL, businessUnit, config).then(res => {
          let apiDataResponse: GeneralHttpResponse = {
            httpStatus: res.status
          };
          resolve(apiDataResponse);
        }).catch(error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }
}