import React from "react";
import { Visitor, Fields, validateEmail} from "../components/VisitorRegistration";
import { FormField } from "../components/VisitorFormFields";
import { RouteComponentProps } from 'react-router-dom';

import '../css/app.scss';

interface OwnProps extends RouteComponentProps {}

interface visitorRegistrationProps extends OwnProps {};

export const VisitorRegistrationForm: React.FunctionComponent<visitorRegistrationProps> = () => {

    const fields: Fields = {
        companyVisitorAccEmail: {
            id: "comapanyVisitorAccEmail",
            label: "Email for visitor account:",
            validation: {rule: validateEmail}
        }
    }

    return (
        <Visitor
              fields={fields}
              render={()=> (
                  <React.Fragment>
                      <div className="alert alert-success" role="alert">
                          Enter an email ID to handle your visitor account
                      </div>
                      <FormField {...fields.visitorAccountEmail}/>
                  </React.Fragment>
              )}
              />
       )
}