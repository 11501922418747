import { BcorpIdea } from "@s360/common-models/bcorp-idea";
import { Session } from "@s360/common-models/session";
import { BcorpIdeaStatus } from "@s360/common-models/bcorp-idea-status"
import { BcorpXlsxIdea } from "../models/bcorp-xlsx-Idea";
import { FileService } from "./FileService";
import axios, { AxiosResponse } from "axios";
import {KeywordType} from "@s360/common-models/keyword-type";
import Cookies from "js-cookie";
import { Initiative } from "@s360/common-models/initiative";
import { BaseObject } from '@s360/common-models/base-object'
import { InitiativeCreatedFrom } from "@s360/common-models/initiative-created-from";

export class BcorpIdeaService extends BaseObject {
    private readonly publishBcorpIdeasUrl: string = process.env.REACT_APP_API_PUBLISH_BCORP_IDEAS_URL!;
    private readonly updateBcorpIdeaStatusUrl: string = process.env.REACT_APP_API_UPDATE_BCORP_IDEA_STATUS_URL!;
    private readonly getBcorpIdeasUrl: string = process.env.REACT_APP_API_GET_BCORP_IDEAS_URL!;
    private readonly createBcorpIdeasUrl: string = process.env.REACT_APP_API_CREATE_BCORP_IDEAS_URL!;
    private readonly getBcorpInitiativeCountUrl: string = process.env.REACT_APP_API_GET_BCORP_INITIATIVE_COUNT_URL!;

    private fileService: FileService;

    private session: Session;

    private authorizationToken: string;

    private bcorpIdeaExcelKeyMap: { [key: string]: keyof BcorpIdea } = {
        "Answer(s)": `answers`,
        "Impact Area": `impactArea`,
        "Impact Topic": `impactTopic`,
        "Points Available": `pointsAvailable`,
        "Points Earned": `pointsEarned`,
        "Provided Answer(s)": `providedAnswers`,
        "Question": `question`,
        "Question Summary": `questionSummary`
    };

    constructor() {
        super();
        this.fileService = new FileService();
        this.session = this.getSession();
        this.authorizationToken = this.getAuthorizationToken();
    }

    getAuthorizationToken = (): string => {
        let token = Cookies.get('token') + '';
        return token;
      }

    private getSession(): Session {
        const sessionJson: string | undefined = Cookies.get(`session`);

        if(!sessionJson) {
            throw new Error('Not logged in, unable to perform operation');
        }

        return JSON.parse(sessionJson);
    }

    public publishBcorpIdea(bcorpJson: string): Promise<string> {
        super.logger.debug(`publishBcorpIdea url ${this.publishBcorpIdeasUrl}`);
        super.logger.debug(`Sending post request`);
        return new Promise<string>((resolve, reject) => {
            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            axios.post(`${this.publishBcorpIdeasUrl}`, bcorpJson, config)
                .then((response: AxiosResponse) => {
                    console.log(`[BcorpIdeaService][publicBcorpIdea] response status ${response.status}`);
                    if(response.status === 200) {
                        resolve(response.data);
                    }

                    throw new Error(response.data);
                })
                .catch((error) => {
                    super.logger.error(error.message);
                    reject(error);
                });
        });
    }

    public createBcorpIdeas(bcorpIdeasJson: string): Promise<string> {
        super.logger.debug(`createBcorpIdeas url ${this.createBcorpIdeasUrl}`);
        super.logger.debug(`Sending post request`);
        return new Promise<string>((resolve, reject) => {
            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            axios.post(`${this.createBcorpIdeasUrl}`, bcorpIdeasJson, config)
                .then((response: AxiosResponse) => {
                    console.log(`[BcorpIdeaService][publicBcorpIdea] response status ${response.status}`);
                    if(response.status === 200) {
                        resolve(response.data);
                    }

                    throw new Error(response.data);
                })
                .catch((error) => {
                    super.logger.error(error.message);
                    reject(error);
                });
        });
    }

    public getBcorpIdeas(): Promise<BcorpIdea[]> {
        //TODO once the data type for bcorpIdeaRequest has been created define the data type of this object to BcorpIdeaRequest

        const data = {
            companyID: this.session.companyID,
            userRole: this.session.userRole,
        }

        super.logger.debug(`getBcorpIdeas url ${this.getBcorpIdeasUrl}`);
        super.logger.debug(`Sending post request`);

        return new Promise<BcorpIdea[]> ((resolve, reject) => {
            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            axios.post(this.getBcorpIdeasUrl, data, config)
                .then((response: AxiosResponse<BcorpIdea[]>) => {
                    super.logger.debug(`response status ${response.status}`);

                    resolve(response.data);
                })
                .catch((error: Error) => {
                    super.logger.error(error.message);
                    reject(error);
                })
        });
    }

    public getBcorpInitiativeCount(category: string, createdFrom: InitiativeCreatedFrom, trackingStatus: string): Promise<number> {
        console.log(`getCountOfBcorpInitiatives url ${this.getBcorpInitiativeCountUrl}`);
        console.log(`Sending post request`);
        console.log(`category ${category}`);
        console.log(`createdFrom ${createdFrom}`);

        const data = {
            companyID: this.session.companyID,
            category: category,
            createdFrom: createdFrom,
            trackingStatus: trackingStatus
        }
        return new Promise<number> ((resolve, reject) => {
            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            axios.post(this.getBcorpInitiativeCountUrl, data, config)
                .then((response: AxiosResponse<{value: number}>) => {
                    console.log(response);
                    resolve(response.data.value);
                })
                .catch((error: Error) => {
                    console.log(error.message);
                    reject(error);
                });
        }
        );
    }
    public updateBcorpIdeaStatus(bcorpIdeaId: string, bcorpIdeaStatus: BcorpIdeaStatus): Promise<any> {
        super.logger.debug(`updateBcorpIdeaStatus url ${this.updateBcorpIdeaStatusUrl}`);
        super.logger.debug(`Sending post request`);

        //TODO once the data type for bcorpIdeaRequest has been created define the data type of this object to BcorpIdeaRequest
        const data = {
            bcorpIdeaId: bcorpIdeaId,
            companyID: this.session.companyID,
            bcorpIdeaStatus:  bcorpIdeaStatus
        }

        return new Promise<any>((resolve, reject) => {
            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            axios.post(this.updateBcorpIdeaStatusUrl, data, config)
                .then((response: AxiosResponse<string>) => {
                    super.logger.debug(`response status ${response.status}`);
                    resolve(response.data);
                })
                .catch((error: Error) => {
                    super.logger.error(error.message);
                    reject(error);
                });
        });
    }

    public xlsxToBcorpIdeas(file: File): Promise<BcorpIdea[]> {
        return new Promise<BcorpIdea[]>((resolve, reject) => {
            this.fileService.xlsxToType<BcorpXlsxIdea>(file)
                .then((bcorpIdeaExcels: BcorpXlsxIdea[]) => {
                    this.bcorpXlsxIdeasToBcorpIdeas(bcorpIdeaExcels)
                        .then((bcorpIdeas: BcorpIdea[]) => {
                            resolve(bcorpIdeas);
                        })
                        .catch((error: Error) => {
                            super.logger.error(error.message);

                            reject(error);
                        });
                }).catch((error: Error) => {
                    super.logger.error(error.message);
                    reject(error);
                });
        });
    }

    public ideaToInitiative (bcorpIdea: BcorpIdea): Initiative {
        const initiative: Initiative = {
            author: ``,
            companyID: bcorpIdea.companyID!,
            description: bcorpIdea.question,
            goals: [bcorpIdea.impactTopic],
            indicators: [],
            note: '',
            objective: '',
            targets: [],
            title: bcorpIdea.questionSummary,
            trackingStatus: 'New',
            archived: false,
            category: bcorpIdea.category,
            uniqueCategories: [bcorpIdea.category ?? ''],
            createdAt: Date.now(),
            isDeleted: false,
            createdFrom: InitiativeCreatedFrom.BCORP,
        }
        return initiative;
    }

    public bcorpXlsxIdeasToBcorpIdeas(bcorpIdeaExcels: BcorpXlsxIdea[]): Promise<BcorpIdea[]> {
        return new Promise<BcorpIdea[]>((resolve) => {
            const bcorpIdeas: BcorpIdea[] = bcorpIdeaExcels.map((bcorpIdeaExcel: BcorpXlsxIdea) => {
                const bcorpIdea: BcorpIdea = {
                    companyID: this.session.companyID,
                    createdAt: Date.now(),
                    answers: [],
                    impactArea: ``,
                    impactTopic: ``,
                    impactScore: 0,
                    pointsAvailable: 0,
                    pointsEarned: 0,
                    providedAnswers: [],
                    keywordType: KeywordType.BCORP,
                    question: ``,
                    questionSummary: ``,
                    isDeleted: false,
                    bcorpIdeaStatus: BcorpIdeaStatus.PENDING
                };

                //Maps the values from each key of the excel spreadsheet column to a model
                for (const key in bcorpIdeaExcel) {
                    //If the key doesn't exist in the key map it throws an error
                    if (key in this.bcorpIdeaExcelKeyMap) {
                        const mappedKey = this.bcorpIdeaExcelKeyMap[key];
                        bcorpIdea[mappedKey] = bcorpIdeaExcel[key];
                    }
                    else {
                        throw new Error(`Invalid spread sheet must contain the correct excel columns`);
                    }
                }
                bcorpIdea.impactScore = bcorpIdea.pointsAvailable - bcorpIdea.pointsEarned;
                //Turns answers into an array and cleans them
                bcorpIdea.answers = this.listCleaner(bcorpIdea.answers);

                bcorpIdea.providedAnswers = this.listCleaner(bcorpIdea.providedAnswers);
                return bcorpIdea;
            });
            resolve(bcorpIdeas);
        });
    }

    private listCleaner(list: any) {
        return JSON.stringify(list).split(/\\r\\n/g)
            .map((providedAnswer) => {
                return providedAnswer.replace(/"|'/g, '');
            });
    }
}