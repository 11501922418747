import * as React from "react";
import { IonPage, IonHeader, IonContent, IonToolbar, IonTitle, IonButton } from "@ionic/react";
import '../css/app.scss';
import { FieldProps } from "./VisitorFormFields";

export interface Fields {
    [key: string]: FieldProps;
}

export interface FormProps {
    fields: Fields;
    render: () => React.ReactNode;
}

export interface FormContext extends FormState {
    setValues: (values: Values) => void;
    validate: (fieldName: string, value: string) => void;
}

export const FormContext = React.createContext<FormContext | undefined>(undefined);

export interface Values {
    [key: string]: any;
}

export interface Errors {
    [key: string]: string;
}

export interface FormState {
    values: Values;
    errors: Errors;
    registrationSuccess?: boolean;
}

export interface VisitorProps extends FormProps {
    emailAddress: string;
}

export const validateEmail = (value: string, fieldName: string): string => 
    value &&
       value.search(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
       )
       ?"Please provide a valid email address"
       :"";


export class Visitor extends React.Component<FormProps, FormState>{
    private emailAddressVaidated: boolean;
    private registrationSuccess: boolean;
    private formValidated: boolean;

    constructor(props: any){
        super(props);
        const errors: Errors = {};
        const values: Values = {};
        this.state = {
            errors,
            values
        };
        this.emailAddressVaidated = false;
        this.registrationSuccess = false;
        this.formValidated = false;
    }

    private setValues = (values: Values) => {
      this.setState({values: {...this.state.values, ...values}})
    }

    private validate = (fieldName: string, value: string): string => {
        let newError: string ="";
        if(
        this.props.fields[fieldName] &&
        this.props.fields[fieldName].validation
        ){
            newError = this.props.fields[fieldName].validation!.rule(
                value,
                fieldName,
                this.props.fields[fieldName].validation!.args
            );
             if(fieldName === 'comapanyVisitorAccEmail' && newError === '')
            {
                this.emailAddressVaidated = true;
            }
        }

        if(this.emailAddressVaidated === true){
            this.formValidated = true;
        }
       
        this.state.errors[fieldName] = newError;
        this.setState({
            errors: {...this.state.errors, [fieldName]: newError}
        })
        return newError;
    }

    public render() {
        const { registrationSuccess} = this.state;
        const context: FormContext = {
            ...this.state,
            setValues: this.setValues,
            validate: this.validate
        };

        return(
            <IonPage id="login-registration-page">
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>Visitor Account Registration</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <FormContext.Provider value={context}>
                        <form className="login-registration-form ion-padding">
                            <div className="container">
                                {this.props.render()}
                                <div className="form-group">
                                    <IonButton
                                       type="submit"
                                       disabled={!this.formValidated}
                                       expand="block"
                                       >Submit</IonButton>
                                </div>
                                {registrationSuccess===true && (
                                    <div className="alert alert-info" role="alert">
                                         A Visitor account has been created for the company, and an email has been triggered to the visitor account email of the company to set their password
                                        </div>
                                )}
                                {registrationSuccess===false && (
                                    <div className="alert alert-danger" role="alert">
                                       Oh No! There was an error trying to register the account. Please try again
                                    </div>
                                )}
                            </div>
                        </form>
                    </FormContext.Provider>
                </IonContent>
            </IonPage>
        )
    }
}