import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonGrid, IonLabel, IonRow, IonTitle, IonToast } from "@ionic/react";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { BcorpOverviewCardProps } from "./BcorpOverviewCardProps";
import { BcorpIdeaService } from "../../services/BcorpIdeaService";
import { InitiativeCreatedFrom } from "@s360/common-models/initiative-created-from";
import { trimmer } from "../../helper/trimmer";
import '../../css/InitiativesTop.css'

const BcorpOverviewCard: React.FC<BcorpOverviewCardProps> = (props) => {
    const category = props.title.toLowerCase() || 'primary';
    const [inProgress, setInProgress] = useState<number>(0);
    const [newInitiatives, setNewInitiatives] = useState<number>(0);
    const [implemented, setImplemented] = useState<number>(0);


    useEffect(() => {
        const bcorpIdeaService: BcorpIdeaService = new BcorpIdeaService();

        bcorpIdeaService.getBcorpInitiativeCount(capitalize(category), InitiativeCreatedFrom.BCORP, `In Progress`)
            .then((value: number) => {
                console.log(`Number of Bcorp Ideas In Progress: ${value}`)
                setInProgress(value)
            })
            .catch(() => {
                props.setErrorMessage('Unable to fetch number of Bcorp Ideas In Progress')
            });

        bcorpIdeaService.getBcorpInitiativeCount(capitalize(category), InitiativeCreatedFrom.BCORP, `New`)
            .then((value: number) => {
                console.log(`Number of Bcorp Ideas New: ${value}`)
                setNewInitiatives(value)
            })
            .catch(() => {
                props.setErrorMessage('Unable to fetch number of Bcorp Ideas New')
            });

        bcorpIdeaService.getBcorpInitiativeCount(capitalize(category), InitiativeCreatedFrom.BCORP, `Implemented`)
            .then((value: number) => {
                console.log(`Number of Bcorp Ideas Implemented: ${value}`)
                setImplemented(value)
            })
            .catch(() => {
                props.setErrorMessage('Unable to fetch number of Bcorp Ideas Implemented percentage')
            });
    }, [category, props]);

    const getImplementedPercentage = () => {
        const implementedPercentage = implemented / (newInitiatives + inProgress + implemented) * 100;
        return trimmer(implementedPercentage);
    }



    return (
        <IonCard
            id={props.id || ""}
            onClick={() => props.onCardClick()}
        >
            <IonCardHeader>
                <IonCardTitle
                    className="bold"
                    color={category}
                >
                    {props.title.toUpperCase()}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonLabel>
                            {getImplementedPercentage() || "--"}% DONE
                        </IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonLabel>
                            {inProgress || "--"} IN PROGRESS
                        </IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonLabel>
                            {newInitiatives || "--"} NEW
                        </IonLabel>
                    </IonRow>
                </IonGrid>

            </IonCardContent>
        </IonCard>
    );
}

export default BcorpOverviewCard;