import React, { useState, useEffect } from "react";
import Select from 'react-select';
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonCard,
  IonCardContent,
  IonToast
} from "@ionic/react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import "./ReportPage.css";
import { MainObjectiveChartData, MainObjectiveSelectOption } from "@s360/common-models/mainObjectiveReport";
import { MainObjective } from "@s360/common-models/mainObjective";
import { MainObjectiveService } from "../services/MainObjectiveService";
import { HttpStatus } from "@s360/common-models/httpStatus";
import { HttpResponse } from "@s360/common-models/httpResponse";
import TargetChart from "../components/MainObjectiveChart";

interface OwnProps extends RouteComponentProps {}
interface ReportProps extends RouteComponentProps, UserState {}

const ReportMainObjective: React.FC<ReportProps> = ({history}) => {
  const [chartData, setChartData] = useState<MainObjectiveChartData>();
  const [showGettingError, setShowGettingError] = useState<boolean>(false);
  const [targetOptions, setTargetOptions] = useState<MainObjectiveSelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<MainObjectiveSelectOption>();
  
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: 'black',
    }),
    control: (provided: any) => ({
      ...provided,
      border: '1px solid black',
      borderRadius: '0',
    }),
  }

  useEffect(() => {
    getReportData();
  }, [selectedOption]);

  useEffect(() => {
    getTargetSelections();
  }, []);

  const getTargetSelections = () => {
    const targetService = new MainObjectiveService();
    
    targetService
      .getMainObjectives()
      .then((response: HttpResponse) => {
        if (response.statusCode === HttpStatus.OK) {
          let options: MainObjectiveSelectOption[] = [];
          let targets: MainObjective[] = response.body;
          targets.forEach(target => {
            options.push({value: target.id + '', label: target.title})
          });
          setTargetOptions(options);
          if (options.length > 0) setSelectedOption(options[0]);
        } else {
          setShowGettingError(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowGettingError(true);
      });
  }

  const getReportData = () => {
    const mainObjectiveService = new MainObjectiveService();
    if(!selectedOption) return;

    mainObjectiveService
      .getMainObjectiveReport(selectedOption.value)
      .then((response: HttpResponse) => {
        if (response.statusCode === HttpStatus.OK) {
          setChartData(response.body);
        } else {
          setShowGettingError(true);
        }
      })
      .catch((error) => {
        console.error("Error get factors:", error);
        setShowGettingError(true);
      });
      

    
  };

  const handleChange = (option: MainObjectiveSelectOption) => {
    setSelectedOption(option);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle size="large" style={{color: 'white'}}>Goals</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCard style={{width: "100%"}}>
              <IonCardContent>
                <IonGrid>
                  <IonRow>
                      <IonCol size="6">
                        <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={targetOptions}
                            styles={customStyles}
                          />  
                      </IonCol>
                  </IonRow>
                  <IonRow>
                    <TargetChart 
                      chartData={chartData}
                    >
                    </TargetChart>
                  </IonRow>
                </IonGrid>
                
              </IonCardContent>
            </IonCard>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonToast
        isOpen={showGettingError}
        onDidDismiss={() => setShowGettingError(false)}
        message="Failed to get targets report data"
        duration={1000}
        position="top"
        color="danger"
      />
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(ReportMainObjective),
});
