import Axios from "axios";
import { ResetPassword } from "@s360/common-models/resetPassword";

export class ResetService {
    public reset(resetParams: ResetPassword) {
        return new Promise<any>(async (resolve, reject) => {
            const resetAPIUrl = process.env.REACT_APP_API_RESET_PASSWORD_URL || "";
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            try {
                Axios.interceptors.request.use(req=>{
                   return req;
                })
                Axios.interceptors.response.use(res => {
                    resolve(res)
                    return res;
                }, (error)=>{
                    if(error.response.status === 401){
                       reject(error.response);
                    }
                })
                await Axios.post(resetAPIUrl, resetParams, config);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        })
    }
}