import Goal from "@s360/common-models/goal";
import "../css/InitiativeReview.css";
import Target from "@s360/common-models/target";
import Indicator from "@s360/common-models/indicator";
import React, { useState } from "react";
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from "@ionic/react";
import { getSdgIcon } from "../helper/SdgIconGetter";
import { chevronDownOutline, chevronForwardOutline, closeSharp, returnDownForwardOutline } from "ionicons/icons";
import { dateConverter } from "../helper/DateConverter";

const InitiativeReview: React.FC<{
  title: string;
  objective: string;
  description: string;
  selectedCategories: string[] | undefined;
  status: string;
  progress?: string;
  businessUnitName?: string;
  businessUnitID?: string;
  ownerFullName?: string;
  ownerEmailAddress?: string;
  targetDate?: number;
  note?: string;
  create: () => void;
  createDisabled: boolean;
  edit?: boolean;
  deletee: string;
  setDeletee: (deletee: string) => void;
  removeGoal: (object: Goal, objects: Goal[]) => Goal[];
  removeTarget: (object: Target, objects: Target[]) => Target[];
  removeIndic: (object: Indicator, objects: Indicator[]) => Indicator[];
  selectedGoals: Goal[];
  setSelectedGoals: (selectedOptions: Goal[]) => void;
  selectedTargets: Target[];
  setSelectedTargets: (selectedOptions: Target[]) => void;
  selectedIndics: Indicator[];
  setSelectedIndics: (selectedOptions: Indicator[]) => void;
}> = (props) => {

  const [openTarget, setOpenTarget] = useState<Target>();
  const [viewIndicators, setViewIndicators] = useState<boolean>(false);

  const deleteGoal = (goal: Goal): void => {
    props.setSelectedGoals(
      props.removeGoal(goal, props.selectedGoals)
    );
    props.setDeletee(goal.name.toString());
  }

  const deleteTarget = (target: Target): void => {
    setOpenTarget(target);
    props.selectedIndics.forEach(indic => {
      if (target.name.toString() === indic.targetName.toString()) {
        props.setSelectedIndics(
          props.removeIndic(indic, props.selectedIndics)
        )
        props.setDeletee(indic.name.toString());
      }
    });
    props.setSelectedTargets(
      props.removeTarget(target, props.selectedTargets)
    );
    props.setDeletee(target.name.toString());
  }

  const deleteIndic = (indic: Indicator): void => {
    props.setSelectedIndics(
      props.removeIndic(indic, props.selectedIndics)
    );
    props.setDeletee(indic.name.toString());
  }

  const expandTarget = (target: Target): void => {
    setViewIndicators(target === openTarget ? !viewIndicators : true);
    setOpenTarget(target);
  }

  const checkIndicators = (target: Target): boolean => {
    return (target.indicators.some(indic => props.selectedIndics.indexOf(indic) >= 0))
  }

  const checkTargets = (goal: Goal): boolean => {
    return (goal.targets.some(target => props.selectedTargets.indexOf(target) >= 0))
  }

  const generateSelectedIndicators = (target: Target): React.ReactElement => {
    return (<>
      {props.selectedIndics.map(indic => {
        if (target.indicators.includes(indic)) {
          return (<>
            <IonRow className="review-indic-item">
              <IonCol size="0.5"/>
              <IonCol size="0.3">
                <IonIcon className="indic-item-icon" icon={returnDownForwardOutline}/>
              </IonCol>
              <IonCol size="0.7">
                <strong>{indic.name}</strong>
              </IonCol>
              <IonCol size="10">
                {indic.desc}
              </IonCol>
              <IonCol size="0.5">
                <IonButton
                  color="black"
                  fill="clear"
                  onClick={() => {deleteIndic(indic)}}
                >
                  <IonIcon className="sdg-delete" icon={closeSharp}/>
                </IonButton>
              </IonCol>
            </IonRow>
          </>)
        }
      })}
    </>)
  }

  const generateSelectedTargets = (): React.ReactElement => {
    return (<>
    {props.selectedGoals.map(goal => {
      return (<>
          <IonRow className="review-goal-entry">
            <IonCol size="1.2">
              <IonButton
                className="goal-delete"
                fill="clear"
                onClick={() => {deleteGoal(goal)}}
              >
                <IonIcon color="light" className="sdg-delete" icon={closeSharp}/>
              </IonButton>
              <IonImg
                id="review-goal"
                src={getSdgIcon(goal.name)}
              />
            </IonCol>
            <IonCol className="review-target-indicators" size="10.8">
              {checkTargets(goal) && props.selectedTargets.map(target => {
                if (goal.targets.includes(target)) {
                  return (<>
                    <IonRow className={checkIndicators(target) ? "review-target-item-expandable" : "review-target-item"}>
                      <IonCol size="0.4" onClick={() => {expandTarget(target)}}>
                        {checkIndicators(target) && (<IonIcon
                          icon={viewIndicators && target == openTarget ? chevronDownOutline : chevronForwardOutline}
                          onClick={() => {expandTarget(target)}}
                        />)}
                      </IonCol>
                      <IonCol size="1.0" onClick={() => {expandTarget(target)}}>
                        <strong>{target.name}</strong>
                      </IonCol>
                      <IonCol size="10.1" onClick={() => {expandTarget(target)}}>
                        {target.desc}
                      </IonCol>
                      <IonCol size="0.5">
                        <IonButton
                          color="black"
                          fill="clear"
                          onClick={() => {deleteTarget(target)}}
                        >
                          <IonIcon className="sdg-delete" icon={closeSharp}/>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    {viewIndicators && target === openTarget && generateSelectedIndicators(target)}
                  </>)
                }
              })}
              {!checkTargets(goal) && (
                <IonRow>
                  <IonCol>
                    <i><strong><br/>No Selected Targets</strong></i>
                  </IonCol>
                </IonRow>
              )}
            </IonCol>
          </IonRow>
        </>)
      })}
    </>)
  }

  const generateSelectedSDGs = (): React.ReactElement => {
    return (<>
      <IonRow className="w-100">
        <IonCol size="1.2"/>
        <IonCol className="review-header" size="1.3"><strong>TARGET/INDICATORS</strong></IonCol>
        <IonCol className="review-header" size="9.5"><strong>DESCRIPTION</strong></IonCol>
      </IonRow>
      {generateSelectedTargets()}
    </>)
  }
  
  const generateInitiativeReview = (): React.ReactElement => {
    return (
    <IonGrid>
      <IonRow>
        <IonCol size="10.5">
          <IonRow>
            <IonLabel className="review-title">{props.title}</IonLabel>
          </IonRow>
          <IonRow>
            <IonButton color={props.selectedCategories?.includes("Collaboration") ? "primary" : "light"}>Collaboration</IonButton>
            <IonButton color={props.selectedCategories?.includes("Environment") ? "primary" : "light"}>Environment</IonButton>
            <IonButton color={props.selectedCategories?.includes("Governance") ? "primary" : props.selectedCategories?.includes("Economy") ? "primary" : "light"}>Governance</IonButton>
            <IonButton color={props.selectedCategories?.includes("Society") ? "primary" : "light"}>Society</IonButton>
          </IonRow>
        </IonCol>
      </IonRow>

      {(props.businessUnitID || props.ownerEmailAddress || props.targetDate) && (
      <IonRow>
        {props.businessUnitID && (<IonCol>
          <IonLabel><i><strong>BUSINESS UNIT:</strong></i>&nbsp;{props.businessUnitName}</IonLabel>
        </IonCol>)}
        {props.ownerEmailAddress && (<IonCol>
          <IonLabel><i><strong>OWNER:</strong></i>&nbsp;{props.ownerFullName}</IonLabel>
        </IonCol>)}
        {props.targetDate && (<IonCol>
          <IonLabel><i><strong>TARGET DATE:</strong></i>&nbsp;{dateConverter(new Date(props.targetDate))}</IonLabel>
        </IonCol>)}
      </IonRow>)}

      <IonRow>
        <IonLabel><i><strong>OBJECTIVE:</strong></i>&nbsp;{props.objective}</IonLabel>
      </IonRow>

      {props.note && (<IonRow>
        <IonLabel><i><strong>NOTE:</strong></i>&nbsp;{props.note}</IonLabel>
      </IonRow>)}

      {props.selectedGoals.length > 0 && generateSelectedSDGs()}

      {props.selectedGoals.length === 0 && (
        <IonRow>
          <IonCol>
            <i><strong><br/>No Selected Goals</strong></i>
          </IonCol>
        </IonRow>
      )}

      <IonRow>
        <IonCol>
          <IonButton
            id="review-create"
            className="float-right"
            shape="round"
            disabled={props.createDisabled}
            onClick={() => {props.create()}}>
            {props.edit ? "Save" : "Create"}
          </IonButton>
        </IonCol>
      </IonRow>

    </IonGrid>);
  };

  return (<>
    {generateInitiativeReview()}
  </>)
}

export default InitiativeReview;