import { HttpResponse } from "@s360/common-models/httpResponse";
import { MainObjective } from "@s360/common-models/mainObjective";
import { MainObjectiveService } from "../services/MainObjectiveService";

export async function goalInitiativeCleaner(initiativeID: string) {
  const mainObjectiveService: MainObjectiveService = new MainObjectiveService();

  mainObjectiveService.getMainObjectives().then((result: HttpResponse) => {
    const goals: MainObjective[] = result.body.filter((goal: MainObjective) => {
      return (goal.initiativeIds.includes(initiativeID));
    })
    if (goals.length > 0) {
      let updateGoal: MainObjective = goals[0];
      updateGoal.initiativeIds = updateGoal.initiativeIds.filter((id: string) => {
        return (id !== initiativeID);
      })
      mainObjectiveService.updateMainObjective(updateGoal);
    }
  })
}