import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonRow, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { Idea } from "@s360/common-models/idea";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { dateToLocale } from "../helper/DateConverter";

const IdeaDetails: React.FC<{
  closeAction: () => void;
  acceptIdea: (idea: Idea) => void;
  rejectIdea: (idea: Idea) => void;
  idea: Idea;
}> = (props) => {

  const labelTexts = {
    title: "Title",
    author: "Author",
    date: "Creation Date",
    description: "Description",
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            {props.idea.title}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.closeAction()}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid className="max-width">
        <IonRow>
            <IonCol>
              <IonLabel>
                <h2>{labelTexts.title}</h2>
              </IonLabel>
              <IonInput
                value={props.idea.title}
                className={"border rounded shadow-sm"}
                type="text"
                readonly={true}
              ></IonInput>
            </IonCol>
            <IonCol>
              <IonLabel>
                <h2>{labelTexts.author}</h2>
              </IonLabel>
              <IonInput
                value={props.idea.author || "Visitor"}
                className={"border rounded shadow-sm"}
                readonly={true}
              ></IonInput>
            </IonCol>
            <IonCol>
              <IonLabel>
                <h2>{labelTexts.date}</h2>
              </IonLabel>
              <IonInput
                value={dateToLocale({ dateNumber: props.idea.createdAt, time: true })}
                className={"border rounded shadow-sm"}
                readonly={true}
              ></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonLabel>
              <h2>{labelTexts.description}</h2>
            </IonLabel>
            <IonTextarea
                value={props.idea.description}
                className={"border rounded shadow-sm"}
                rows={10}
                readonly={true}
              ></IonTextarea>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="10"/>
            <IonCol size="1">
              <IonButton
                className="float-right"
                shape="round"
                onClick={() => {
                  props.acceptIdea(props.idea)
                }}
              >
                Accept
              </IonButton>
            </IonCol>
            <IonCol size="1">
              <IonButton
                className="float-right"
                shape="round"
                onClick={() => {
                  props.rejectIdea(props.idea)
                }}
              >
                Reject
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  )
}

export default IdeaDetails;