import React from "react";
import { CartesianGrid, Bar, ReferenceLine, BarChart, Tooltip, XAxis, YAxis, Legend, LabelList } from "recharts";
import { IonCard, IonCardHeader,IonCardContent, IonCardTitle, IonGrid, IonRow,IonLabel} from "@ionic/react";
import { MainObjectiveChartData } from "@s360/common-models/mainObjectiveReport"
import './MainObjectiveChart.css'

const MainObjectiveChart: React.FC<{
  chartData?: MainObjectiveChartData;
}> = (props) => {

  const CustomTooltip = (value: any) => {
    if (value.active && value.payload && value.payload.length > 1) {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle style={{color: 'black'}}>Details:</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                {/* <IonCol > */}
                  <IonLabel  style={{color: 'black'}}>First Year Emission ({value.payload[0].payload.startYear}): </IonLabel>
                  </IonRow>
                {/* </IonCol> */}
                <IonRow>
                  <IonLabel>{"    " + value.payload[0].payload.startYearEmission.toFixed(1) + " kg CO2-e"}</IonLabel>
                </IonRow>
              
              {/* <IonRow> */}
                <IonRow >
                  <IonLabel style={{color: 'black'}}>Current Year Actual Emission: </IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel>{"    " + value.payload[0].payload.currentYearEmission.toFixed(1) + " kg CO2-e (" + value.payload[0].payload.diffPercentage + ")"}</IonLabel>
                </IonRow>
              {/* </IonRow> */}
              {/* <IonRow> */}
                <IonRow>
                  <IonLabel style={{color: 'black'}}>Current Year Target Emission: </IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel>{"    " + value.payload[0].payload.currentYearTargetEmission.toFixed(1) + " kg CO2-e"}</IonLabel>
                </IonRow>
              {/* </IonRow> */}
              {/* <IonRow> */}
                <IonRow>
                  <IonLabel style={{color: 'black'}}>Last Year Target Emission: </IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel>{"    " + value.payload[0].payload.lastYearTargetEmission.toFixed(1) + " kg CO2-e"}</IonLabel>
                </IonRow>
              {/* </IonRow> */}
            </IonGrid>
          </IonCardContent>
        </IonCard>
        // <div className="custom-tooltip">
          
        //   <p className="label">{`${value.payload[0].name} : ${value.payload[0].payload.percentage}`}</p>
        //   <p className="intro">{`${value.payload[1].name} : ${value.payload[1].payload.diffPercentage}`}</p>
        //   {/* <p className="intro">{value.label}</p>
        //   <p className="desc">Anything you want can be displayed here.</p> */}
        // </div>
        
      );
    }
  
    return null;
  };

  const getBarColor = () => {
    if(props.chartData) {
      if(props.chartData.target - props.chartData.value > 0.5) {
        return "#ff4040";
      } else if (props.chartData.target - props.chartData.value < -0.5) {
        return "#2f8cf7";
      } else {
        return "#fff836";
      }
    } else {
      return "4d4d4d";
    }
  }

  const getRefferenceLineColor = () => {
    if(props.chartData) {
      if(props.chartData.target - props.chartData.value > 1) {
        return "#ff0000";
      } else if (props.chartData.target - props.chartData.value < -1) {
        return "#0026ff";
      } else {
        return "#f5d800";
      }
    } else {
      return "4d4d4d";
    }
  }

  return (
    <>
      <BarChart
        width={400}
        height={500}
        data={[props.chartData]}
        margin={{ top: 25, right: 5, left: 5, bottom: 5 }}
      >
        <XAxis 
          dataKey="xTitle" 
          padding={{ left: 40, right: 40 }}
        />
        <YAxis
          domain={[props.chartData ? props.chartData.startYear: 0, props.chartData ? props.chartData.endYear : 100]} 
          allowDataOverflow={true}
          interval={0}
          tickCount={props.chartData ? props.chartData.endYear - props.chartData.startYear + 1 : 10 }
        />
        <Tooltip content={<CustomTooltip />}/>
        <Legend />
        <CartesianGrid stroke="#f5f5f5" />
        <ReferenceLine y={props.chartData?.target} stroke={getRefferenceLineColor()} strokeDasharray="3 3"></ReferenceLine>
        <Bar stackId="bar" name="Actual" dataKey="value" fill={getBarColor()} >
            <LabelList data={props.chartData ? [props.chartData] : []} dataKey="percentage"  />
        </Bar>
        <Bar stackId="bar" name="Target" dataKey="diff" fill="#b3b3b3" ></Bar>
      </BarChart>
    </>
  );
};

export default MainObjectiveChart;
