export function getSdgColor (goal: string): string {
  let goalNumber: string = !isNaN(parseInt(goal)) ? (parseInt(goal) < 10 ? `0${parseInt(goal)}` : parseInt(goal).toLocaleString()) : (goal);
  switch (goalNumber) {
    case '01':
      return `#e5243b`;
    case '02':
      return `#DDA63A`;
    case '03':
      return `#4C9F38`;
    case '04':
      return `#C5192D`;
    case '05':
      return `#FF3A21`;
    case '06':
      return `#26BDE2`;
    case '07':
      return `#FCC30B`;
    case '08':
      return `#A21942`;
    case '09':
      return `#FD6925`;
    case '10':
      return `#DD1367`;
    case '11':
      return `#FD9D24`;
    case '12':
      return `#BF8B2E`;
    case '13':
      return `#3F7E44`;
    case '14':
      return `#0A97D9`;
    case '15':
      return `#56C02B`;
    case '16':
      return `#00689D`;
    case '17':
      return `#19486A`;
    default:
      return 'green';
  }
}
