import React from "react";
import { Link } from "react-router-dom";

const ForgotUsernameMessage: React.FC<{
  email: string;
}> = (props) => {
  return (
    <>      
        <p>Your user name has been sent to the following email address.</p>
        <h2>
          {props.email}
        </h2>
        <br/>
        <Link to='/login'>Back to Login Page</Link>
    </>
  );
};

export default ForgotUsernameMessage;
