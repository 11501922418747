import React, { useState, useEffect } from "react";
import { ellipse } from "ionicons/icons";
import '../css/app.scss';
import '../css/VisitorLanding.css';
import {
  IonToast,
  IonModal,
  IonLabel,
  IonIcon,
  IonImg
} from "@ionic/react";
import VisitorInitiativeModal from '../components/VisitorInitiativeModal'
import { useHistory } from 'react-router-dom';
import "../components/modal.css";
import ideaButton from "../images/submit_idea_button.svg";

const VisitorLanding: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [successfulSubmitToast, setSuccessfulSubmitToast] = useState<boolean>(false);
  const history = useHistory();
  const closeModal = () => {
    window.open(process.env.REACT_APP_VISITOR_URL, '_self');
    setShowModal(false);
  };

  const showToast = () => {
    setSuccessfulSubmitToast(true);
  }

  const showSuccess = () => {
    setShowModal(false);
    window.open('/ideaSubmit',"_self");
  }

  return (
    <div className="idea-container">
      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        backdropDismiss={false}
        cssClass="initiative-modal"
      >
        <VisitorInitiativeModal
           closeAction={closeModal}
           successAction={showSuccess}
      >
        </VisitorInitiativeModal>
      </IonModal>
      <IonToast
          isOpen={successfulSubmitToast}
          onDidDismiss={() => setSuccessfulSubmitToast(false)}
          message="Idea is submitted Successfully"
          duration={3000}
          position="top"
          color="success"
        />
      <IonLabel><h1 className="idea-heading">How can our organisation be more sustainable?</h1></IonLabel>
      <div className = "content-container">
          <div className = "ideas-left">
            <IonLabel><h3 className="ideas-label" id="idea-1">Reduce waste by....?</h3></IonLabel>
            <IonLabel><h3 className="ideas-label" id="idea-2">Make facilities more accessible by...?</h3></IonLabel>
            <IonLabel><h3 className="ideas-label" id="idea-3">Donate to charity?</h3></IonLabel>
          </div>

          <div className = "ellipses-left-container">
            <div className="ellipses-left">
              <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-1" />
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-1" />
            </div>
            <div className="ellipses-left">
              <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-2" />
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-2" />
            </div>
            <div className="ellipses-left">
              <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-3"  />
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-3"/>
            </div>
          </div>

          <div className="button-container">
          <IonImg src={String(ideaButton)} alt="submit-idea" id="submit-idea-button" onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}></IonImg>
              <div className="submit-idea-text-container">
                <h2 className="submit-idea-text">SUBMIT YOUR IDEA HERE</h2>
              </div>
          </div>

          <div className = "ellipses-right-container">
            <div className="ellipses-right">
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-4" />
              <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-4" />
            </div>
            <div className="ellipses-right">
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-5" />
              <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-5" />
            </div>
            <div className="ellipses-right">
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-6"/>
              <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-6" />
            </div>
          </div>

          
          <div className = "ideas-right">
            <IonLabel><h3 className="ideas-label" id="idea-4">Organise local tree planting event?</h3></IonLabel>
            <IonLabel><h3 className="ideas-label" id="idea-5">Research new technology ?</h3></IonLabel>
            <IonLabel><h3 className="ideas-label" id="idea-6">Replace unsustainable service / product with more sustainable version?</h3></IonLabel>
          </div>
      </div>

      <div className = "ellipses-bottom-container">
            <div className="ellipses-bottom-small">
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-7" />
              <IonIcon icon={ellipse} className="ellipse-small" id= "small-ellipse-8"/>
            </div>
            <div className="ellipses-bottom-large">
            <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-7" />
            <IonIcon icon={ellipse} className="ellipse-large" id= "large-ellipse-8" />
            </div>
          </div>

      <div className="ideas-bottom">
        <IonLabel><h3 className="ideas-label" id="idea-7">Improve diversity by...?</h3></IonLabel>
        <IonLabel><h3 className="ideas-label" id="idea-8">Reduce carbon footprint by...?</h3></IonLabel>
      </div>
    </div>
  )
}

export default VisitorLanding;
