import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonNote,
  IonTextarea,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSearchbar,
  IonNav,
  IonRouterLink,
  IonItem,
  IonRouterOutlet
} from "@ionic/react";
import { at, closeOutline } from "ionicons/icons";
import UserIonInput from "./UserIonInput";
import UserIonTextarea from "./UserIonTextarea";
import UserIonSelect from "./UserIonSelect";
import Controller from "@s360/common-models/controller";
import Goal from "@s360/common-models/goal";
import Target from "@s360/common-models/target";
import Indicator from "@s360/common-models/indicator";
import {
  statusOptions,
  trackingProgress,
  allMyGoals,
  statusDescriptions,
} from "@s360/common-models/constants";
import { Initiative } from "@s360/common-models/initiative";
import { BusinessUnit } from "@s360/common-models/businessUnit";
import { User } from "@s360/common-models/user";


import { APIService } from "../services/APIService";
import './UserIonSelect.css';
import './InitiativeModal.css';
import { useLocation } from "react-router";
import { Plugins } from '@capacitor/core';
import { Idea } from "@s360/common-models/idea";
import { IdeaService } from "../services/IdeaService";
import { UserService } from "./../services/UserService";
import InitiativeSDG from "./InitiativeSDG";
import InitiativeReview from "./InitiativeReview";
import { dynamicSort } from "../helper/dynamicSort";
import InitiativeDetails from "./InitiativeDetails/InitiativeDetails";
import InitiativeAttachments from "./InitiativeAttachments/InitiativeAttachments";
import { Attachment } from "@s360/common-models/attachment";



import { InitiativeCreatedFrom } from "@s360/common-models/initiative-created-from";

const { Storage } = Plugins;

const errorMessages = {
  title: "  Title text is required",
  status: "  Status is required",
  progress: "  is required",
  desc: "  Description is required",
  general: "  This information is required",
  category: "Category is required"
};

const InitiativeModal: React.FC<{
  closeAction: () => void;
  showToast: () => void;
  isNewInitiative: boolean;
  userName?: any;
  editTargetInitiative?: Initiative;
  bcorpIdea?: boolean;
  addByNewIdea?: Idea;
  onSubmit?: () => void;
}> = (props) => {
  let isNewInitiative:boolean = props.isNewInitiative;
  const [editTargetInitiative, setEditTargetInitiative] = useState<Initiative | undefined>(props.editTargetInitiative? props.editTargetInitiative : undefined);
  
  useEffect((): void =>{
    setEditTargetInitiative(props.editTargetInitiative)
  },[props.editTargetInitiative])
  
  const apiService: APIService = new APIService();

  useEffect(() => {
    setEditTargetInitiative(props.editTargetInitiative)
  }, [props.editTargetInitiative])

  const userService: UserService = new UserService()

  const [showGlobalGoals, setShowGlobalGoals] = useState<boolean>(false);

  const [goalFilter, setGoalFilter] = useState<string>('');
  const [showClearAlert, setClearAlert] = useState(false);
  const [showCloseAlert, setCloseAlert] = useState(false);
  const [showReminderAlert, setReminderAlert] = useState(false);
  const [showCancelledAlert, setShowCancelledAlert] = useState(false);
  const [reminderContent, setReminderContent] = useState<string>("");

  const [titleErrorMessage, setTitleErrorMessage] = useState<string>("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState<string>("");
  const [descErrorMessage, setDescErrorMessage] = useState<string>("");
  const [statusErrorMessage, setStatusErrorMessage] = useState<string>("");
  const [progressErrorMessage, setProgressErrorMessage] = useState<string>("");
  const [indicErrorMessage, setIndicErrorMessage] = useState<string>("");
  const [objErrorMessage, setObjErrorMessage] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [statusDisabled, setStatusDisabled] = useState<boolean>(
    props.editTargetInitiative &&
      props.editTargetInitiative.trackingStatus === "Cancelled" ? true : false);
  const [progressDisabled, setProgressDisabled] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [targets, setTargets] = useState<Target[]>([]);
  const [indics, setIndics] = useState<Indicator[]>([]);
  const [deletee, setDeletee] = useState<string>("");

  const [selectingTargetsIndic, setSelectingTargetsIndic] = useState<boolean>(false);
  const [selectedGoal, setSelectedGoal] = useState<Goal>();


  const goals: Goal[] = allMyGoals;
  const [filteredGoals, setFilteredGoals] = useState<Goal[]>(goals);
  const [filteredTargets, setFilteredTargets] = useState<Target[]>(targets);
  const [filteredIndicators, setFilteredIndicators] = useState<Indicator[]>(indics);
  const [titleText, setTitleText] = useState<string>(
    props.editTargetInitiative ? props.editTargetInitiative.title : (props.addByNewIdea ? props.addByNewIdea.title : "")
  );
  const [initiativeId, setInitiativeId] = useState<string>(
    props.editTargetInitiative ? props.editTargetInitiative.initiativeId || "" : ""
  );
  const [descriptionText, setDesriptionText] = useState<string>(
    props.editTargetInitiative ? props.editTargetInitiative.description : (props.addByNewIdea ? props.addByNewIdea.description : "")
  );
  const [statusText, setStatusText] = useState<string>(
    props.editTargetInitiative ? props.editTargetInitiative.trackingStatus : "New"
  );

  const [progressText, setProgressText] = useState<string>(
    props.editTargetInitiative ? props.editTargetInitiative!.progress! : ""
  );

  const [selectedGoals, setSelectedGoals] = useState<Goal[]>(
    props.editTargetInitiative ? findGoals(props.editTargetInitiative.goals, goals) : []
  );
  const [selectedTargets, setSelectedTargets] = useState<Target[]>(
    props.editTargetInitiative ? findTargets(props.editTargetInitiative.targets, goals) : []
  );
  const [selectedIndics, setSelectedIndics] = useState<Indicator[]>(
    props.editTargetInitiative
      ? findIndicators(props.editTargetInitiative.indicators, goals)
      : []
  );
  const [noteText, setNoteText] = useState<string>(
    props.editTargetInitiative && props.editTargetInitiative.note
      ? props.editTargetInitiative.note
      : ""
  );



  const [targetDate, setTargetDate] = useState<number>(
    props.editTargetInitiative && props.editTargetInitiative.targetDate ?
      props.editTargetInitiative.targetDate : 0
  )

  const [selectedCategories, setSelectedCategories] = useState<string[] | undefined>(
    props.editTargetInitiative && props.editTargetInitiative.uniqueCategories ?
      props.editTargetInitiative.uniqueCategories : undefined
  );

  const [collaboration, setCollaboration] = useState<boolean>(
    props.editTargetInitiative && props.editTargetInitiative.uniqueCategories ?
      props.editTargetInitiative.uniqueCategories.includes('Collaboration') : false
  );
  const [environment, setEnvironment] = useState<boolean>(
    props.editTargetInitiative && props.editTargetInitiative.uniqueCategories ?
      props.editTargetInitiative.uniqueCategories.includes('Environment') : false
  );
  const [governance, setGovernance] = useState<boolean>(
    props.editTargetInitiative && props.editTargetInitiative.uniqueCategories ?
      props.editTargetInitiative.uniqueCategories.includes('Economy') : false
  );
  const [society, setSociety] = useState<boolean>(
    props.editTargetInitiative && props.editTargetInitiative.uniqueCategories ?
      props.editTargetInitiative.uniqueCategories.includes('Society') : false
  );

  const [attachments, setAttachments] = useState<Attachment[]>(
    props.editTargetInitiative?.attachments ?
    props.editTargetInitiative.attachments : []
  );

  const [createdFrom, setCreatedFrom] = useState<InitiativeCreatedFrom>(
    props.editTargetInitiative && props.editTargetInitiative.createdFrom
      ? props.editTargetInitiative.createdFrom
      : InitiativeCreatedFrom.UNKNOWN
  );

  useEffect(() => {
    let categories: string[] = [];
    if (collaboration) categories.push("Collaboration");
    if (environment) categories.push("Environment");
    if (governance) categories.push("Economy"); // ("Governance");
    if (society) categories.push("Society");
    setSelectedCategories(categories);
  }, [collaboration, environment, governance, society]);

  const [objText, setObjText] = useState<string>(
    props.editTargetInitiative && props.editTargetInitiative.objective
      ? props.editTargetInitiative.objective
      : ""
  );

  const [businessUnitID, setBusinessUnitID] = useState<string>(
    props.editTargetInitiative && props.editTargetInitiative.businessUnitID ?
      props.editTargetInitiative.businessUnitID : ""
  );

  const [businessUnitName, setBusinessUnitName] = useState<string>(
    props.editTargetInitiative && props.editTargetInitiative.businessUnitName ?
      props.editTargetInitiative.businessUnitName : "Assign to a Business Unit"
  );

  const progressPercentages = ["0%", "25%", "50%", "75%", "100%"]

  const validProgresses = (progress: string): string[] => {
    let result: Array<string> = [];

    progressPercentages.forEach((progressPercentage: string) => {
      result.push(progressPercentage);
    })
    return result;
  };

  const [validProgress, setValidProgress] = useState<string[]>(
    props.editTargetInitiative
      ? validProgresses(props.editTargetInitiative.progress!)
      : trackingProgress
  );
  const location = useLocation();

  const changePage = (page: number): void => {
    setCurrentPage(page);
  }

  const filterIndicators = (filter: string): Indicator[] => {
    if (!filter || filter.length <= 0) return indics;
    let loweredText: string = filter.toLowerCase();
    let indicatorList: Indicator[] = indics.filter(function (indicator) {
      return (indicator && ((indicator.name && indicator.name.toLowerCase().indexOf(loweredText) >= 0)
        || (indicator.desc && indicator.desc.toLowerCase().indexOf(loweredText) >= 0)
      ))
    })
    return indicatorList;
  };

  const filterTargets = (filter: string): Target[] => {
    if (!filter || filter.length <= 0) return targets;
    let loweredText: string = filter.toLowerCase();
    let targetList: Target[] = targets.filter(function (target) {
      return (target && (
        (target.name && target.name.toLowerCase().indexOf(loweredText) >= 0)
        || (target.desc && target.desc.toLowerCase().indexOf(loweredText) >= 0)
        || (filterIndicators(filter).length > 0)
      ))
    })
    console.log("filtering targets");
    console.log(targetList.length);
    return targetList;
  };

  const filterGoals = (filter: string): Goal[] => {
    if (!filter || filter.length <= 0) return goals;
    let loweredText: string = filter.toLowerCase();
    let goalList: Goal[] = goals.filter(function (goal) {
      return (goal && (
        (goal.name && goal.name.toLowerCase().indexOf(loweredText) >= 0)
        || (goal.desc && goal.desc.toLowerCase().indexOf(loweredText) >= 0)
        || (filterTargets(loweredText).length > 0)
      ))
    })
    return goalList;
  };

  React.useEffect((): void => {
    setFilteredGoals(filterGoals(goalFilter));
    setFilteredTargets(filterTargets(goalFilter));
    setFilteredIndicators(filterIndicators(goalFilter));
  }, [goalFilter, targets, indics])

  React.useEffect((): void => {
    if (props.editTargetInitiative) {
      setTitleText(props.editTargetInitiative!.title);
      setInitiativeId(props.editTargetInitiative!.id || "");
      setDesriptionText(props.editTargetInitiative!.description);
      setStatusText(props.editTargetInitiative!.trackingStatus);
      setProgressText(props.editTargetInitiative!.progress || "");
      setSelectedGoals(findGoals(props.editTargetInitiative!.goals, goals));
      setSelectedTargets(findTargets(props.editTargetInitiative!.targets, goals));
      setSelectedIndics(findIndicators(props.editTargetInitiative!.targets, goals));
      setNoteText(props.editTargetInitiative!.note || "");
      setTargetDate(props.editTargetInitiative.targetDate || 0);
      setSelectedCategories(props.editTargetInitiative!.uniqueCategories || undefined);
      setCollaboration(props.editTargetInitiative.uniqueCategories?.includes('Collaboration') || false);
      setEnvironment(props.editTargetInitiative.uniqueCategories?.includes('Environment') || false);
      setGovernance(props.editTargetInitiative.uniqueCategories?.includes('Economy') || false);
      setSociety(props.editTargetInitiative.uniqueCategories?.includes('Society') || false);
      setObjText(props.editTargetInitiative!.objective);
      setBusinessUnitID(props.editTargetInitiative!.businessUnitID || "");
      setBusinessUnitName(props.editTargetInitiative!.businessUnitName || "Assign to a Business Unit");
      setOwnerFullName(props.editTargetInitiative!.ownerFullName || "Assign to a User");
      setAttachments(props.editTargetInitiative.attachments || [])
      setCreatedFrom(props.editTargetInitiative!.createdFrom || InitiativeCreatedFrom.UNKNOWN);
    }
  }, [props.editTargetInitiative, props.addByNewIdea, props.isNewInitiative])

  const [ownerEmailAddress, setOwnerEmailAddress] = useState<string>(
    editTargetInitiative && editTargetInitiative.ownerEmailAddress ?
      editTargetInitiative.ownerEmailAddress : ""
  );

  const [ownerFullName, setOwnerFullName] = useState<string>(
    editTargetInitiative && editTargetInitiative.ownerFullName ?
      editTargetInitiative.ownerFullName : "Assign to a User"
  );

  React.useEffect(() => {
    if (location.pathname.endsWith("addEx")) {
      setEditTargetInitiative(undefined);
      isNewInitiative = false;
    } else if (location.pathname.endsWith("addNew")) {
      setEditTargetInitiative(undefined);
      if (isNewInitiative && statusText !== statusOptions[0]) {
        console.log(`New Initiative`)
        setStatusText(statusOptions[0]);
        setStatusDisabled(true);
      }
    } else if (props.editTargetInitiative) {
      isNewInitiative = false;
    }
  }, [props])

  function findGoals(checkedGoals: string[], goals: Goal[]): Goal[] {

    let result: Goal[] = [];
    checkedGoals.forEach((item) => {
      goals.forEach((goal) => {
        if (goal.name === item) {
          result.push(goal);
        }
      });
    });
    return result;
  }

  function findTargets(checkedTargets: string[], goals: Goal[]): Target[] {
    let result: Target[] = [];
    checkedTargets.forEach((item) => {
      goals.forEach((goal) => {
        if (goal.name === item.substr(0, item.indexOf("."))) {
          goal.targets.forEach((target) => {
            if (target.name === item) {
              result.push(target);
            }
          });
        }
      });
    });
    return result;
  }

  function findIndicators(
    checkedIndicators: string[],
    goals: Goal[]
  ): Indicator[] {
    let result: Indicator[] = [];
    checkedIndicators.forEach((item) => {
      goals.forEach((goal) => {
        if (goal.name === item.substr(0, item.indexOf("."))) {
          goal.targets.forEach((target) => {
            if (target.name === item.substr(0, item.lastIndexOf("."))) {
              target.indicators.forEach((indicator) => {
                if (indicator.name === item) {
                  result.push(indicator);
                }
              });
            }
          });
        }
      });
    });
    return result;
  }

  React.useEffect((): void => {
    if (submitDisabled && currentPage === 3) {
     setCurrentPage(1)
    };
  }, [submitDisabled, currentPage])

  React.useEffect(():void  => {
    if (submitDisabled) {
      checkInputs();
    }
  }, [
    descriptionText,
    titleText,
    statusText,
    progressText,
    selectedGoals,
    selectedTargets,
    selectedIndics,
    noteText,
    objText,
    businessUnitID,
    businessUnitName,
    targetDate,
    ownerEmailAddress,
    ownerFullName,
    selectedCategories
  ]);

  React.useEffect((): void => {
    let targets: Target[] = [];
    if (selectedGoals.length !== 0) {
      selectedGoals.forEach((goal) => {
        goal.targets.forEach((target) => {
          targets.push(target);
        });
      });
    }
    console.log(`setting targets length ${targets.length}`)
    setTargets(targets);
  }, [selectedGoals]);

  React.useEffect((): void => {
    let indics: Indicator[] = [];
    if (selectedTargets.length !== 0) {
      selectedTargets.forEach((target) => {
        target.indicators.forEach((indic) => {
          indics.push(indic);
        });
      });
    }
    setIndics(indics);
  }, [selectedTargets, targets]);

  const addAttachment = (attachment: Attachment) => {
    setAttachments((attachments: Attachment[]) => (
      attachments.concat(attachment)
    ));
  }

  const removeAttachment = (targetAttachment: Attachment) => {
      const updatedAttachments: Attachment[] = attachments.filter((attachment: Attachment) => (
        attachment.id !== targetAttachment.id
      ));

      setAttachments(updatedAttachments);
  }

  React.useEffect((): void => {
    removeArtifacts();
  }, [deletee]);

  const removeArtifacts = (): void => {
    if (deletee.length < 3) {
      let tempArray: Target[] = [];
      for (let index = 0; index < selectedTargets.length; index++) {
        if (selectedTargets[index].goalName !== deletee) {
          tempArray.push(selectedTargets[index]);
        }
      }
      setSelectedTargets(tempArray);

      let tempArray2: Indicator[] = [];
      for (let index = 0; index < selectedIndics.length; index++) {
        if (selectedIndics[index].goalName !== deletee) {
          tempArray2.push(selectedIndics[index]);
        }
      }
      setSelectedIndics(tempArray2);
    }
    if (deletee.length === 3) {
      let tempArray: Indicator[] = [];
      for (let index = 0; index < selectedIndics.length; index++) {
        if (selectedIndics[index].targetName !== deletee) {
          tempArray.push(selectedIndics[index]);
        }
      }
      setSelectedIndics(tempArray);
    }
  };

  async function getUserName(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        const authDetails = JSON.parse(res.value!);
        let userName = authDetails.firstName + ' ' + authDetails.lastName;
        resolve(userName)
      }).catch(error => {
        reject(error);
      })
    })
  }

  const doesExistAllInputs = (): string | number | undefined => {
    return (
      titleText.trim() &&
      descriptionText.trim() &&
      statusText &&
      selectedCategories?.length &&
      statusText)
  }

  //Check if inputs validation are correct, then send POST to API, then show reminder alert
  const submitInputs = async (): Promise<void> =>  {

    await getUserName().then(value => {
      const userName: string = value;
      if (doesExistAllInputs()) {
        let selectedGoalsName: string[] = [];
        selectedGoals.forEach((goal) => {
          selectedGoalsName.push(goal.name);
        });

        let selectedTargetsName: string[] = [];
        selectedTargets.forEach((target) => {
          selectedTargetsName.push(target.name);
        });

        let selectedIndicsName: string[] = [];
        selectedIndics.forEach((indicator) => {
          selectedIndicsName.push(indicator.name);
        });

        console.log(attachments)

        let initiative: Initiative = {
          id: editTargetInitiative ? editTargetInitiative.id : null,
          initiativeId: editTargetInitiative
            ? editTargetInitiative.initiativeId
            : null,
          companyID: '',
          title: titleText,
          description: descriptionText,
          trackingStatus: statusText,
          progress: statusText === "In Progress" ? progressText : null,
          goals: selectedGoalsName,
          targets: selectedTargetsName,
          indicators: selectedIndicsName,
          author: props.addByNewIdea?.author ? props.addByNewIdea.author : userName,
          objective: objText,
          note: noteText,
          businessUnitID: businessUnitID !== "" || businessUnitID !== undefined ? businessUnitID : undefined,
          businessUnitName: businessUnitName !== "Assign to a Business Unit" ? businessUnitName : undefined,
          targetDate: targetDate,
          ownerEmailAddress: ownerEmailAddress,
          ownerFullName: ownerFullName !== "Assign to a User" ? ownerFullName : undefined,
          uniqueCategories: selectedCategories,
          attachments: attachments,
          createdFrom: createdFrom
        };
        console.log(initiative.attachments)


        if (selectedIndics.length === 0) {
          setReminderContent("Indicators");
        }

        apiService.submitInitiative(initiative)
          .then((result) => {
            if (result) {
              if (props.addByNewIdea) {
                let ideaService = new IdeaService();
                ideaService.acceptIdea(props.addByNewIdea).then((result) => {
                  if (result.status === 200) {
                    props.showToast();

                    props.closeAction();
                  } else {
                    props.closeAction();
                  }
                })
                .catch((error) => {
                  console.error(error);
                });

            } else {
              if(props.onSubmit !== undefined) {
                props.onSubmit();
              };
              props.closeAction();
              props.showToast();
            }
          } else {
            props.closeAction();
          }
        });
        props.closeAction();
      }
    });

  };

  const closeModal = (): void => {
    props.closeAction();
  }

  useEffect((): void =>{
    console.log(`Statue text changed: ${statusText}`)
  }, [statusText])

  //Set error messages and disable/enable submit button
  const checkInputs = (): void => {
    titleText.trim()
      ? setTitleErrorMessage("")
      : setTitleErrorMessage(errorMessages.title);
    descriptionText.trim()
      ? setDescErrorMessage("")
      : setDescErrorMessage(errorMessages.desc);
    statusText
      ? setStatusErrorMessage("")
      : setStatusErrorMessage(errorMessages.status);
    progressText
      ? setProgressErrorMessage("")
      : setProgressErrorMessage(errorMessages.progress);
    selectedCategories?.length
      ? setCategoryErrorMessage("")
      : setCategoryErrorMessage(errorMessages.category);
    if (statusText === "Implemented") {
    } else {
      setIndicErrorMessage("");
    }
    if (statusText !== "New" && statusText) {
      objText
        ? setObjErrorMessage("")
        : setObjErrorMessage(errorMessages.general);
    } else {
      setObjErrorMessage("");
    }
    if (
      titleText.trim() &&
      descriptionText.trim() &&
      statusText &&
      selectedCategories?.length &&
      statusText
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const retrieveInputs = (): void => {
    checkInputs();
    if (statusText === "Cancelled") {
      if (doesExistAllInputs()) {
        setShowCancelledAlert(true);
      }
    } else {
      console.log(`Submitting inputs`)
      submitInputs();
    }
  };

  const clearInputs = (): void => {
    setDesriptionText("");
    setStatusText("");
    setSelectedCategories([]);
    setTitleText("");
    setObjText("");
    setNoteText("");
    setBusinessUnitID("");
    setTargetDate(0);
    setBusinessUnitName("Assign to a Business Unit");
    setOwnerFullName("Assign to a User");
  };

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            {" "}
            {
            isNewInitiative 
              ? "Enter New Idea"
              : editTargetInitiative
                ? "Edit Initiative"
                : "Create a New Initiative"}{" "}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setClearAlert(true)}>Clear</IonButton>
            <IonButton onClick={() => setCloseAlert(true)}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding scrollable">
        <IonGrid className="max-width">
          <IonRow>
            <nav>
              <ul className="navigation">
                <li className={currentPage === 1 ? (!submitDisabled ? "nav-item active" : "nav-item error") : (!submitDisabled ? "nav-item" : "nav-item error-gray")}
                  onClick={() => setCurrentPage(1)}>
                  <span className={currentPage === 1 ? (!submitDisabled ? "nav-circle active" : "nav-circle error") : (!submitDisabled ? "nav-circle" : "nav-circle error-gray")}></span>
                  <a className="nav-link">
                    <p className="nav-page-number">1</p>
                    <p className="nav-page">Details</p>
                  </a>
                </li>
                <li className={currentPage === 2 ? "nav-item active" : "nav-item"}
                onClick={() => {
                  checkInputs();
                  setCurrentPage(2);
                }}>
                  <span className={currentPage === 2 ? "nav-circle active" : "nav-circle"}></span>
                  <a className="nav-link">
                    <p className="nav-page-number">2</p>
                    <p className="nav-page">Attachments</p>
                  </a>
                </li>
                <li className={currentPage === 3 ? "nav-item active" : "nav-item"}
                  onClick={() => setCurrentPage(3)}>
                  <span className={currentPage === 3 ? "nav-circle active" : "nav-circle"}></span>
                  <a className="nav-link">
                    <p className="nav-page-number">3</p>
                    <p className="nav-page">SDGs</p>
                  </a>
                </li>
                <li className={currentPage === 4 ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    checkInputs();
                    setCurrentPage(4);
                  }}>
                  <span className={currentPage === 4 ? "nav-circle active" : "nav-circle"}></span>
                  <a className="nav-link">
                    <p className="nav-page-number">4</p>
                    <p className="nav-page">Review</p>
                  </a>
                </li>
              </ul>
            </nav>
          </IonRow>

            {currentPage === 1 && (
              <InitiativeDetails
                categoryErrorMessage={categoryErrorMessage}
                checkInputs={checkInputs}
                descErrorMessage={descErrorMessage}
                descriptionText={descriptionText}
                objErrorMessage={objErrorMessage}
                objText={objText}
                ownerEmailAddress={ownerEmailAddress}
                ownerFullName={ownerFullName}
                progressDisabled={progressDisabled}
                progressErrorMessage={progressErrorMessage}
                retrieveInputs={retrieveInputs}
                setDesriptionText={setDesriptionText}
                setObjText={setObjText}
                setOwnerEmailAddress={setOwnerEmailAddress}
                setOwnerFullName={setOwnerEmailAddress}
                setTargetDate={setTargetDate}
                statusDescriptions={statusDescriptions}
                statusDisabled={statusDisabled}
                statusErrorMessage={statusErrorMessage}
                targetDate={targetDate}
                titleErrorMessage={titleErrorMessage}
                validProgress={validProgress}
                noteText={noteText}
                setNoteText={setNoteText}
                initiative={editTargetInitiative}
                businessUnitID={businessUnitID}
                businessUnitName={businessUnitName}
                collaboration={collaboration}
                environment={environment}
                governance={governance}
                progressText={progressText}
                titleText={titleText}
                society={society}
                statusText={statusText}
                setCurrentPage={setCurrentPage}
                setBusinessUnitID={setBusinessUnitID}
                setBusinessUnitName={setBusinessUnitName}
                setCollaboration={setCollaboration}
                setEnvironment={setEnvironment}
                setGovernance={setGovernance}
                setProgressText={setProgressText}
                setSociety={setSociety}
                setStatusText={setStatusText}
                setTitleText={setTitleText}
              />
            )}
            {currentPage === 2 && (
              <InitiativeAttachments
                initiativeID={initiativeId}
                initiative={editTargetInitiative}
                setCurrentPage={setCurrentPage}
                attachments={attachments}
                retrieveInputs={retrieveInputs}
                addAttachment={addAttachment}
                removeAttachment={removeAttachment}
              />
            )}

            {currentPage === 3 && (
              <>
                <IonSearchbar
                  color="black"
                  placeholder="Search SDGs"
                  value={goalFilter}
                  onIonChange={e => setGoalFilter(e.detail.value!)}
                />


                <InitiativeSDG
                  goals={filteredGoals}
                  targets={filteredTargets}
                  indics={filteredIndicators}
                  setCurrentPage={changePage}
                  checkInputs={checkInputs}
                  deletee={deletee}
                  setDeletee={setDeletee}
                  addGoal={Controller.addGoal}
                  removeGoal={Controller.removeGoal}
                  addTarget={Controller.addTarget}
                  removeTarget={Controller.removeTarget}
                  addIndic={Controller.addIndic}
                  removeIndic={Controller.removeIndic}
                  selectedGoal={selectedGoal}
                  setSelectedGoal={setSelectedGoal}
                  selectingTargetsIndic={selectingTargetsIndic}
                  setSelectingTargetsIndic={setSelectingTargetsIndic}
                  selectedGoals={selectedGoals}
                  setSelectedGoals={setSelectedGoals}
                  selectedTargets={selectedTargets}
                  setSelectedTargets={setSelectedTargets}
                  selectedIndics={selectedIndics}
                  setSelectedIndics={setSelectedIndics}
                />
              </>
            )}
            {currentPage === 4 &&
              <InitiativeReview
                title={titleText}
                objective={objText}
                description={descriptionText}
                selectedCategories={selectedCategories}
                status={statusText}
                progress={progressText}
                businessUnitName={businessUnitName}
                businessUnitID={businessUnitID}
                ownerFullName={ownerFullName}
                ownerEmailAddress={ownerEmailAddress}
                targetDate={targetDate}
                note={noteText}
                create={retrieveInputs}
                createDisabled={submitDisabled}
                edit={editTargetInitiative ? true : false}
                deletee={deletee}
                setDeletee={setDeletee}
                removeGoal={Controller.removeGoal}
                removeTarget={Controller.removeTarget}
                removeIndic={Controller.removeIndic}
                selectedGoals={selectedGoals}
                setSelectedGoals={setSelectedGoals}
                selectedTargets={selectedTargets}
                setSelectedTargets={setSelectedTargets}
                selectedIndics={selectedIndics}
                setSelectedIndics={setSelectedIndics}
              />
            }



          <br />
        </IonGrid>
        <IonAlert
          isOpen={showClearAlert}
          backdropDismiss={false}
          onDidDismiss={() => setClearAlert(false)}
          header={"Are you sure you want to clear all entered inputs"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                clearInputs();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonAlert
          isOpen={showCloseAlert}
          backdropDismiss={false}
          onDidDismiss={() => setCloseAlert(false)}
          header={"Are you sure you want to exit back to console page"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                closeModal();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonAlert
          isOpen={showReminderAlert}
          backdropDismiss={false}
          onDidDismiss={() => setReminderAlert(false)}
          header={
            "You have not selected any " +
            reminderContent +
            " we will remind you to do this at a later stage"
          }
          buttons={[
            {
              text: "Okay",
              handler: () => {
                setTitleText("");
                setDesriptionText("");
                props.closeAction();
                props.showToast();
              },
            },
          ]}
        />
        <IonAlert
          isOpen={showCancelledAlert}
          backdropDismiss={false}
          onDidDismiss={() => setShowCancelledAlert(false)}
          header={"Are you sure you want to cancel this initiative? The status of the cancelled item will not be able to be changed."}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                submitInputs();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </>
)}



export default InitiativeModal
