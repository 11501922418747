import React, { useState } from "react";
import { IonPage, IonHeader, IonContent, IonToolbar, IonTitle, IonButton, IonAlert, IonItem, IonText, IonSpinner, IonToast, IonInput, IonLabel, IonRouterLink, IonLoading } from "@ionic/react";
import { RouteComponentProps, useLocation, useParams } from 'react-router-dom';
import { LoginService } from "../services/LoginService";
import { LoginResponse } from '@s360/common-models/apiResponses'
import { setIsLoggedIn, setUserRole, setEmailAddress } from "../data/user/user.actions";
import { connect } from '../data/connect';
import '../css/app.scss';
import { useForm } from 'react-hook-form';
import { Roles } from "@s360/common-models/roles";
import { Plugins } from '@capacitor/core';
import { UserState } from "../data/user/user.state";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { activeSession } from "../helper/sessionManagement";

const { Storage } = Plugins;

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUserRole: typeof setUserRole;
  setEmailAddress: typeof setEmailAddress;
}

const appSessionCheck = async () : Promise<Roles> => {
  return new Promise<Roles>(async (resolve, reject)=>{
    let loginCheckComplete: boolean = false;
    let userType: Roles = Roles.User;
    try {
      let token = Cookies.get('token');
      if(!token){
        console.log("No token")
        // await Storage.clear();
      }
      else {
        await Storage.get({key: 'sessionParams'}).then((sessionData: any) =>{
          const sessionObject = JSON.parse(sessionData)
          const sessionDetails: AuthenticationResponse = {
            token: sessionObject.token,
            firstName: sessionObject.firstName,
            lastName: sessionObject.lastName,
            emailAddress: sessionObject.emailAddress,
            companyID: sessionObject.companyID,
            userRole: sessionObject.userRole
          }
          console.log(`User role: ${sessionDetails.userRole}`)
          switch (sessionDetails.userRole) {
            case 'visitor':
              userType = Roles.Visitor
              break;
            case 'admin':
              userType = Roles.Admin
              break;
            case 'user':
              userType = Roles.User
              break;
            case 's360':
              userType = Roles.S360
              break;
          }

        })
      }
    }
    catch (error) {
      console.log(`[Error][index][try]: ${error}`)
     // await Storage.clear();
    }
    loginCheckComplete = true;
    resolve (userType)
  })
}

interface LoginProps extends OwnProps, UserState, DispatchProps { }

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, isLoggedin, setUserRole, history, setEmailAddress }) => {

  let [inputEmailAddress, setInputEmailAddress] = useState('');
  let [inputPassword, setInputPassword] = useState('');
  const [emailAddressError, setEmailAddressError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [canLogin, setCanLogin] = useState<boolean>(false);
  const [userNameBlurred, setUserNameBlurred] = useState<boolean>(false);
  const [paswordBlurred, setPasswordBlurred] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const emailTestRegEx: RegExp = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$", "g");
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const { control, handleSubmit } = useForm();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(['token']);
  const [token, setToken] = useState('');
  const location = useLocation();
  const handle: any = useParams();
  const [source, setSource] = useState<string>("");
  const [showAfterResetToast, setShowAfterResetToast] = useState<boolean>(false);
  const [userType, setUserType] = useState<Roles>();

  const [alertMessage, setAlertMessage] = useState("");

  React.useEffect(() => {
    try {
      const reset = Cookies.get('passwordReset');
      if (reset === 'reset') {
        setShowAfterResetToast(true);
      }
    }
    finally {
      getSession();
      setSource(handle['source']);
    }
  }, []);

  React.useEffect(()=>{
    console.log(`Checking if its a visitor`)
    appSessionCheck().then(setUserType).catch(error=>{
      console.error(error);
    })
    if(userType === Roles.Visitor){
      console.log(`This is a visitor`)
      alert('visitor')
      const ideaPageUrl = process.env.REACT_APP_VISITOR_URL;
      window.open(ideaPageUrl,"_self")
    }
  },[setUserType])

  React.useEffect(() => {
    checkInputs();
  })

  const checkInputs = () => {
    setEmailAddressError(inputEmailAddress.trim() === "");
    setPasswordError(inputPassword.trim() === "");
    setCanLogin((emailTestRegEx.test(inputEmailAddress.trim()) && inputPassword.trim() !== ""));
  };

  async function handleCookie(token: string, companyID: string, systemAdmin: boolean): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      Cookies.set('token', token, { domain: process.env.REACT_APP_DOMAIN });
      Cookies.set('cid', companyID, { domain: process.env.REACT_APP_DOMAIN });
      Cookies.set('s360_sys_admin', systemAdmin ? '1' : '0', { domain: process.env.REACT_APP_DOMAIN });
      resolve(true);
    })
  }

  async function getSession(): Promise<any> {
    return new Promise<string>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        const authDetails = JSON.parse(res.value!);
        
        if (authDetails) {
          if ('isLoggedIn' in authDetails) {
            setToken(authDetails.token);
            console.log(`Inside getSession authDetails`);
            setIsLoggedIn(true);
            setUserLoggedIn(true);
          }
          if( authDetails.userRole === Roles.Visitor){
            window.open(process.env.REACT_APP_VISITOR_URL,'_self')
          }
        }
        else {
          console.log(`<<<<<<<<<<<<<<< This is the user role: ${true}`);
          setIsLoggedIn(false)
          setUserLoggedIn(false);
        }

      }).catch(error => {
        reject(error);
      })
    })
  }

  const getRole = (strRole?: string) => {
    let role: Roles = Roles.User;
    if (strRole === 'admin') {
      role = Roles.Admin;
    } else if (strRole === 'visitor') {
      role = Roles.Visitor;
    }
    return role;
  }

  async function loginUser() {
    setShowLoading(true);
    setFormSubmitted(true);
    try {
      const loginService = new LoginService();
      console.log(`[loginUser][location]: Before entering the service`);
      loginService.Login(inputEmailAddress, inputPassword).then(async (value: LoginResponse) => {
        console.log('Returned data:', JSON.stringify(value));
        if (value.status === 200 && value.user?.userRole !== 'visitor') {


          setShowLoading(false);
          if (source === 'initiativePortal') {
            setIsLoggedIn(true);
            setEmailAddress(inputEmailAddress);
            setUserRole(getRole(value.user!.userRole));
            await handleCookie(value.token || "", value.user!.companyID || "", (value.user && value.user.systemAdmin) ? true : false);
            const consoleUrl = process.env.REACT_APP_CONSOLE_URL!;
            window.open(consoleUrl, "_self");
          }
          else if (source === 'cc') {
            console.log(`[token value]: ${value.token}`)
            handleCookie(value.token || "", value.user!.companyID || "", (value.user && value.user.systemAdmin) ? true : false).then((value: boolean) => {
              if (value) {
                window.open(process.env.REACT_APP_CC_URL!, "_self");
              }
            });


          }
          console.log(`[role]: ${value.user?.userRole}`);
        }
        else if (value.status === 200 && value.user?.userRole === 'visitor') {
          setIsLoggedIn(true);
          setEmailAddress(inputEmailAddress);
          setUserRole(getRole(value.user!.userRole));
          await handleCookie(value.token || "", value.user!.companyID || "", (value.user && value.user.systemAdmin) ? true : false);
          window.open('/idea-new', "_self")
        }
        else if (value.status === 401 || 500) {
          setShowLoading(false);
          setInputPassword('');
          console.log("Authentication failed");
          setAlertMessage("Incorrect credentials");
          try{
          setShowAlert(true);
            activeSession(false)
            console.log(`interceptor error`)
            console.error(value)
          }
          catch(error){
            console.error(error)
          }
        }
      });
      console.log(`[Location][loginUser()]: Outside service call`)
    } catch (e) {
      setShowLoading(false);
      console.log(`Request failed in login.tsx: ${e}`);
      setShowAlert(true);
    }
  }

  document.onkeydown = (e) => {
    if (e.keyCode == 13 && canLogin) {
      loginUser();
    }
  }

  if (source === 'initiativePortal' && userLoggedIn) {
    window.open(process.env.REACT_APP_CONSOLE_URL!, "_self");
  }

  return (
    <div>
      <IonPage id="login-registration-page">
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={loginUser} className="login-registration-form ion-padding">
            <IonItem>
              <IonLabel position="stacked" color="primary">Email Address</IonLabel>
              <IonInput
                name="emailAddress"
                type="email"
                spellCheck={false}
                autocapitalize="off"
                onIonChange={e => setInputEmailAddress(e.detail.value!)}
                className="tab-order-1"
                required >
              </IonInput>
            </IonItem>
            <IonItem lines="none">
              <br></br>
            </IonItem>
            {formSubmitted && emailAddressError && <IonText color="danger">
              <p className="ion-padding-start">
                Email Address is required
              </p>
            </IonText>}

            <IonItem>
              <IonLabel position="stacked" color="primary">Password</IonLabel>
              <IonInput type="password" autocomplete="new-password" name="password" className="tab-order-2" autoSave='off' value={inputPassword} onIonChange={e => setInputPassword(e.detail.value!)} onIonBlur={() => setPasswordBlurred(true)} onIonFocus={() => setPasswordBlurred(false)}>
              </IonInput>
            </IonItem>

            <IonItem lines="none">
              <IonRouterLink className="link" tabIndex={-1} routerLink={'/forgot_reset/' + source}><IonLabel style={{ fontSize: "75%" }} color="medium">Forgot Password?</IonLabel></IonRouterLink>
            </IonItem>

            {paswordBlurred && passwordError && <IonText color="danger">
              <p className="ion-padding-start">
                Password is required
              </p>
            </IonText>}

            <IonButton onClick={loginUser} disabled={!canLogin} expand="block">Login</IonButton>

          </form>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
          />

          <IonAlert
            cssClass='my-loading-class'
            isOpen={showAlert}
            backdropDismiss={false}
            onDidDismiss={() => setShowAlert(false)}
            header={alertMessage}
            buttons={[
              {
                text: "OK",
              }
            ]}
          />
        </IonContent>
        <IonToast
          isOpen={showAfterResetToast}
          onDidDismiss={() => {
            try {
              Cookies.remove('passwordReset', { domain: process.env.REACT_APP_DOMAIN });
            }
            finally {
              setShowAfterResetToast(false);
            }
          }}
          message='Password reset successfully. Please use your new credentials to login to your account'
          position='top'
          duration={2000}
          color='success'
        />
      </IonPage>
    </div>
  );



};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn,
    setUserRole,
    setEmailAddress
  },
  component: Login
})