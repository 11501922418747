import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { BcorpIdea } from "@s360/common-models/bcorp-idea";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { trimmer } from "../../helper/trimmer";
import { KeywordTranslationService } from "../../services/KeywordTranslationServices";
import { BcorpInfoModalProps } from "./BcorpInfoModalProps";

const BcorpInfoModal: React.FC<BcorpInfoModalProps> = (props) => {
    const [displayCategory, setDisplayCategory] = useState({
        category: '',
        color: 'primary'
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitialMount, setIsInitialMount] = useState<boolean>(true);

    useEffect(() => {

        if(!isInitialMount) {
            setIsInitialMount(true)
            return;
        }

        const keywordTranslationService: KeywordTranslationService = new KeywordTranslationService();
        const bcorpIdea: BcorpIdea = props.bcorpIdea;

        setIsLoading(true)
        keywordTranslationService.translateKeyword(bcorpIdea.keywordType, bcorpIdea.impactArea)
            .then((category: string) => {
                setDisplayCategory({
                    category: category,
                    color: category.toLowerCase()
                });
                console.log(category)
            })
            .catch((error: Error) => {
                console.log(error)
                setDisplayCategory({
                    category: `An error has occurred`,
                    color: `danger`
                })
            })
            .finally(() => {
                setIsLoading(false);
                setIsInitialMount(false)
            });
    }, [props]);


    const handleOnExitClick = () => {
        props.closeAction();
    }

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>
                        {props.bcorpIdea.questionSummary}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            onClick={handleOnExitClick}
                        >
                            <IonIcon
                                icon={closeOutline}
                                slot="icon-only"
                            />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid
                    className="max-width ion-margin"
                >
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol
                                    className="ion-margin-end"
                                >
                                    Points Available
                                </IonCol>
                                <IonCol
                                    className="ion-margin-end"
                                >
                                    Points Earned
                                </IonCol>
                                <IonCol>
                                     Impact Score
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="black-border ion-margin-end"
                                >
                                    {trimmer(props.bcorpIdea.pointsAvailable)}
                                </IonCol>
                                <IonCol
                                    className="black-border ion-margin-end"
                                >
                                    {trimmer(props.bcorpIdea.pointsEarned)}
                                </IonCol>
                                <IonCol
                                    className="black-border"
                                >
                                    {trimmer(props.bcorpIdea.impactScore)}
                                </IonCol>
                            </IonRow>
                        </IonCol>

                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                Status
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="black-border"
                                >
                                    {props.bcorpIdea.bcorpIdeaStatus}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                Impact Topic
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="black-border "
                                >
                                    {props.bcorpIdea.impactTopic}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol
                                    className="ion-margin-end"
                                >
                                    B Corp Impact Area
                                </IonCol>
                                <IonCol
                                    className=""
                                >
                                    S360 Category
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="black-border ion-margin-end"
                                >
                                    {props.bcorpIdea.impactArea}

                                </IonCol>
                                <IonCol
                                    className="black-border"
                                >
                                    {
                                        isLoading ?
                                            <IonText
                                                color="medium"
                                            >
                                                Loading...
                                            </IonText>
                                            :
                                            <IonText
                                                color={`${displayCategory.color}`}
                                            >
                                                {displayCategory.category}
                                            </IonText>
                                    }

                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                Question
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="black-border"
                                >
                                    {props.bcorpIdea.question}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                Answers
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="black-border"
                                >
                                    {props.bcorpIdea.answers.map((answer: string) => (
                                        <IonItem
                                            color={(props.bcorpIdea.providedAnswers.includes(answer)) ? "primary" : ""}
                                            lines="none"
                                            className="selected-answer-border"
                                        >
                                            <IonCol>{answer}</IonCol>
                                            {
                                                (props.bcorpIdea.providedAnswers.includes(answer)) &&
                                                <IonCol
                                                    size="auto"
                                                >
                                                    <i>
                                                        (Selected)
                                                    </i>
                                                </IonCol>
                                            }
                                        </IonItem>
                                    ))}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>
    );
}

export default BcorpInfoModal;