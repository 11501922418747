import Axios from "axios";
import { Plugins } from '@capacitor/core';
import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import Cookies from 'js-cookie';
import { UserActivityLog, UserActivityLogRequest } from '@s360/common-models/activityLog';
import { UserCognitoLog } from "@s360/common-models/user";

const { Storage } = Plugins;

export class AuthActivityService {
    private authorizationToken: string;
    constructor() {
        this.authorizationToken = this.getAuthorizationToken();
    }

    getAuthorizationToken = (): string => {
        const token: string = Cookies.get('token') + '';
        return token;
      }
    
    private getObject() : Promise<AuthenticationResponse> {
        return new Promise<AuthenticationResponse>((resolve, reject) => {
             Storage.get({key: 'sessionParams'}).then((result: { value: string }) => {
                 resolve(JSON.parse(result.value!))
             }).catch((error: any) => {
                 reject(error);
             })
        })
    }

    private getActivityLogs(API_URL: string, dateRange: UserActivityLogRequest): Promise<UserActivityLog[]> {
        return new Promise<UserActivityLog[]>((resolve,reject) => {
            this.getObject().then((value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request: UserActivityLogRequest  = {
                    companyID: value.companyID || '',
                    startDate: dateRange?.startDate || 0,
                    endDate: dateRange?.endDate || 0,
                }
                const getUsersURL = API_URL || '';
                Axios.post(getUsersURL, request, config).then((response)=>{
                    const responseData = JSON.parse(response.data);
                    const userActivityLogs = JSON.parse(responseData.body);
                    console.log(API_URL);
                    console.log(userActivityLogs);
                    resolve(userActivityLogs);
                }).catch((error)=> {
                    reject(error);
                })
            })
        })
    }

    private getUsersStatus(API_URL: string): Promise<UserCognitoLog[]> {
        return new Promise<UserCognitoLog[]>((resolve, reject) => {
            this.getObject().then((value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request = {
                    companyID: value.companyID || '',
                }
                const getUsersURL = API_URL || '';
                Axios.post(getUsersURL, request, config).then((response)=>{
                    const responseData = JSON.parse(response.data);
                    const userCognitoLogs = JSON.parse(responseData.body);
                    console.log(API_URL);
                    console.log(userCognitoLogs);
                    resolve(userCognitoLogs);
                }).catch((error)=> {
                    reject(error);
                })
            })
        })
    }

    public getActivatedUsers(): Promise<UserCognitoLog[]> {
        return this.getUsersStatus(process.env.REACT_APP_API_GET_ACTIVATED_USERS_URL!);
    }

    public getNonActivatedUsers(): Promise<UserCognitoLog[]> {
        return this.getUsersStatus(process.env.REACT_APP_API_GET_NON_ACTIVATED_USERS_URL!);
    }

    public getUniqueUserLogins(dateRange: UserActivityLogRequest): Promise<UserActivityLog[]> {
        return this.getActivityLogs(process.env.REACT_APP_API_GET_UNIQUE_USER_LOGINS_URL!, dateRange);
    }

    public getAllLoginAttempts(dateRange: UserActivityLogRequest): Promise<UserActivityLog[]> {
        return this.getActivityLogs(process.env.REACT_APP_API_GET_ALL_LOGIN_ATTEMPTS_URL!, dateRange);
    }

    public getSuccessfulLoginAttempts(dateRange: UserActivityLogRequest): Promise<UserActivityLog[]> {
        return this.getActivityLogs(process.env.REACT_APP_API_GET_SUCCESSFUL_LOGIN_ATTEMPTS_URL!, dateRange);
    }

    public getFailedLoginAttempts(dateRange: UserActivityLogRequest): Promise<UserActivityLog[]> {
        return this.getActivityLogs(process.env.REACT_APP_API_GET_FAILED_LOGIN_ATTEMPS_URL!, dateRange);
    }
}