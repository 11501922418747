import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonPage,
  IonLabel,
  IonCard,
  IonCardContent,
} from "@ionic/react";

import { Initiative } from "@s360/common-models/initiative";
import { useLocation, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import { APIService } from "../services/APIService";
import ReportProgressList from "../components/ReportProgressList";
import "./ReportPage.css";
import { retrieveInitiatives } from "@s360/common-models/retrieveInitiatives";
import { ResponseReportContent } from "@s360/common-models/apiResponses";

interface OwnProps extends RouteComponentProps { }
interface ReportProps extends RouteComponentProps, UserState { }

const ReportPage: React.FC<ReportProps> = ({ history, userRole, emailAddress }) => {
  const [targetInitiatives, setTargetInitiatives] = useState<Initiative[]>([]);
  const [inProgressInitiatives, setInProgressInitiatives] = useState<
    Initiative[]
  >([]);
  const [overDueInitiatives, setOverDueInitiatives] = useState<Initiative[]>(
    []
  );
  const [saveStatementEnabled, setSaveStatementEnabled] = useState<boolean>(
    false
  );
  const [saveStoryEnabled, setSaveStoryEnabled] = useState<boolean>(false);
  const [strMissionStatement, setStrMissionStatement] = useState<string>("");
  const [strStory, setStrStory] = useState<string>("");
  const [lateDate, setLateDate] = useState<any>();

  //const [title, setTitle] = useState("");
  let dimension = "All";
  let title = "Multi";
  const location = useLocation();
  const sessionParams = location.state;
  if (location.pathname.endsWith("environment")) {
    title = "Environment";
    dimension = "Environment";
  } else if (location.pathname.endsWith("economy")) {
    title = "Governance";
    dimension = "Economy";
  } else if (location.pathname.endsWith("society")) {
    title = "Society";
    dimension = "Society";
  } else if (location.pathname.endsWith("collaboration")) {
    title = "Collaboration"
    dimension = "Collaboration"
  }

  const getReportData = (goals: string) => {
    const apiService = new APIService();

    apiService
      .getReportConfig(dimension)
      .then((result) => {
        if (result) {
          let reportContent = result.report_contents.find((reportContent: ResponseReportContent ) => reportContent.type == 0);
          if (reportContent && reportContent.content) {
            setStrMissionStatement(reportContent.content);
            setSaveStatementEnabled(false);
          }
          reportContent = result.report_contents.find((reportContent: ResponseReportContent) => reportContent.type == 1);
          if (reportContent && reportContent.content) {
            setStrStory(reportContent.content);
            setSaveStoryEnabled(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error get report config:", error);
      });

    let params: retrieveInitiatives = {
      category: goals
    }
    apiService.retrieveInitiatives(params).then((result) => {
      setTargetInitiatives(result.body.initiatives);
      setInProgressInitiatives(
        result.body.initiatives.filter((initiative: Initiative) => initiative.trackingStatus != "Implemented")
      );
    });
  };

  const getLateInitiatives = (date: number) => {
    const apiService = new APIService();
    apiService.getLateInitiatives(date, dimension).then((result) => {
      setOverDueInitiatives(result.body.initiatives);
    });
  };

  function saveStatement() {
    const apiService = new APIService();
    apiService.saveReportConfig(0, dimension, strMissionStatement);
    setSaveStatementEnabled(false);
  }

  function saveStory() {
    const apiService = new APIService();
    apiService.saveReportConfig(1, dimension, strStory);
    setSaveStoryEnabled(false);
  }

  useEffect(() => {
    getReportData(dimension);
  }, [dimension]); //retrieve and display initiatives whenever dimension changes

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle color="light" size="large">{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow className="listContainer">
            <ReportProgressList
              displayedInitiatives={inProgressInitiatives}
            ></ReportProgressList>
          </IonRow>

          <IonRow className="listContainer">
            <IonCard>
              <IonCardContent>
                <IonLabel>Number of completed initiatives</IonLabel>
                <br />
                <IonLabel>
                  {" "}
                  {
                    targetInitiatives.filter((s) => s.trackingStatus == "Implemented")
                      .length
                  }
                </IonLabel>
              </IonCardContent>
            </IonCard>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(ReportPage),
});
