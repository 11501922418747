import React, { useState } from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonLabel,
  IonLoading,
  IonText,
  IonNote
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import UserIonInput from "./UserIonInput";
import UserIonTextarea from "./UserIonTextarea";
import { APIService } from "../services/APIService";
import { useLocation } from "react-router";
import { HttpStatus } from "@s360/common-models/httpStatus";
import { MainObjective } from "@s360/common-models/mainObjective";
import { MainObjectiveService } from "../services/MainObjectiveService";
import "./MainObjectiveForm.css";
import { Initiative } from '@s360/common-models/initiative';
import { result } from "lodash";


const labelTexts = {
  mainObjective: "Goal",
  description: "Description / notes",
  initiatives: "Initiatives",
};

const errorMessages = {
  mainObjective: "Goal is required",
  targetDate: "Target Date is required",
};

interface initiativeOption { value: string; label: string; }

const MainObjectiveForm: React.FC<{
  closeAction: () => void;
  showToast: () => void;
  showErrorToast: (msg: string) => void;
  editMainObjective?: MainObjective;
  targetTakenInitiativesList: string[];
}> = (props) => {
  const location = useLocation();

  const mainObjectiveService = new MainObjectiveService();
  const [mainObjective, setMainObjective] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [targetDate, setTargetDate] = useState<Date>(new Date());
  const [queuedInitiativeOptions, setQueuedInitiativeOptions] = useState<initiativeOption[]>([]);
  const [initiativeOptions, setInitiativeOptions] = useState<initiativeOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<initiativeOption | null>(null);
  const [newInitiative, setNewInitiative] = useState<Initiative>();

  const [mainObjectiveErrorMessage, setMainObjectiveErrorMessage] = useState<string>("");
  const [targetDateErrorMessage, setTargetDateErrorMessage] = useState<string>("");
  const [showClearAlert, setClearAlert] = useState(false);
  const [showCloseAlert, setCloseAlert] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [initiativeElements, setInitiativeElements] = useState<React.ReactElement[]>([]);

  React.useEffect(() => {
    if (submitDisabled) {
      checkInputs();
    }
  }, [
    mainObjective, targetDate
  ]);

  React.useEffect(() => {
    generateSelectedInitiativeList();
  }, [
    queuedInitiativeOptions
  ]);

  const getDateFromStr = (dateStr: string) => {
    if (!dateStr || dateStr === '') return new Date();
    var parts = dateStr.split('/');
    var mydate = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
    return mydate;
  }

  const getDateString = (date: Date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [(dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    date.getFullYear()
    ].join('/');
  };

  const submitInitiative = (newInitiative: Initiative) => {
    const apiService = new APIService();
    apiService.submitInitiative(newInitiative).then((result) => {

    });
  }

  React.useEffect(() => {
    setShowLoading(true);
    const apiService = new APIService();
    let options: initiativeOption[] = [];
    let queuedOptions: initiativeOption[] = [];
    apiService.retrieveInitiatives().then((result) => {
      result.body.initiatives.forEach((initiative: Initiative) => {
        if (props.editMainObjective && props.editMainObjective.initiativeIds.find(s => s === initiative.id)) {
          queuedOptions.push({ value: initiative.id + '', label: initiative.title });
        } else if (!props.targetTakenInitiativesList.includes(initiative.id!)) {
          options.push({ value: initiative.id + '', label: initiative.title });
        }
      })

      options.sort(function (a, b) {
        if (a.label && b.label) {
          if (a.label > b.label) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return 1;
        }
      });

      if (props.editMainObjective) {
        setQueuedInitiativeOptions(queuedOptions);
        setMainObjective(props.editMainObjective.title);
        setDescription(props.editMainObjective.description);
        setTargetDate(getDateFromStr(props.editMainObjective.targetDate));
      }
      setInitiativeOptions(options);

      setShowLoading(false);

    }).catch(error => {
      console.error(error)
    });



  }, []);

  const handleChange = (option: initiativeOption) => {
    setSelectedOption(option);
  };
  const submitInputs = async () => {

    if (mainObjective.trim() && targetDate) {
      let ids: string[] = [];
      queuedInitiativeOptions.forEach(option => {
        ids.push(option.value);
      })
      let newMainObjective: MainObjective = {
        companyID: '',
        title: mainObjective,
        description: description,
        targetDate: getDateString(targetDate),
        initiativeIds: ids,
      };

      if (props.editMainObjective) {
        newMainObjective.id = props.editMainObjective.id;
        newMainObjective.companyID = props.editMainObjective.companyID;


        mainObjectiveService.updateMainObjective(newMainObjective).then((result) => {
          if (result.status === HttpStatus.OK) {
            props.closeAction();
            props.showToast();
          } else {
            props.showErrorToast("Failed to update target");
          }
        }).catch(err => {
          console.error(err);
          props.showErrorToast(err);
        });
      } else {
        mainObjectiveService.submitMainObjective(newMainObjective).then((result) => {
          if (result.status === HttpStatus.OK) {
            props.closeAction();
            props.showToast();
          } else {
            props.showErrorToast("Failed to add target");
          }
        }).catch(err => {
          console.error(err);
          props.showErrorToast(err);
        });
      }
    }
    //  });

  };

  //Set error messages and disable/enable submit button
  const checkInputs = () => {
    mainObjective.trim()
      ? setMainObjectiveErrorMessage("")
      : setMainObjectiveErrorMessage(errorMessages.mainObjective);

    targetDate
      ? setTargetDateErrorMessage("")
      : setTargetDateErrorMessage(errorMessages.targetDate);

    if (
      mainObjective.trim() && targetDate
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const retrieveInputs = () => {
    checkInputs();
    submitInputs();
  };

  const clearInputs = () => {
    setMainObjective("");
    setDescription("");
    setTargetDate(new Date());
    setSelectedOption(null);
    setQueuedInitiativeOptions([]);
    setMainObjectiveErrorMessage("");
    setTargetDateErrorMessage("");
  };

  const setSelectedOptionToQueue = () => {
    if (selectedOption) {
      let queue: initiativeOption[] = queuedInitiativeOptions.slice();
      queue.push(selectedOption);
      setQueuedInitiativeOptions(queue);
      let selections: initiativeOption[] = initiativeOptions.slice();
      let index = selections.findIndex(s => s.value === selectedOption.value);
      selections.splice(index, 1);
      setInitiativeOptions(selections);
      setSelectedOption(null);
    }
  };

  const cancelSelection = (option: initiativeOption) => {
    let queue: initiativeOption[] = queuedInitiativeOptions.slice();
    let index = queue.findIndex(s => s.value === option.value);
    queue.splice(index, 1);
    setQueuedInitiativeOptions(queue);
    let selections: initiativeOption[] = initiativeOptions.slice();
    selections.push(option);
    selections.sort(function (a, b) {
      if (a.label && b.label) {
        if (a.label > b.label) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 1;
      }
    });
    setInitiativeOptions(selections);
  };

  const generateSelectedInitiativeList = () => {
    let i = 0;
    let list = queuedInitiativeOptions.map(function (option) {
      i++;
      return (
        <IonRow key={i}>
          <IonCol className="selected-box">{i.toString() + ". " + option.label}</IonCol>
          <IonCol size="2">
            <IonButton color="light" size="small" className="delete-button"
              disabled={false}
              onClick={() => cancelSelection(option)}
            >
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
      );
    }
    );
    setInitiativeElements(list);
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: 'black',
    }),
    control: (provided: any) => ({
      ...provided,
      border: '1px solid black',
      borderRadius: '0',
    }),
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            {props.editMainObjective ? "Edit Goal" : "Add Goal"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={submitDisabled}
              onClick={() => retrieveInputs()}
            >
              {props.editMainObjective ? "Update" : "Create"}
            </IonButton>
            <IonButton onClick={() => setClearAlert(true)}>Clear</IonButton>
            <IonButton onClick={() => setCloseAlert(true)}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <UserIonInput
                value={mainObjective}
                setValue={setMainObjective}
                labelText={labelTexts.mainObjective}
                errorMessage={mainObjectiveErrorMessage}
                maxLength={70}
              ></UserIonInput>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: "1rem" }}>
            <IonCol >
              <IonLabel>Target Date</IonLabel>
              <IonText color="danger">*</IonText>
            </IonCol>
          </IonRow>
          <IonRow >
            <IonNote style={{ marginLeft: "0.5rem" }} color="danger">{targetDateErrorMessage}</IonNote>
          </IonRow>
          <IonRow>
            <IonCol className="period-col calender-input" >
              <DatePicker dateFormat="dd/MM/yyyy" selected={targetDate} onChange={(date: Date) => setTargetDate(date)} />
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: "1rem" }}>
            <IonCol>
              <UserIonTextarea
                value={description}
                setValue={setDescription}
                labelText={labelTexts.description}
                errorMessage={""}
                mandatory={false}
                rows={2}
              ></UserIonTextarea>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel>Initiatives</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol >
              <Select
                value={selectedOption}
                onChange={handleChange}
                options={initiativeOptions}
                styles={customStyles}
              />
            </IonCol>
            <IonCol size="2">
              <IonButton className="add-button"
                disabled={false}
                onClick={() => setSelectedOptionToQueue()}
              >
                {"Add"}
              </IonButton>
            </IonCol>
          </IonRow>

          {initiativeElements}

        </IonGrid>
        <IonAlert
          isOpen={showClearAlert}
          backdropDismiss={false}
          onDidDismiss={() => setClearAlert(false)}
          header={"Are you sure you want to clear your inputs?"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                clearInputs();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonAlert
          isOpen={showCloseAlert}
          backdropDismiss={false}
          onDidDismiss={() => setCloseAlert(false)}
          header={"Are you sure you don't want to submit a target?"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                props.closeAction();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
      />
    </>
  );
};

export default MainObjectiveForm;
