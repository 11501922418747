export function dynamicSort(property: string): (a: any, b:any) => number {
  // Sorting in ascending order by default
  // Add " - " in front to sort in descending order
  let order: number = 1;
  if (property[0] === "-") {
    order = -1;
    property = property.substring(1);
  }
  return function (a: any, b: any): number {
    let propertyA: number | string = !isNaN(Number(a[property])) ? Number(a[property]) : a[property];
    let propertyB: number | string = !isNaN(Number(b[property])) ? Number(b[property]) : b[property];
    let result = (propertyA < propertyB) ? -1 : (propertyA > propertyB) ? 1 : 0;
    return result * order;
  }
}