import React from "react";
import {
    IonAlert
} from "@ionic/react";

import {
    setIsLoggedIn,
    setEmailAddress
} from "../data/user/user.actions";
import { useCookies } from "react-cookie";
import { Plugins } from '@capacitor/core';
import { clearSession } from "../helper/sessionManagement";
const { Storage } = Plugins;

interface SessionAlertProps {
    showAlert: boolean
}

 async function logout(removeCookie: any) {
    await removeCookie('token', { domain: process.env.REACT_APP_DOMAIN });
    await removeCookie('cid', { domain: process.env.REACT_APP_DOMAIN });
    await Storage.clear();
    clearSession();
    setEmailAddress();
    setIsLoggedIn(false);
    window.open('/login/initiativePortal', "_self");
  }
  
export const SessionAlert: React.FC <SessionAlertProps> = (
    props: SessionAlertProps
) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    console.log(`Entered session alert`);
   
    return (
      
        <IonAlert
          isOpen={props.showAlert}
          backdropDismiss={false}
    
          header={"You have been timed out due to inactivity. Please login again"}
          
          buttons={[
            {
              text: "Okay",
              handler: () => {
               logout(removeCookie);
              },
            }
          ]}
        />
    )
}