
import { resolve } from 'path';
import { utils, writeFile } from 'xlsx';

export interface exportAsExcelParams {
  data: any[],
  workSheetColumnNames: string[],
  workSheetName: string,
  filePath: string,
}

export function exportAsExcel(params: exportAsExcelParams) {
  const workBook = utils.book_new();
  const workSheetData = [
    params.workSheetColumnNames,
    ... params.data
  ];
  const workSheet = utils.aoa_to_sheet(workSheetData);
  utils.book_append_sheet(workBook, workSheet, params.workSheetName);
  writeFile(workBook, resolve(params.filePath));
}