export function dateConverter(date: Date, format?: string): string {
    const language: string = navigator.language;
    const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let formattedDate: string = '';
    formattedDate = date.toLocaleDateString(language, {timeZone: timeZone});
    return formattedDate;
}

interface dateConverterParams {
    date?: Date,
    dateNumber?: number,
    time?: boolean
}

export function dateToLocale(params: dateConverterParams): string {
    let formattedDate: string = '';
    const language: string = navigator.language;
    const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (params.time) {
        if (params.date) formattedDate = params.date!.toLocaleString(language, {timeZone: timeZone});
        if (params.dateNumber) formattedDate = new Date(params.dateNumber).toLocaleString(language, {timeZone: timeZone});
    } else {
        if (params.date) formattedDate = params.date!.toLocaleDateString(language, {timeZone: timeZone});
        if (params.dateNumber) formattedDate = new Date(params.dateNumber).toLocaleDateString(language, {timeZone: timeZone});
    }
    return formattedDate;
}
