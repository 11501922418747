import React, { useState } from "react";
import "./InitiativeList.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import "./UsersList.css"
import "../css/MainObjectiveList.css"
import { IonAlert, IonButton, IonGrid, IonIcon, IonModal, IonToast, IonLabel, IonPopover, IonList, IonItem } from "@ionic/react";
import {
  createOutline,
  trashOutline,
  ellipsisVerticalSharp
} from "ionicons/icons";
import { MainObjective } from "@s360/common-models/mainObjective";
import { MainObjectiveService } from "../services/MainObjectiveService";
import MainObjectiveForm from "./MainObjectiveForm";
import { stringShortenerEllipsis } from "../helper/StringShortenerEllipsis";
import DisplayTable from "./DisplayTable";
import InitiativesList from "./InitiativesList";
import { Initiative } from "@s360/common-models/initiative";
import { APIService } from "../services/APIService";
import ChildTable from "./ChildTable";

const MainObjectiveList: React.FC<{
  mainObjectiveList: MainObjective[];
  afterEditedAction: () => void;
  targetTakenInitiativesList: string[];
  onTargetClicked: (mainObjective: MainObjective) => any;
}> = (props) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [showEditToast, setShowEditToast] = useState(false);
  const [showEditFailedToast, setShowEditFailedToast] = useState(false);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [showDeleteFailedToast, setShowDeleteFailedToast] = useState(false);
  const [targetMainObjective, setTargetMainObjective] = useState<MainObjective>();
  const [objectiveInitiatives, setObjectiveInitiatives] = useState<boolean>(false);
  const [goalInitiatives, setGoalInitiatives] = useState<Initiative[]>([]);
  const [goalInitiativesElement, setGoalInitiativesElement] = useState<React.ReactElement>();
  const mainObjectiveService = new MainObjectiveService();
  const [showPopover, setShowPopover] = useState<{open: boolean, event: Event | undefined}>({
    open: false,
    event: undefined,
  });

  const mainObjectiveListHeaders: string[] = ["GOAL", "INITIATIVES", "DATE", ""];

  const mainObjectiveColumnIds: string[] = ["goal-title", "goal-initiatives", "goal-date", "goal-operations"];

  const mainObjectiveColumnWidths: string[] = ["65%", "15%", "15%", "1%"];
  
  React.useEffect(() => {
    const apiService = new APIService();
    apiService.retrieveInitiatives().then((result) => {
      setGoalInitiatives(result.body.initiatives);
    }).catch(error => {
      console.error(error);
    })
  }, [targetMainObjective])

  React.useEffect(() => {
    setGoalInitiativesElement(objectiveInitiatives ? displayGoalInitiatives(targetMainObjective!) : <></>);
  }, [objectiveInitiatives, targetMainObjective])

  const targetClicked = (mainObjective: MainObjective) => {
    props.onTargetClicked(mainObjective);
  }

  const onDeleteButton = (mainObjective: MainObjective) => {
    setTargetMainObjective(mainObjective);
    setIsOpenDeleteDialog(true);
  }

  const onEditButton = (mainObjective: MainObjective) => {
    setTargetMainObjective(mainObjective);
    setIsOpenEditDialog(true);
  }

  const deleteMainObjective = () => {
    if (!targetMainObjective) return;
    mainObjectiveService.deleteMainObjective(targetMainObjective).then((result) => {
      if (result.status === 200) {
        setShowDeleteToast(true);
        props.afterEditedAction();
      } else {
        setShowDeleteFailedToast(true);
      }
    })
      .catch((error) => {
        console.error("Error delete user:", error);
        setShowDeleteFailedToast(true);
      });
  }

  const afterUpdated = () => {
    setShowEditToast(true);
    props.afterEditedAction();
  }

  const getMainObjectiveOperations = (mainObjective: MainObjective): React.ReactElement => {
    return (
      <>
          <IonIcon icon={ellipsisVerticalSharp} onClick={(event) => {
              setTargetMainObjective(mainObjective);
              setShowPopover({ open: true, event: event.nativeEvent });
            }}
          />
        <IonPopover key={targetMainObjective?.id + ''}
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
          showBackdrop={false}
        >
          <IonList key={targetMainObjective?.id + ''}>
            <IonItem key={targetMainObjective?.id + '_edit'} button onClick={() => {
              setShowPopover({ open: false, event: undefined });
              onEditButton(targetMainObjective!);
              }}
            >Edit</IonItem>
            <IonItem key={targetMainObjective?.id + '_del'} button onClick={() => {
              setShowPopover({ open: false, event: undefined });
              onDeleteButton(targetMainObjective!);
            }}
            >Delete</IonItem>
          </IonList>
        </IonPopover>
      </>
    )
  }

  const getMainObjectiveDate = (mainObjective: MainObjective): React.ReactElement => {
    return (
      <>{mainObjective.targetDate ? mainObjective.targetDate : ''}</>
    )
  }

  const getMainObjectiveInitiatives = (mainObjective: MainObjective): React.ReactElement => {
    return (
      <div className="cursorPointer">
              {mainObjective.initiativeIds.length + ' Initiatives'}
      </div>
    )
  }

  const displayGoalInitiatives = (mainObjective: MainObjective): React.ReactElement => {
    let displayedGoalInitiatives: Initiative[] = goalInitiatives.filter(initiative => {
      return (mainObjective.initiativeIds?.includes(initiative.id!))
    });
    let result: React.ReactElement = displayedGoalInitiatives.length > 0 ? <ChildTable displayedInitiatives={displayedGoalInitiatives}/> : <></>;
    return result;
  }

  const getMainObjectiveTitle = (mainObjective: MainObjective): React.ReactElement => {
    return (
      <div className="cursorPointer"
        onClick={() => {
          targetMainObjective?.id === mainObjective.id ? setObjectiveInitiatives(!objectiveInitiatives) : setObjectiveInitiatives(true);
          setTargetMainObjective(mainObjective);
        }}>
      {stringShortenerEllipsis(mainObjective.title, 70)}
      </div>
    )
  }

  const rowInfoGetters: ((mainObjective: MainObjective) => React.ReactElement)[] = [
    getMainObjectiveTitle,
    getMainObjectiveInitiatives,
    getMainObjectiveDate,
    getMainObjectiveOperations
  ]

  return (
    <IonGrid>
      <DisplayTable
         items={props.mainObjectiveList}
         headers={mainObjectiveListHeaders}
         rowInfoGetters={rowInfoGetters}
         customColumnId={mainObjectiveColumnIds}
         customWidth={mainObjectiveColumnWidths}
         tableId='main-objective-table'
         customTextColor="black"
         customBackgroundColor="000000"
         customTitleStyle={true}
         popupChildRow={true}
         popupChildRowElement={goalInitiativesElement}
         popupChildRowID={targetMainObjective?.id}
       />
      <IonModal
        isOpen={isOpenEditDialog}
        onDidDismiss={() => setIsOpenEditDialog(false)}
        backdropDismiss={false}
      >
        <MainObjectiveForm
          showToast={() => afterUpdated()}
          showErrorToast={(msg: string) => setShowEditFailedToast(true)}
          closeAction={() => setIsOpenEditDialog(false)}
          editMainObjective={targetMainObjective}
          targetTakenInitiativesList={props.targetTakenInitiativesList}
        ></MainObjectiveForm>
      </IonModal>
      <IonAlert
        isOpen={isOpenDeleteDialog}
        backdropDismiss={false}
        onDidDismiss={() => setIsOpenDeleteDialog(false)}
        header={"Are you sure you want to delete this target?"}
        buttons={[
          {
            text: "OK",
            handler: () => {
              deleteMainObjective();
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ]}
      />
      <IonToast
        isOpen={showDeleteToast}
        onDidDismiss={() => setShowDeleteToast(false)}
        message="Target is deleted successfully"
        duration={1000}
        position="top"
        color="success"
      />
      <IonToast
        isOpen={showDeleteFailedToast}
        onDidDismiss={() => setShowDeleteFailedToast(false)}
        message="Failed to delete target"
        duration={1000}
        position="top"
        color="danger"
      />
      <IonToast
        isOpen={showEditToast}
        onDidDismiss={() => setShowEditToast(false)}
        message="Target is edited successfully"
        duration={1000}
        position="top"
        color="success"
      />
      <IonToast
        isOpen={showEditFailedToast}
        onDidDismiss={() => setShowEditFailedToast(false)}
        message={"Failed to edit target"}
        duration={1000}
        position="top"
        color="danger"
      />
    </IonGrid>
  );
};

export default MainObjectiveList;
