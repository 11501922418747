import React, { useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import UserIonInput from "./UserIonInput";
import UserIonSelect from "./UserIonSelect";
import { User } from "@s360/common-models/user";
import { UserService } from "../services/UserService";
import { Roles } from "@s360/common-models/roles";
import { HttpStatus } from "@s360/common-models/httpStatus";

const labelTexts = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email Address",
  role: "Role",
};

const errorMessages = {
  firstName: "First Name is required",
  lastName: "Last Name is required",
  email: "Email Address is required",
};
const userRoleOptions: string[] = [
  "Administrator", "Staff", "Visitor"
];

const UserForm: React.FC<{
  allUsers: User[];
  closeAction: () => void;
  showToast: () => void;
  showErrorToast: (msg: string) => void;
  user?: User;
}> = (props) => {
  const userService = new UserService();
  const [firstName, setFirstName] = useState<string>(props.user ? props.user.firstName : "");
  const [lastName, setLastName] = useState<string>(props.user ? props.user.lastName :"");
  const [email, setEmail] = useState<string>(props.user ? props.user.emailAddress :"");
  const [isEditMode, setIsEditMode] = useState<boolean>(props.user ? true : false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<string>("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string>("");
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
  const [showClearAlert, setClearAlert] = useState(false);
  const [showCloseAlert, setCloseAlert] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const emailRegex: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const max_admin_accounts: number = 2;
  const max_staff_accounts: number = 20;
  const max_visitor_accounts: number = 1;

  React.useEffect(() => {
    if (submitDisabled) {
      checkInputs();
    }
  }, [
    firstName,
    lastName,
    email
  ]);

  const getRoleType = (role: string) => {
    switch (role) {
      case 'Administrator':
        return Roles.Admin;
      case 'Staff':
        return Roles.User;
      case 'Visitor':
        return Roles.Visitor;
      default:
        return Roles.User;
    }
  }
  const [userRole, setUserRole] = useState<string>(props.user && props.user.userRole ? getRoleType(props.user.userRole) :"Staff");

  const maxAccountsAvailable = (role?: string): number => {
    switch (role) {
      case Roles.Admin: 
        return max_admin_accounts;
      case Roles.User:
        return max_staff_accounts;
      case Roles.Visitor:
        return max_visitor_accounts;
      default:
        return 20;
    }
  }

  const filterCurrentAccounts = (users: User[], role: string): number => {
    let accounts: User[] = users.filter(user => {
      return (user && (user.userRole === role));
    });
    return accounts.length;
  }
  
  const validateAccountsAvailable = (): boolean => {
    let currentAccounts: number = filterCurrentAccounts(props.allUsers, getRoleType(userRole));
    let checkMaxAccountType: boolean = ((currentAccounts + 1) <= maxAccountsAvailable(getRoleType(userRole)));
    let checkMaxAccountsAvailable: boolean = ((currentAccounts + 1) <= maxAccountsAvailable());
    
    if (!checkMaxAccountType) {
      props.showErrorToast(`You have reached your limit of ${userRole} roles! (Max ${maxAccountsAvailable(getRoleType(userRole))})\nPlease contact S360 to enquire about adding more user roles.`);
    } else if (!checkMaxAccountsAvailable) {
      props.showErrorToast(`You have reached your limit of all user roles! (Max ${maxAccountsAvailable()}) Please contact S360 to enquire about adding more user roles.`);
    }

    return (checkMaxAccountType && checkMaxAccountsAvailable);
  }

  const submitInputs = async () => {
   
      if (
         lastName.trim() &&
         firstName.trim() &&
         email.trim() &&
         validateAccountsAvailable()
       ) {
         let role = Roles.User;
         if (userRole === 'Administrator') {
            role = Roles.Admin;
         } else if (userRole === 'Visitor') {
            role = Roles.Visitor;
         }
          let newUser : User= {
            id: props.user ?  props.user.id : '',
            companyID: '',
            lastName: lastName,
            firstName: firstName,
            emailAddress: email,
            userRole: role
          };
          if (isEditMode) {
            userService.updateUser(newUser).then((result) => {
              if (result.httpStatus === HttpStatus.OK) {
                props.closeAction();
                props.showToast();
              } else {
                props.showErrorToast("Failed to update user");
              }
            }).catch(error => {
              props.showErrorToast(error);
          });
          } else {
          userService.addUser(newUser).then((result) => {
            if (result.httpStatus === HttpStatus.OK) {
              props.closeAction();
              props.showToast();
            } else {
              props.showErrorToast("Failed to add user");
            }
          }).catch(error => {
              props.showErrorToast(error);
          });
        }
       }
  };

  //Set error messages and disable/enable submit button
  const checkInputs = () => {
    firstName.trim()
      ? setFirstNameErrorMessage("")
      : setFirstNameErrorMessage(errorMessages.lastName);
    lastName.trim()
      ? setLastNameErrorMessage("")
      : setLastNameErrorMessage(errorMessages.firstName);
    email.trim()
      ? setEmailErrorMessage("")
      : setEmailErrorMessage(errorMessages.email);

    if (
      firstName.trim() &&
      lastName.trim() &&
      email.trim()
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const retrieveInputs = () => {
    checkInputs();
    submitInputs();
  };

  const clearInputs = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            {isEditMode ?  "Edit User" : "Add User"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={submitDisabled}
              onClick={() => retrieveInputs()}
            >
              {isEditMode ?  "Update" : "Create"}
            </IonButton>
            <IonButton onClick={() => setClearAlert(true)}>Clear</IonButton>
            <IonButton onClick={() => setCloseAlert(true)}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding scrollable">
        <IonGrid className="max-width">
          <IonRow>
            <IonCol>
              <UserIonInput
                value={firstName}
                setValue={setFirstName}
                labelText={labelTexts.firstName}
                errorMessage={firstNameErrorMessage}
                maxLength={50}
              ></UserIonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <UserIonInput
                value={lastName}
                setValue={setLastName}
                labelText={labelTexts.lastName}
                errorMessage={lastNameErrorMessage}
                maxLength={50}
              ></UserIonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <UserIonInput
                value={email}
                setValue={setEmail}
                labelText={labelTexts.email}
                errorMessage={emailErrorMessage}
                disabled={isEditMode}
                maxLength={50}
              ></UserIonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <UserIonSelect
                value={userRole}
                setValue={setUserRole}
                options={userRoleOptions}
                labelText={labelTexts.role}
                errorMessage={""}
                disabled={false}
              ></UserIonSelect>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonAlert
          isOpen={showClearAlert}
          backdropDismiss={false}
          onDidDismiss={() => setClearAlert(false)}
          header={"Are you sure you want to clear your inputs?"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                clearInputs();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonAlert
          isOpen={showCloseAlert}
          backdropDismiss={false}
          onDidDismiss={() => setCloseAlert(false)}
          header={"Are you sure you don't want to submit a user?"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                props.closeAction();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </>
  );
};

export default UserForm;
