import React from "react";
import { IonPage, IonHeader, IonContent, IonToolbar, IonTitle, IonButton } from "@ionic/react";
import '../css/app.scss';
import { FieldProps } from "./ResetFormFields";
import { ResetService } from "../services/ResetService";
import { ResetResponse } from "@s360/common-models/apiResponses";
import { ResetPassword } from "@s360/common-models/resetPassword";


export interface Fields {
    [key: string]: FieldProps;
}

export interface FormProps {
    emailAddress: string;
    history: any;
    fields: Fields;
    render: () => React.ReactNode;

}

export interface FormContext extends FormState {
    /* Function that allows values in the values state to be set */
    setValues: (values: Values) => void;

    validate: (fieldName: string, value: string) => void;
}

/*
 * The context which allows state and functions to be shared with Field.
 * Note that we need to pass createContext a default value which is why undefined is unioned in the type
 */

export const FormContext = React.createContext<FormContext | undefined>(undefined);

export interface Values {
    [key: string]: any;
}

export interface Errors {
    [key: string]: string;
}

export interface FormState {
    values: Values;
    errors: Errors;
    submitSuccess?: boolean;
}

export interface ResetProps extends FormProps {
    emailAddress: string;
    history: any;
    resetSuccess: boolean;
}

export interface CompareValue {
    newPassword: string
}

export const required = (value: string, fieldName: string): string =>
    value === undefined ||
        value === null ||
        value === ""
        ? "The current password must be provided"
        : "";

export const isValidPassword = (value: string, fieldName: string): string =>
    value &&
        value.search(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        )
        ? " The password must be of minimum eight characters containing at least one letter, one number and one special character"
        : "";

export const comparePassword = (value: string, state: CompareValue): string =>
    value &&
        value === state.newPassword
        ? ""
        : "The passwords do not match";

export class Reset extends React.Component<FormProps, FormState> {
    private currentPasswordProvided: boolean;
    private passwordValidated: boolean;
    private completeValidation: boolean;
    static resetParams: ResetPassword;
    public emailAddress: string;
    static history: any;
    static resetSuccess: boolean;

    constructor(props: any) {
        super(props);
        const errors: Errors = {};
        const values: Values = {};
        this.state = {
            errors,
            values
        };

        this.currentPasswordProvided = false;
        this.passwordValidated = false;
        this.completeValidation = false;
        this.emailAddress = '';
        Reset.history = this.props.history;
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    private setValues = (values: Values) => {
        this.setState({ values: { ...this.state.values, ...values } })

    };

    private validate = (fieldName: string, value: string): string => {
        let newError: string = "";
        if (fieldName !== 'retypePassword') {

            if (
                this.props.fields[fieldName] &&
                this.props.fields[fieldName].validation
            ) {
                newError = this.props.fields[fieldName].validation!.rule(
                    value,
                    fieldName,
                    this.props.fields[fieldName].validation!.args
                );
                if (fieldName == 'currentPassword' && newError === "") {
                    this.currentPasswordProvided = true;
                }
                else if (fieldName == 'newPassword' && newError === "") {
                    this.passwordValidated = true;
                }
            }
        }
        else if (fieldName === 'retypePassword') {
            if (
                this.props.fields[fieldName] &&
                this.props.fields[fieldName].ComparePasswordValidation
            ) {
                let passwordValues: CompareValue = {
                    newPassword: this.state.values.newPassword
                }
                newError = this.props.fields[fieldName].ComparePasswordValidation!.rule(
                    value,
                    passwordValues,
                    this.props.fields[fieldName].ComparePasswordValidation!.args
                );
                if (this.currentPasswordProvided === true && this.passwordValidated === true && newError === "") {

                    Reset.resetParams = {
                        emailAddress: this.props.emailAddress,
                        currentPassword: this.state.values.currentPassword,
                        newPassword: this.state.values.newPassword,
                    }
                    this.completeValidation = true;
                }
                else {
                    this.completeValidation = false;
                }

            }
        }
        this.state.errors[fieldName] = newError;
        this.setState({
            errors: { ...this.state.errors, [fieldName]: newError }
        });
        return newError;
    }

    private handleSubmit = async (
        e: React.FormEvent<HTMLFormElement>
    ): Promise<void> => {
        e.preventDefault();
        try {
            const resetService = new ResetService();
            const resetResponse: ResetResponse = await resetService.reset(Reset.resetParams);
            
            if (resetResponse.status === 401) {
                const submitSuccess: boolean = false;
                this.setState({ submitSuccess })

            }
            else if (resetResponse.status === 200) {
                const submitSuccess: boolean = true;
                this.setState({ submitSuccess })
                window.open(process.env.REACT_APP_LOGIN_URL,'_self');
            }
        } catch (e) {
            // const submitSuccess: boolean = false;
            // this.setState({submitSuccess})
            console.error(e);
        }

    }


    public render() {
        const { submitSuccess } = this.state;
        const context: FormContext = {
            ...this.state,
            setValues: this.setValues,
            validate: this.validate
        };
        console.log(`[reset context][values]: ${JSON.stringify(context)}`)
        return (
            <IonPage id="login-registration-page">
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>Reset Password</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <FormContext.Provider value={context}>
                        <form onSubmit={this.handleSubmit} className="login-registration-form ion-padding">
                            <div className="container">
                                {this.props.render()}
                                <div className="form-group">
                                    <IonButton
                                        type="submit"
                                        disabled={!this.completeValidation}
                                        expand="block"
                                    >Submit</IonButton>
                                </div>
                                {submitSuccess === true && (
                                    <div className="alert alert-info" role="alert">
                                        Your account has been confirmed! Please login using your new password.
                                    </div>
                                )}
                                {submitSuccess === false &&
                                    (
                                        <div className="alert alert-danger" role="alert">
                                            Incorrect password provided. Please use the temporary password that was sent to your email.
                                        </div>
                                    )}
                            </div>
                        </form>
                    </FormContext.Provider>
                </IonContent>
            </IonPage>
        )
    }
}




