import { Initiative } from "@s360/common-models/initiative";
import { retrieveInitiatives } from "@s360/common-models/retrieveInitiatives";
import { APIService } from "../services/APIService";

export async function initiativeBUCleaner(id: string) {
  const InitiativeService = new APIService();
  let params: retrieveInitiatives = {
    businessUnit: id
  }
  InitiativeService.retrieveInitiatives(params).then(result => {
    result.body.initiatives.forEach((initiative: Initiative) => {
      let newInitiative: Initiative = initiative;
      newInitiative.businessUnitID = undefined;
      newInitiative.businessUnitName = undefined;
      InitiativeService.submitInitiative(newInitiative);
    })
  })
}