import { UserActions } from './user.actions';
import { UserState } from './user.state';

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case 'set-user-loading':
      return { ...state, loading: action.isLoading };
    case 'set-user-data':
      return { ...state, ...action.data };
    case 'set-emailAddress':
      return { ...state, emailAddress: action.emailAddress };
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.loggedIn };
    case 'set-user-role':
      return { ...state, userRole: action.userRole };
    case 'set-system-admin':
      return { ...state, systemAdmin: action.systemAdmin};
  }
}