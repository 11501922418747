import { Session } from "@s360/common-models/session";
import axios, { AxiosResponse } from "axios"
import Cookies from "js-cookie";

//TODO move to common models
export interface AttachmentResponse {
    uploadUrl: string,
    //Key is the name of the file that is stored in S3 bucket
    key: string
}

export class AttachmentService {
    private readonly getBucketUrl: string = process.env.REACT_APP_GET_BUCKET_URL!
    private readonly deleteAttachmentUrl: string = process.env.REACT_APP_DELETE_ATTACHMENT_URL!;


    private session: Session;
    private authorizationToken: string;

    constructor() {
        this.authorizationToken = this.getAuthorizationToken();
        this.session = this.getSession();
    }

    private getAuthorizationToken = (): string => {
        let token = Cookies.get('token') + '';
        return token;
    }

    private getSession(): Session {
        const sessionJson: string | undefined = Cookies.get(`session`);

        if(!sessionJson) {
            throw new Error('Not logged in, unable to perform operation');
        }

        return JSON.parse(sessionJson);
    }

    public getPutSignedUrl(initiativeID: string, fileName: string): Promise<AttachmentResponse> {
        return new Promise<AttachmentResponse>((resolve, reject) => {
            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            axios.post(this.getBucketUrl,
                {
                    companyID: this.session.companyID,
                    initiativeID: initiativeID,
                    fileName: `${fileName}`
                },
                config
            )
                .then((response: AxiosResponse<AttachmentResponse>) => {
                    console.log(`[AttachmentService][getBucket] response status ${response.status}`);
                    if (response.status === 200) {
                        const attachmentResponse = response.data;
                        resolve(attachmentResponse);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        })
    }

    public uploadAttachment(url: string, file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios.put(url, file)
                .then(() => {
                    resolve(`Attachment Upload`)
                })
                .catch((error: Error) => {
                    console.error(error);
                    reject(error);
                })
        })
    }

    public deleteAttachment(initiativeID: string, fileName: string) {
        return new Promise<string>((resolve, reject) => {
            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            axios.post(this.deleteAttachmentUrl,
                {
                    companyID :this.session.companyID,
                    initiativeID: initiativeID,
                    fileName: fileName
                },
                config
            )
                .then(() => {
                    resolve(`Attachment Deleted`)
                })
                .catch((error: Error) => {
                    console.error(error);
                    reject(error);
                })
        })
    }
}