import React from "react";
import { Reset, Fields, required, isValidPassword, comparePassword} from "../components/ResetPassword";
import { FormField } from "../components/ResetFormFields";
import { RouteComponentProps, useLocation,  useHistory  } from 'react-router-dom';

import '../css/app.scss';

interface OwnProps extends RouteComponentProps {}

interface ResetProps extends OwnProps {};


export const ResetForm: React.FunctionComponent<ResetProps> = () => {
    const params = useLocation();
    let history = useHistory();
    const emailAddress = params.pathname.substr(params.pathname.lastIndexOf('/')+1);
    
    const fields: Fields = {
        currentPassword: {
            id: "currentPassword",
            label:"Current Password:",
            validation: {rule: required}
        },
        newPassword: {
            id: "newPassword",
            label: "New Password:",
            validation: {rule: isValidPassword},
        },
        retypePassword: {
            id: "retypePassword",
            label: "Retype Password",
            ComparePasswordValidation: {rule: comparePassword},
        }
    }
    return (
    <Reset
          emailAddress = {emailAddress}
          history = {history}
          fields={fields}
          render={()=> (
              <React.Fragment>
                  <div className="alert alert-success" role="alert">
                      Reset your password to activate your account
                  </div>
                  <FormField {...fields.currentPassword}/>
                  <FormField {...fields.newPassword}/>
                  <FormField {...fields.retypePassword}/>
              </React.Fragment>
          )}
          />
   )
   
}