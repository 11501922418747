import { IonButton, IonCheckbox, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from "@ionic/react";
import Goal from "@s360/common-models/goal";
import React, { useState } from "react";
import { getSdgIcon } from "../helper/SdgIconGetter";
import "../css/InitiativeSDG.css";
import Target from "@s360/common-models/target";
import Indicator from "@s360/common-models/indicator";
import { checkmarkCircle, chevronDownOutline, chevronForwardOutline, informationCircle, returnDownForwardOutline } from "ionicons/icons";
import { getSdgLongDesc } from "../helper/SdgLongDescription";
import { getSdgColor } from "../helper/SdgColorGetter";
import { CheckboxChangeEventDetail } from "@ionic/core";


const InitiativeSDG: React.FC<{
  goals: Goal[];
  targets: Target[];
  indics: Indicator[];
  errorMessage?: string;
  setCurrentPage: (page: number) => void;
  checkInputs: () => void;
  deletee: string;
  setDeletee: (deletee: string) => void;
  addGoal: (object: Goal, objects: Goal[]) => Goal[];
  removeGoal: (object: Goal, objects: Goal[]) => Goal[];
  addTarget: (object: Target, objects: Target[]) => Target[];
  removeTarget: (object: Target, objects: Target[]) => Target[];
  addIndic: (object: Indicator, objects: Indicator[]) => Indicator[];
  removeIndic: (object: Indicator, objects: Indicator[]) => Indicator[];
  selectedGoal: Goal | undefined;
  setSelectedGoal: (selectedGoal: Goal) => void;
  selectingTargetsIndic: boolean;
  setSelectingTargetsIndic: ((object: boolean) => void);
  selectedGoals: Goal[];
  setSelectedGoals: (selectedOptions: Goal[]) => void;
  selectedTargets: Target[];
  setSelectedTargets: (selectedOptions: Target[]) => void;
  selectedIndics: Indicator[];
  setSelectedIndics: (selectedOptions: Indicator[]) => void;
}> = (props) => {

  const globalGoalsURL: string = "https://www.globalgoals.org/";

  const [goal, setGoal] = useState<Goal>();
  const [openTarget, setOpenTarget] = useState<Target>();
  const [selectingIndicators, setSelectingIndicators] = useState<boolean>(false);
  
  React.useEffect(() => {
    props.setSelectingTargetsIndic(false);
  }, []);

  function onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  const deleteGoal = (goal: Goal): void => {
    props.setSelectedGoals(
      props.removeGoal(goal, props.selectedGoals)
    );
    props.setDeletee(goal.name.toString());
  }

  const addGoal = (goal: Goal): void => {
    if (!props.selectedGoals.includes(goal)) {
      props.setSelectedGoals(
        props.addGoal(goal, props.selectedGoals)
      );
    }
    props.setSelectedGoal(goal);
    props.setSelectingTargetsIndic(true);
    setGoal(goal);
  }

  const expandTarget = (target: Target): void => {
    setSelectingIndicators(target === openTarget ? !selectingIndicators : true);
    setOpenTarget(target);
  }

  const checkBoxTarget = (e: CustomEvent<CheckboxChangeEventDetail>, target: Target): void => {
    setOpenTarget(target);
    if (e.detail.checked) {
      if (!props.selectedTargets.includes(target)) {
        props.setSelectedTargets(
          props.addTarget(target, props.selectedTargets)
        );
      }
      setSelectingIndicators(true);
    } else {
      props.selectedIndics.forEach(indic => {
        if (target.name.toString() === indic.targetName.toString()) {
          props.setSelectedIndics(
            props.removeIndic(indic, props.selectedIndics)
          )
          props.setDeletee(indic.name.toString());
        }
      });
      props.setSelectedTargets(
        props.removeTarget(target, props.selectedTargets)
      );
      props.setDeletee(target.name.toString());
    }
  }

  const checkBoxIndic = (e: CustomEvent<CheckboxChangeEventDetail>, indic: Indicator, target: Target): void => {
    
    if (e.detail.checked) {
      if (!props.selectedIndics.includes(indic)) {
        props.setSelectedIndics(
          props.addIndic(indic, props.selectedIndics)
        );
      }
      if (!props.selectedTargets.includes(target)) {
        props.setSelectedTargets(
          props.addTarget(target, props.selectedTargets)
        )
      }
    } else {
      props.setSelectedIndics(
        props.removeIndic(indic, props.selectedIndics)
      );
      props.setDeletee(indic.name.toString());
    }
  }
  
  const generateSDG = (goals: Goal[]): React.ReactElement => {
    return (
    <>
      {!props.selectingTargetsIndic && (
      <>
        <IonRow>Select an SDG to link this initiative to:</IonRow>
        <IonRow className="w-100">
        {!props.selectingTargetsIndic && goals.map((goal, index) => (
          <div id="sdg-icon">
            <a href={`${globalGoalsURL}${index + 1}`} target="_blank"><IonIcon className="sdg-info" icon={informationCircle} /></a>
            <IonIcon
              className={props.selectedGoals.includes(goal) ? "sdg-selected" : "sdg"}
              icon={checkmarkCircle}
              onClick={() => {deleteGoal(goal)}}
            />
            <IonImg
              id="img-goal"
              src={getSdgIcon(goal.name)}
              onClick={() => {addGoal(goal)}}
            />
          </div>))}
        </IonRow>
        <IonGrid>
          <IonRow className="p-5">
            <IonCol size="10"/>
            <IonCol>
              <IonButton
                className="float-right"
                shape="round"
                onClick={() => {
                  props.setCurrentPage(2);
                }}>
                Back
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                className="float-right"
                shape="round"
                onClick={() => {
                  props.checkInputs();
                  props.setCurrentPage(4);
                }}>
                Next
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>)}
      {props.selectingTargetsIndic && (<>
        <IonGrid>
          <div id="sdg-menu" style={{ backgroundColor: getSdgColor(goal?.name ? goal.name : "")}}>
            <IonRow>
              <IonLabel className="sdg-title">{goal?.name}&nbsp;{goal?.desc.split(" ").slice(3).join(" ")}</IonLabel>
            </IonRow>
            <IonRow>
              {getSdgLongDesc(goal?.name ? goal.name : "")}
            </IonRow>
          </div>
          {props.targets.length > 0 && (<>
          <IonRow>Please select Targets and Indicators:</IonRow>
          <IonRow id="target-header">
            <IonCol size="1">
              TARGET
            </IonCol>
            <IonCol size="10">
              DESCRIPTION
            </IonCol>
            <IonCol size="1">
              INDICATORS
            </IonCol>
          </IonRow>
          </>)}
          {props.targets.length > 0 && props.targets.filter(onlyUnique).map((target) => {
            if (goal?.targets.includes(target)) {
              return (<>
                <IonRow className={props.selectedTargets.includes(target) ? "target-item-selected" : "target-item"}>
                  <IonCol size="0.3">
                    <IonIcon
                      icon={selectingIndicators && target == openTarget ? chevronDownOutline : chevronForwardOutline}
                      onClick={() => {expandTarget(target)}}
                    />
                  </IonCol>
                  <IonCol size="0.7">
                    {target.name}
                  </IonCol>
                  <IonCol size="10.25"
                    onClick={() => {expandTarget(target)}}
                  >
                    {target.desc}
                  </IonCol>
                  <IonCol size="0.375">
                    <IonLabel>{target.indicators.length}</IonLabel>
                  </IonCol>
                  <IonCol size="0.375">
                    <IonCheckbox
                        key={target.name}
                        id="target-checkbox"
                        className="border text-center"
                        checked={props.selectedTargets.includes(target)}
                        onIonChange={(e) => {checkBoxTarget(e, target)}}
                      />
                  </IonCol>
                </IonRow>
                {selectingIndicators && openTarget === target && (
                  openTarget.indicators.map(indic => {
                    return (<>
                      <IonRow className={props.selectedIndics.includes(indic) ? "indic-item-selected" : "indic-item"}>
                        <IonCol size="0.2">
                          <IonIcon className="indic-item-icon" icon={returnDownForwardOutline}/>
                        </IonCol>
                        <IonCol size="0.8">
                          {indic.name}
                        </IonCol>
                        <IonCol size="10.25">
                          {indic.desc}
                        </IonCol>
                        <IonCol size="0.75">
                          <IonCheckbox
                            key={indic.name}
                            id="indic-checkbox"
                            className={"border text-center"}
                            checked={props.selectedIndics.includes(indic)}
                            onIonChange={(e) => {checkBoxIndic(e, indic, target)}}
                          />
                        </IonCol>
                      </IonRow>
                    </>)
                    }
                  )
                )}
              </>)
            }
          })}
          <IonRow>
            <IonCol>
              <IonButton
                className="float-right"
                shape="round"
                onClick={() => {
                  props.setSelectingTargetsIndic(false)
                }}>
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>)}
    </>);
  };

  return (<>
    {generateSDG(props.goals)}
  </>)
}

export default InitiativeSDG;