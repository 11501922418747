import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonItemGroup, IonModal, IonRow, IonText, IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { BcorpIdeaService } from "../../services/BcorpIdeaService";
import { BcorpIdea } from "@s360/common-models/bcorp-idea";
import { arrowDownOutline } from 'ionicons/icons'
import DropDown from "../DropDown/DropDown";
import { BcorpIdeaStatus } from "@s360/common-models/bcorp-idea-status";
import InitiativeModal from "../InitiativeModal";
import { Initiative } from "@s360/common-models/initiative";
import { dynamicSort } from "../../helper/dynamicSort";
import { trimmer } from "../../helper/trimmer";
import "../../css/Table.css";
import "./BcorpIdeaList.css"
import '../modal.css'
import BcorpInfoModal from "../BcorpInfoModal/BcorpInfoModal";

const BcorpIdeaList: React.FC = () => {
    const [bcorpIdeas, setBcorpIdeas] = useState<BcorpIdea[]>([]);
    const [bcorpIdeaInitiative, setBcorpIdeaInitiative] = useState<Initiative>();
    const [showAcceptedToast, setShowAcceptedToast] = useState<boolean>(false);
    const [showInitiativeModal, setShowInitiativeModal] = useState<boolean>(false);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [targetBcorpIdea, setTargetBcorpIdea] = useState<BcorpIdea>();
    const [errorMessage, setErrorMessage] = useState<string>('');


    const bcorpIdeaService = new BcorpIdeaService();

    useEffect(() => {
        bcorpIdeaService.getBcorpIdeas()
        .then((result: BcorpIdea[]) => {
            result.sort(dynamicSort("-impactScore"))
            setBcorpIdeas(result);
        })
        .catch(() => {
            setErrorMessage('Error occurred, could not get B Corp Ideas')
        });
    }, []);

    const showToast = () => {
        setShowAcceptedToast(true);
    }

    const closeModal = () => {
        setShowInitiativeModal(false);
    }


    const closeInitiativeModal = () => {
        setShowInitiativeModal(false);
    }

    const closeInfoModal = () => {
        setShowInfoModal(false);
    }

    const completeAccept = () => {
        bcorpIdeaService.updateBcorpIdeaStatus(targetBcorpIdea!.id!, BcorpIdeaStatus.ACCEPT);
        updateBcorpStatus(targetBcorpIdea!, BcorpIdeaStatus.ACCEPT)
    }

    const isBcorpIdeasAccepted = (bcorpIdea: BcorpIdea) => {
        return bcorpIdea.bcorpIdeaStatus !== BcorpIdeaStatus.ACCEPT
    }

    const handleRowClick = (newBcorpIdea: BcorpIdea) => {
        setTargetBcorpIdea(newBcorpIdea);
        setShowInfoModal(true);
    }

    const handleAccept = (bcorpIdea: BcorpIdea) => {
        const initiative = bcorpIdeaService.ideaToInitiative(bcorpIdea);
        setBcorpIdeaInitiative(initiative);
        setShowInitiativeModal(true);
    }

    const handleHold = (bcorpIdea: BcorpIdea) => {
        bcorpIdeaService.updateBcorpIdeaStatus(bcorpIdea.id!, BcorpIdeaStatus.HOLD);
        updateBcorpStatus(bcorpIdea, BcorpIdeaStatus.HOLD)
    }

    const handleReject = (bcorpIdea: BcorpIdea) => {
        bcorpIdeaService.updateBcorpIdeaStatus(bcorpIdea.id!, BcorpIdeaStatus.REJECT);
        updateBcorpStatus(bcorpIdea, BcorpIdeaStatus.REJECT)
    }

    const updateBcorpStatus = (targetBcorpIdea: BcorpIdea, bcorpIdeaStatus: BcorpIdeaStatus) => {
        //Updates the ui with the correct status
        const updatedBcorpIdeas: BcorpIdea[] = bcorpIdeas.map((bcorpIdea: BcorpIdea) => {
            if (bcorpIdea.id === targetBcorpIdea.id) {
                targetBcorpIdea.bcorpIdeaStatus = bcorpIdeaStatus;
            }
            return bcorpIdea;
        })
        setBcorpIdeas(updatedBcorpIdeas)
    }

    return (
        <IonContent>
            <IonGrid>
                { bcorpIdeas.length > 0
                    ? (
                        <>
                            <IonRow className="table-heading ion-align-items-center">
                                <IonCol className="col-question" size="5.25">
                                    <IonText>Question</IonText>
                                </IonCol>
                                <IonCol className="col-status" size="0.9">
                                    <IonText>Status</IonText>
                                </IonCol>
                                <IonCol className="col-points-earned" size="1.55">
                                    <IonText>Points Earned</IonText>
                                </IonCol>
                                <IonCol className="col-points-available" size="1.75">
                                    <IonText>Points Available</IonText>
                                </IonCol>
                                <IonCol className="col-impact-score" size="1.4">
                                    <IonText>Impact Score</IonText>
                                    <IonIcon className="heading-sortable-icon" icon={arrowDownOutline} />
                                </IonCol>
                                <IonCol className="col-button" size="0.75" />
                            </IonRow>
                            {bcorpIdeas.filter(isBcorpIdeasAccepted)
                                .map((bcorpIdea: BcorpIdea) => (
                                <IonRow
                                    key={bcorpIdea.id}
                                    className="table-row ion-align-items-center"
                                    onClick={() => handleRowClick(bcorpIdea)}
                                >
                                    <IonCol className="col-question" size="5.25">{bcorpIdea.question}</IonCol>
                                    <IonCol className="col-status" size="0.9">{bcorpIdea.bcorpIdeaStatus}</IonCol>
                                    <IonCol className="col-points-earned" size="1.55">{bcorpIdea.pointsEarned}</IonCol>
                                    <IonCol className="col-points-available" size="1.75">{bcorpIdea.pointsAvailable}</IonCol>
                                    <IonCol className="col-impact-score" size="1.4">{trimmer(bcorpIdea.impactScore)}</IonCol>
                                    <IonCol className="col-button" size="0.75">
                                        <DropDown
                                            isDisabled={bcorpIdea.bcorpIdeaStatus === BcorpIdeaStatus.REJECT}
                                        >
                                            <IonItem
                                                button={true}
                                                onClick={() => handleAccept(bcorpIdea)}
                                            >
                                                Accept
                                            </IonItem>
                                            <IonItem
                                                button={true}
                                                onClick={() => handleHold(bcorpIdea)}
                                            >
                                                Hold
                                            </IonItem>
                                            <IonItem
                                                button={true}
                                                onClick={() => handleReject(bcorpIdea)}
                                            >
                                                Reject
                                            </IonItem>
                                        </DropDown>
                                    </IonCol>
                                </IonRow>
                            ))}
                        </>
                    )
                    : (
                        <IonRow className="ion-margin-top">
                            <div className="alert alert-secondary blankInitiatives" role="alert">
                                B Corp Ideas haven't been uploaded. Please upload B Corp Ideas to display results
                            </div>
                        </IonRow>
                    )}
            </IonGrid>
            <IonToast
                isOpen={showAcceptedToast}
                onDidDismiss={() => setShowAcceptedToast(false)}
                message="Idea is accepted successfully"
                duration={1000}
                position="top"
                color="success"
            />
            <IonToast
                isOpen={errorMessage !== ''}
                onDidDismiss={() => setErrorMessage('')}
                message={errorMessage}
                duration={2000}
                position="top"
                color="danger"
            />
            <IonModal
                isOpen={showInitiativeModal}
                onDidDismiss={() => setShowInitiativeModal(false)}
                backdropDismiss={false}
                cssClass="initiative-modal"
            >
                <InitiativeModal
                    closeAction={closeModal}
                    showToast={showToast}
                    isNewInitiative={true}
                    editTargetInitiative={bcorpIdeaInitiative}
                    onSubmit={completeAccept}
                />
            </IonModal>

            <IonModal
                isOpen={showInfoModal}
                onDidDismiss={() => setShowInitiativeModal(false)}
                backdropDismiss={false}
                cssClass="initiative-modal"
            >
                <BcorpInfoModal
                    bcorpIdea={targetBcorpIdea!}
                    closeAction={closeInfoModal}
                />
            </IonModal>
        </IonContent>
    )
};

export default BcorpIdeaList;