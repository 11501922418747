import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router";
import { useCookies } from "react-cookie";
import { clearSession } from "../helper/sessionManagement";

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import {
  leaf,
  globe,
  logIn,
  logOut,
  bulb,
  addCircleOutline,
  pencil,
  statsChartOutline,
  readerOutline,
  peopleOutline,
  businessOutline,
  bagOutline,
  calculator,
  globeOutline,
  fingerPrintOutline,
  clipboardOutline
} from "ionicons/icons";
import "./Menu.css";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import {
  setIsLoggedIn,
  setEmailAddress,
  setUserRole,
  setSystemAdmin
} from "../data/user/user.actions";
import Cookies from 'js-cookie';
import { Plugins } from '@capacitor/core';
import { Roles } from "@s360/common-models/roles";
const { Storage } = Plugins;

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setEmailAddress: typeof setEmailAddress;
  setUserRole: typeof setUserRole;
  setSystemAdmin: typeof setSystemAdmin;
}

interface MenuProps extends RouteComponentProps, UserState, DispatchProps {
  userSession: any;
}

const appLoginCheck = async () : Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject)=>{
    try {
      let token = Cookies.get('token');
      if(token){
        resolve(true)
       
      }
      else{ 
        await Storage.clear();
        resolve(false)
      }
    }
    catch (error) {
      console.error(error)
     await Storage.clear();
    }
  })
}

const Menu: React.FC<MenuProps> = ({
  userRole,
  systemAdmin,
  setIsLoggedIn,
  setEmailAddress,
  setUserRole,
  setSystemAdmin
},
  props: MenuProps) => {
  const location = useLocation();
  const [isRegisterPage, setIsRegisterPage] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [appLoggedIn, setAppLoggedIn] = useState<boolean>(false);

  React.useEffect(()=>{
    appLoginCheck().then(setAppLoggedIn).catch(error=>{
      console.error(`Menu login check error: ${error}`)
    })
  },[setAppLoggedIn])

  async function getSession(): Promise<any> {
    return new Promise<string>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        const authDetails = JSON.parse(res.value!);
        if (authDetails) {
          if ('isLoggedIn' in authDetails) {
            setIsLoggedIn(true);
            setUserRole(authDetails.userRole);
          }
          if ('systemAdmin' in authDetails) {
            setSystemAdmin(true);
          }
        }
        else {
          setIsLoggedIn(false)
        }


      }).catch(error => {
        reject(error);
      })
      if (location.pathname.includes('/register')) {
        setIsRegisterPage(true);
      }
    })
  }


  useEffect(() => {
    getSession();
  }, [])
  async function logout() {
    await removeCookie('token', { domain: process.env.REACT_APP_DOMAIN });
    await removeCookie('cid', { domain: process.env.REACT_APP_DOMAIN });
    await removeCookie('session',  { domain: process.env.REACT_APP_DOMAIN })
    await Storage.clear();
    clearSession();
    setEmailAddress();
    setIsLoggedIn(false);
    window.open('/login/initiativePortal', "_self");
  }
  async function register() {
    window.open('/register', "_self");
  }
  async function login() {
    window.open('/login/initiativePortal', "_self");
  }




  if (!(location.pathname.includes('idea-new') || location.pathname.includes('ideaSubmit')) && userRole !== Roles.Visitor) {
    return (
      <IonMenu type="overlay" contentId="main" >
        <IonContent forceOverscroll={false}>
          <IonList lines='none'>
            {(appLoggedIn && userRole === Roles.Admin) && (
              <>
                <IonListHeader>Goals</IonListHeader>
                <IonMenuToggle key="mainobjective" auto-hide="false">
                  <IonItem
                    detail={false}
                    routerLink="/goals"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/goals")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={globe} />
                    <IonLabel>Goals</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              </>
            )}
            {(appLoggedIn && (userRole === Roles.User || userRole === Roles.Admin)) && (
              <IonListHeader>Initiatives</IonListHeader>
            )}

            {/* <IonMenuToggle key="initiative" auto-hide="false"> */}
              {(appLoggedIn && ( userRole === Roles.User || userRole === Roles.Admin)) && (
                <IonItem
                  detail={false}
                  routerLink="/console"
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/console")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={leaf} />
                  <IonLabel>Initiative List</IonLabel>
                </IonItem>
              )}

              {(appLoggedIn && (userRole === Roles.Admin) ) && (
                <IonItem
                  detail={false}
                  routerLink="/console/addEx"
                  routerDirection="none"
                >
                  <IonIcon slot="start" icon={addCircleOutline} />
                  <IonLabel>Enter Initiative</IonLabel>
                </IonItem>
              )}

              {(appLoggedIn && (userRole === Roles.User) ) && (
                <IonItem
                  detail={false}
                  routerLink="/console/addIdea"
                  routerDirection="none"
                >
                  <IonIcon slot="start" icon={addCircleOutline} />
                  <IonLabel>Enter Idea</IonLabel>
                </IonItem>
              )}

              {(appLoggedIn && userRole === Roles.Admin) && (
                <IonItem
                  detail={false}
                  routerLink="/idea"
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/idea")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={bulb} />
                  <IonLabel>Ideas List</IonLabel>
                </IonItem>
              )}

              {(appLoggedIn && userRole === Roles.Admin) && (
                <IonItem
                  detail={false}
                  routerLink="/bcorp"
                  routerDirection="none"
                  className={
                    location.pathname === "/bcorp" 
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={bulb} />
                  <IonLabel>B Corp</IonLabel>
                </IonItem>
              )}
            {/* </IonMenuToggle> */}
            {appLoggedIn && userRole === Roles.Admin && (
              <>
                <IonListHeader>Report</IonListHeader>
                <IonMenuToggle key="report" auto-hide="false">
                  <IonItem
                    detail={false}
                    routerLink="/report"
                    routerDirection="none"
                    className={
                      location.pathname == "/report" ? "selected" : undefined
                    }
                  >
                    <IonIcon slot="start" icon={statsChartOutline} />
                    <IonLabel>Overview</IonLabel>
                  </IonItem>

                  {/* <IonItem
                    detail={false}
                    routerLink="/report_target"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/report_target")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={statsChartOutline} />
                    <IonLabel>Goals</IonLabel>
                  </IonItem> */}
                  <IonItem
                    detail={false}
                    routerLink="/report_carbon"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/report_carbon")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={statsChartOutline} />
                    <IonLabel>Carbon</IonLabel>
                  </IonItem>
                  <IonItem
                    detail={false}
                    routerLink="/report_detail/society"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/report_detail/society")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={readerOutline} />
                    <IonLabel>Society</IonLabel>
                  </IonItem>
                  <IonItem
                    detail={false}
                    routerLink="/report_detail/environment"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/report_detail/environment")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={readerOutline} />
                    <IonLabel>Environment</IonLabel>
                  </IonItem>
                  <IonItem
                    detail={false}
                    routerLink="/report_detail/economy"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/report_detail/economy")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={readerOutline} />
                    <IonLabel>Governance</IonLabel>
                  </IonItem>
                  <IonItem
                    detail={false}
                    routerLink="/report_detail/collaboration"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/report_detail/collaboration")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={readerOutline} />
                    <IonLabel>Collaboration</IonLabel>
                  </IonItem>
                  <IonItem
                    detail={false}
                    routerLink="/global_goals_dashboard"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/global_goals_dashboard")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={globeOutline} />
                    <IonLabel>SDGs</IonLabel>
                  </IonItem>
                  <IonItem
                  detail={false}
                    routerLink="/bcorp_report"
                    routerDirection="none"
                    className={
                      location.pathname === "/bcorp_report" ?
                      "selected" :
                      undefined
                    }
                  >
                    <IonIcon slot="start" icon={statsChartOutline} />
                    <IonLabel>B Corp Overview</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              </>
            )}

            {appLoggedIn && userRole === Roles.Admin && (
              <>
                <IonListHeader>Tools</IonListHeader>
                <IonMenuToggle key="tools" auto-hide="false">
                  <IonItem
                    detail={false}
                    href={`${process.env.REACT_APP_CC_URL}/carbon-input`}
                    target="_blank"
                  >
                    <IonIcon slot="start" icon={calculator}></IonIcon>
                    <IonLabel>Carbon Calculator</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              </>
            )}


            <IonListHeader>Account</IonListHeader>
            <IonMenuToggle key="initiative" auto-hide="false">
              {appLoggedIn && userRole === Roles.Admin && systemAdmin ? (
                <IonItem
                  detail={false}
                  routerLink="/register"
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/register")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={clipboardOutline}></IonIcon>
                  <IonLabel>Register a Company</IonLabel>
                </IonItem>
              ) : (null)
              }
              {appLoggedIn && userRole === Roles.Admin ? (
                <IonItem
                  detail={false}
                  routerLink="/business_units"
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/business_units")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={businessOutline} />
                  <IonLabel>Business Units</IonLabel>
                </IonItem>
              ) : (null)
              }
              {appLoggedIn && userRole === Roles.Admin ? (
                <IonItem
                  detail={false}
                  routerLink="/users"
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/users")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={peopleOutline} />
                  <IonLabel>Users</IonLabel>
                </IonItem>
              ) : (null)
              }
              {appLoggedIn && userRole === Roles.Admin ? (
                <IonItem
                  detail={false}
                  routerLink="/auth_activity"
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/auth_activity")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={fingerPrintOutline} />
                  <IonLabel>Authentication Activity</IonLabel>
                </IonItem>
              ) : (null)
              }
              {appLoggedIn ? (
                <IonItem
                  detail={false}
                  routerLink="/change_password"
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/change_password")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={bagOutline} />
                  <IonLabel>Change password</IonLabel>
                </IonItem>
              ) : (null)
              }
              {appLoggedIn ? (
                <IonItem
                  detail={false}
                  onClick={logout}
                  routerLink='/login/initiativePortal'
                  routerDirection="none"
                  className={
                    location.pathname.startsWith("/logout")
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" icon={logOut} />
                  <IonLabel>Logout</IonLabel>
                </IonItem>
              ) : (
                <IonMenuToggle key="nonLogin" auto-hide="false">
                  <IonItem
                    detail={false}
                    onClick={login}
                    routerLink="/"
                    routerDirection="none"
                    className={
                      location.pathname.startsWith("/login/initiativePortal")
                        ? "selected"
                        : undefined
                    }
                  >
                    <IonIcon slot="start" icon={logIn} />
                    <IonLabel>Login</IonLabel>
                  </IonItem>
                  {(isRegisterPage) && (
                    <IonItem
                      detail={false}
                      onClick={register}
                      routerLink="/"
                      routerDirection="none"
                      className={
                        location.pathname.startsWith("/register")
                          ? "selected"
                          : undefined
                      }
                    >
                      <IonIcon slot="start" icon={pencil} />
                      <IonLabel>Register</IonLabel>
                    </IonItem>
                  )}

                </IonMenuToggle>
              )}
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>
    );
  }
  return (
    null
  )

};

export default connect<{}, UserState, DispatchProps>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    username: state.user.emailAddress,
    systemAdmin: state.user.systemAdmin,
  }),
  mapDispatchToProps: {
    setIsLoggedIn,
    setUserRole,
    setEmailAddress,
    setSystemAdmin
  },
  component: withRouter(Menu),
});
