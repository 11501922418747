import React, { useEffect, useState } from 'react';
import {
    IonCol,
    IonGrid,
    IonRow,
    IonLabel
} from '@ionic/react';
import { Initiative } from '@s360/common-models/initiative';
import { dateConverter } from '../helper/DateConverter';
import { ProgressBar } from 'react-bootstrap';
import "../css/ChildTable.css";



const BasicTable: React.FC<{
    displayedInitiatives: Initiative[] | undefined;

}> = (props) => {
    const [hasProgress, setHasProgress] = useState<boolean>(false);
    const [pages, setPages] = useState<number[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pagedInitiatives, setPagedInitiatives] = useState<Initiative[]>([]);

    useEffect(() => {
        // (Number of Initiatives / 5) = number of pages. It creates an array of numbers (pages) [1, 2, 3, 4, 5, ...] as page numbers.
        const createPages: number[] = Array.from(Array(props.displayedInitiatives? Math.ceil(props.displayedInitiatives.length / 5) : 0), (_,x) => x + 1);
        setPages(createPages);
        setCurrentPage(1);
        console.log(pages);
    }, [props.displayedInitiatives])

    useEffect(() => {
        let maxIndex: number = currentPage * 5;
        let minIndex: number = maxIndex - 5;
        setPagedInitiatives(props.displayedInitiatives!.filter((_initiative, index) => {
            return ((index >= minIndex && index < maxIndex) || showAll)
        }))
    }, [currentPage, props.displayedInitiatives, showAll])

    const getCategoryColor = (category: string) => {
        switch (category) {
            case 'Economy': {
                return 'blue';
            }
            case 'Governance': {
                return 'blue';
            }
            case 'Environment': {
                return 'green';
            }
            case 'Collaboration': {
                return 'purple';
            }
            default: {
                return 'orange';
            }
        }
    };

    const getCategoryLabel = (initiative: Initiative) => {
        return (
            <IonLabel style={{
                position: "absolute",
                width: "7rem",
                height: "1.5rem",
                color: "white",
                borderRadius: 6,
                marginLeft: "-3rem",
                //marginTop: "-0.75rem",
                backgroundColor: getCategoryColor(initiative.category + ''),
            }}>
                {initiative.category === "Economy" ? "Governance" : initiative.category}
            </IonLabel>
        )
    };

    const checkTargetDateDue = (date: Date) => {
        return (new Date() >= date);
    };

    const getTargetDateStyle = (date: Date) => {
        let style = 'dark';
        if (checkTargetDateDue(date)) style = 'danger';
        return style;
    }

    const getTargetDateUI = (initiative: Initiative) => {
        if (!initiative.targetDate) return (<IonLabel style={{ color: '#cccccc' }}>——</IonLabel>);
        let targetDate = new Date(initiative.targetDate);
        return (<IonLabel className="date-label" color={getTargetDateStyle(targetDate)}>{dateConverter(targetDate)}</IonLabel>);
    };

    const getProgressValue = (initiative: Initiative) => {
        let progress: number = 0;
        switch (initiative.trackingStatus) {
            case 'In Progress':
                progress = initiative.progress ? Number(initiative.progress.replace('%', '')) : 0;
                break;
            case 'Implemented':
                progress = 100;
            default:
                break;
        }
        return progress;
    };

    return (
        <IonGrid>
            <IonRow>
                <IonCol size="4" className="heading">Initiative</IonCol>
                <IonCol
                    size="3"
                    className="ion-text-center heading"
                >Category</IonCol>
                <IonCol
                    size="2"
                    className="ion-text-center heading">Date</IonCol>
                <IonCol
                    size="2"
                    className="ion-text-center heading">Progress</IonCol>
                <IonCol
                    size="1"
                    className="ion-text-center heading"></IonCol>
            </IonRow>
            {pagedInitiatives.map(function (initiative, index) {
                if (initiative && !hasProgress) {
                    setHasProgress(true);
                }
                return (<>
                    <IonRow className="ion-margin-bottom">
                        <IonCol size="4">{initiative.title}</IonCol>
                        <IonCol
                            size="3"
                            className="ion-text-center"
                        >{getCategoryLabel(initiative)}</IonCol>
                        <IonCol
                            size="2"
                            className="ion-text-center">{getTargetDateUI(initiative)}</IonCol>
                        <IonCol size="2" className="ion-text-center">
                            <ProgressBar className="prg-bar" variant="success" now={getProgressValue(initiative)} />
                        </IonCol>
                        <IonCol
                            size="1"
                            className="ion-text-center">{getProgressValue(initiative)}%</IonCol>
                    </IonRow>
                </>);
            })}
            <IonRow>
                <IonCol size="8"></IonCol>
                {pages.length > 1 && !showAll && (<IonCol id="show" size="2" onClick={() => {setShowAll(true)}}>
                    Show All
                </IonCol>)}
                {showAll && (<IonCol id="show" size="2" onClick={() => {setShowAll(false)}}>
                    Show Less
                </IonCol>)}
                {pages.length > 1 && !showAll && (<nav>
                    <ul className="pagination">
                        {pages.map((page) => (
                            <li
                                key={page}
                                className={page === currentPage ? "page-item active" : "page-item"}
                            >
                                <a className="page-link" onClick={() => {setCurrentPage(page)}}>
                                    {page}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>)}
            </IonRow>
        </IonGrid>
    );
};

export default BasicTable;