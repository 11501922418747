import { Roles } from '@s360/common-models/roles';
import { combineReducers } from './combineReducers';
import { userReducer } from './user/user.reducer';

export const initialState: AppState = {
  user: {
    userRole: Roles.User,
    isLoggedin: false,
    loading: false,
    emailAddress: ""
  }
};

export const reducers = combineReducers({
  user: userReducer
  // add here when a new reducer comes
});

export type AppState = ReturnType<typeof reducers>;