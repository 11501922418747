import React, { useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonToast,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSearchbar,

} from "@ionic/react";

import InitiativeModal from "../components/InitiativeModal";
import InitiativesList from "../components/InitiativesList";
import { Initiative } from "@s360/common-models/initiative";

import "../components/modal.css";
import { useLocation, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import { ToastColor } from "@s360/common-models/toastColor";
import { APIService } from "../services/APIService";
import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { BusinessUnitService } from './../services/BusinessUnitService';
import { BusinessUnit } from "@s360/common-models/businessUnit";
import { Plugins } from '@capacitor/core';
import { Roles } from "@s360/common-models/roles";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { retrieveInitiatives } from "@s360/common-models/retrieveInitiatives";
import VisitorInitiativeModal from "../components/VisitorInitiativeModal";
import InitiativeExport from "../components/InitiativeExport";
import { goalInitiativeCleaner } from "../helper/goalInitiativeCleaner";


let token: any;
let cid: string;
let decodedToken: any;

const { Storage } = Plugins;


interface OwnProps extends RouteComponentProps { }
interface ConsoleProps extends RouteComponentProps, UserState {
  props: any,
  token: string;
  sessionParams: any;
}

const appSessionCheck = async (): Promise<AuthenticationResponse> => {
  return new Promise<AuthenticationResponse>(async (resolve, reject) => {
    let loginCheckComplete: boolean = false;
    let userType: Roles = Roles.User;
    try {
      let token = Cookies.get('token');
      await Storage.get({ key: 'sessionParams' }).then((sessionData: any) => {
        const sessionObject = JSON.parse(sessionData)
        const sessionDetails: AuthenticationResponse = {
          token: sessionObject.token,
          firstName: sessionObject.firstName,
          lastName: sessionObject.lastName,
          emailAddress: sessionObject.emailAddress,
          companyID: sessionObject.companyID,
          userRole: sessionObject.userRole
        }
        switch (sessionDetails.userRole) {
          case 'visitor':
            userType = Roles.Visitor
            break;
          case 'admin':
            userType = Roles.Admin
            break;
          case 'user':
            userType = Roles.User
            break;
          case 's360':
            userType = Roles.S360
            break;
        }
        resolve(sessionDetails)
      })

    }
    catch (error) {
      console.error(error)
    }
  })
}

const ConsolePage: React.FC<ConsoleProps> = ({
  history,
  userRole
}) => {

  const [showModal, setShowModal] = useState(false);
  const [showIdeaModal, setShowIdeaModal] = useState(false);
  const [isNewInitiative, setIsNewInitiative] = useState(false);
  const [successfulSubmitToast, setSuccessfulSubmitToast] = useState<boolean>(false);
  const [successfulDeleteToast, setSuccessfullDeleteToast] = useState<boolean>(false);
  const [successfulResetToast, setSuccessfullResetToast] = useState<boolean>(false);
  const [displayedInitiatives, setDisplayedInitiatives] = useState<
    Initiative[]
  >([]);
  const businessUnitService: BusinessUnitService = new BusinessUnitService();
  const [businessUnits, setBusinessUnits] = useState<Array<BusinessUnit>>();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<string>('');
  const [businesUnitsCount, setBusinessUnitsCount] = useState<number>();
  const [categoryChanged, setCategoryChanged] = useState<boolean>();
  const [initiativeFetchRequest, setInitiativeFetchRequest] = useState(false);
  const [editInitiative, setEditInitiative] = useState<Initiative>();
  const [lastCategory, setLastCategory] = useState<string>();
  const [sessionDetails, setSessionDetails] = useState<AuthenticationResponse>();

  const location: any = useLocation();
  const [initiativeDeleted, setInitiativeDeleted] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const [inProgressInitiatives, setInProgressInitiatives] = useState<Initiative[]>([]);
  const [newInitiatives, setNewInitiatives] = useState<Initiative[]>([]);
  const [reviewedInitiatives, setReviewedInitiatives] = useState<Initiative[]>([]);
  const [scopedInitiatives, setScopedInitiatives] = useState<Initiative[]>([]);
  const [onHoldInitiatives, setOnHoldInitiatives] = useState<Initiative[]>([]);
  const [implementedInitiatives, setImplementedInitiatives] = useState<Initiative[]>([]);
  const [cancelledInitiatives, setCancelledInitiatives] = useState<Initiative[]>([]);

  const [inProgressShownItems, setInProgressShownItems] = useState<number>(3);
  const [newShownItems, setNewShownItems] = useState<number>(3);
  const [reviewedShownItems, setReviewedShownItems] = useState<number>(3);
  const [scopedShownItems, setScopedShownItems] = useState<number>(3);
  const [onHoldShownItems, setOnHoldShownItems] = useState<number>(3);
  const [implementedShownItems, setImplementedShownItems] = useState<number>(3);
  const [cancelledShownItems, setCancelledShownItems] = useState<number>(3);

  const statusList = [
    "IN PROGRESS",
    "NEW",
    "REVIEWED",
    "SCOPED",
    "ON HOLD",
    "IMPLEMENTED",
    "CANCELLED",
  ]

  const statusLabel = {
    inProgress: "IN PROGRESS",
    new: "NEW",
    reviewed: "REVIEWED",
    scoped: "SCOPED",
    onHold: "ON HOLD",
    implemented: "IMPLEMENTED",
    cancelled: "CANCELLED"
  }

  React.useEffect(() => {
    Storage.get({key: 'reloadInitiatives'}).then(result => {
      if (result.value === 'true') {
        Storage.set({key: 'reloadInitiatives', value: 'false'})
        reloadInitiatives('all');
      }
    })
  })

  React.useEffect(() => {
    appSessionCheck().then(setSessionDetails).catch(error => {
      console.error(error);
    })
    if (sessionDetails?.userRole === Roles.Visitor) {
      window.open(process.env.REACT_APP_VISITOR_URL, '_self')
    }
  }, [setSessionDetails])

  function setShownItems(status: string, items: number) {
    switch (status) {
      case "IN PROGRESS":
        setInProgressShownItems(items);
        break;
      case "NEW":
        setNewShownItems(items);
        break;
      case "REVIEWED":
        setReviewedShownItems(items);
        break;
      case "SCOPED":
        setScopedShownItems(items);
        break;
      case "ON HOLD":
        setOnHoldShownItems(items);
        break;
      case "IMPLEMENTED":
        setImplementedShownItems(items);
        break;
      case "CANCELLED":
        setCancelledShownItems(items);
        break;
    }
  }

  function getShownItems(status: string): number {
    switch (status) {
      case "IN PROGRESS":
        return inProgressShownItems;
      case "NEW":
        return newShownItems;
      case "REVIEWED":
        return reviewedShownItems;
      case "SCOPED":
        return scopedShownItems;
      case "ON HOLD":
        return onHoldShownItems;
      case "IMPLEMENTED":
        return implementedShownItems;
      case "CANCELLED":
        return cancelledShownItems;
      default:
        return 3;
    }
  }

  function getDisplay(status: string): Initiative[] {
    switch (status) {
      case "IN PROGRESS":
        return inProgressInitiatives;
      case "NEW":
        return newInitiatives;
      case "REVIEWED":
        return reviewedInitiatives;
      case "SCOPED":
        return scopedInitiatives;
      case "ON HOLD":
        return onHoldInitiatives;
      case "IMPLEMENTED":
        return implementedInitiatives;
      case "CANCELLED":
        return cancelledInitiatives;
      default:
        return []
    }
  }

  function setDisplay(status: string, initiatives: Initiative[]): void {
    switch (status) {
      case "IN PROGRESS":
        setInProgressInitiatives(initiatives);
        break;
      case "NEW":
        setNewInitiatives(initiatives);
        break;
      case "REVIEWED":
        setReviewedInitiatives(initiatives);
        break;
      case "SCOPED":
        setScopedInitiatives(initiatives);
        break;
      case "ON HOLD":
        setOnHoldInitiatives(initiatives);
        break;
      case "IMPLEMENTED":
        setImplementedInitiatives(initiatives);
        break;
      case "CANCELLED":
        setCancelledInitiatives(initiatives);
        break;
    }
  }

  React.useEffect(() => {
    // In Progress Fetch
    displayInitiatives(lastCategory, inProgressShownItems.toString(), '0', statusLabel.inProgress)
  }, [searchText, selectedBusinessUnit, inProgressShownItems, lastCategory]);

  React.useEffect(() => {
    // New Fetch
    displayInitiatives(lastCategory, newShownItems.toString(), '0', statusLabel.new)
  }, [searchText, selectedBusinessUnit, newShownItems, lastCategory]);

  React.useEffect(() => {
    // Reviewed Fetch
    displayInitiatives(lastCategory, reviewedShownItems.toString(), '0', statusLabel.reviewed)
  }, [searchText, selectedBusinessUnit, reviewedShownItems, lastCategory]);

  React.useEffect(() => {
    // Scoped Fetch
    displayInitiatives(lastCategory, scopedShownItems.toString(), '0', statusLabel.scoped)
  }, [searchText, selectedBusinessUnit, scopedShownItems, lastCategory]);

  React.useEffect(() => {
    // On Hold Fetch
    displayInitiatives(lastCategory, onHoldShownItems.toString(), '0', statusLabel.onHold)
  }, [searchText, selectedBusinessUnit, onHoldShownItems, lastCategory]);

  React.useEffect(() => {
    // Implemented Fetch
    displayInitiatives(lastCategory, implementedShownItems.toString(), '0', statusLabel.implemented)
  }, [searchText, selectedBusinessUnit, implementedShownItems, lastCategory]);

  React.useEffect(() => {
    // Cancelled Fetch
    displayInitiatives(lastCategory, cancelledShownItems.toString(), '0', statusLabel.cancelled)
  }, [searchText, selectedBusinessUnit, cancelledShownItems, lastCategory]);

  React.useEffect(() => {
    retrieveBusinessUnits();
  }, [])

  React.useEffect(() => {
    if (!showModal && location.pathname.endsWith("addEx")) {
      setIsNewInitiative(false);
      setEditInitiative(undefined)
      setShowModal(true);
    }
    if (!showModal && location.pathname.endsWith("addNew")) {
      setEditInitiative(undefined)
      setIsNewInitiative(true);
      setShowModal(true);
    }
    if (!showIdeaModal && location.pathname.endsWith("addIdea")) {
      setShowIdeaModal(true);
    }
  },[location.pathname])

  const reloadInitiatives = (status: string): void => {
    if (status == 'all') {
      statusList.map(trackingStatus => {
        retrieveInitiativesV2(lastCategory, getShownItems(trackingStatus.toUpperCase()).toString(), '0', trackingStatus.toUpperCase())
      })
    } else {
      retrieveInitiativesV2(lastCategory, getShownItems(status.toUpperCase()).toString(), '0', status.toUpperCase())
    }
  }

  const allInitiatives = (): Initiative[] => {
    return (
      inProgressInitiatives.concat(
        newInitiatives,
        reviewedInitiatives,
        scopedInitiatives,
        onHoldInitiatives,
        implementedInitiatives,
        cancelledInitiatives,
      )
    )
  }

  const retrieveBusinessUnits = () => {
    getObject().then(value => {
      businessUnitService.getAllBusinessUnits().then((businessUnitsArray: Array<BusinessUnit>) => {
        setBusinessUnits(businessUnitsArray);
        setBusinessUnitsCount(businessUnits?.length);
      }).catch(error => {
        console.error(error);
      });
    })
  }

  const displayInitiatives = (
    category?: string,
    items?: string,
    offset?: string,
    status?: string,) => {
    if (category !== lastCategory) {
      setCategoryChanged(true);
    }
    if (location.pathname.endsWith('environment')) {
      category = "Environment";
    }
    if (location.pathname.endsWith('society')) {
      category = "Society";
    }
    if (location.pathname.endsWith('collaboration')) {
      category = "Collaboration";
    }
    if (location.pathname.endsWith('economy')) {
      category = "Economy";
    }
    retrieveInitiativesV2(category, items, offset, status);
  };

  const retrieveInitiativesV2 = (
    category?: string,
    items?: string,
    offset?: string,
    status?: string,
  ) => {
    const apiService = new APIService();
    setLastCategory(category!);

    getObject().then(value => {
      let params: retrieveInitiatives = {
        category: category,
        items: items,
        offset: offset,
        searchQuery: searchText,
        businessUnit: selectedBusinessUnit,
        status: status
      }
      apiService.retrieveInitiatives(params).then((result) => {
        setDisplay(status || '', result.body.initiatives);
        setInitiativeFetchRequest(true);
      }).catch(error => {
        console.error(error)
      });
    })
  };



  const isLoggedIn = (): boolean => {
    try {
      const authObject: any = getObject();
      if (location.state.firstName || authObject.firstName) {
        return true;
      }
      else { return false }
    }
    catch (error) {
      return false;
    }
  }

  

  async function getObject(): Promise<AuthenticationResponse> {
    return new Promise<AuthenticationResponse>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        resolve(JSON.parse(res.value!))
      }).catch(error => {
        reject(error);
      })
    })
  }


  function closeModal() {
    if(!location.pathname.endsWith('/console')){
      history.push('/console')
    }
    setShowModal(false);
    setShowIdeaModal(false);
  }

  const ideaSubmitOnSuccess = (): void => {
    setShowIdeaModal(false);
    setSuccessfulSubmitToast(true);
    closeModal();
  }

  function showSubmitToast() {
    reloadInitiatives('all');
    setSuccessfulSubmitToast(true);
  }

  function openEditModal(initiative: Initiative) {
    setEditInitiative(initiative);
    setShowModal(true);
  }

  function delInitiative(initiative: Initiative) {
    const apiService = new APIService();
    goalInitiativeCleaner(initiative.id!);
    apiService.deleteInitiative(initiative).then((value) => {
      setSuccessfullDeleteToast(true);
      reloadInitiatives(initiative.trackingStatus);
      setInitiativeDeleted(true);
    })
  }

  function toastMessage(): string {
    let toastMessage: string = "";
    if (successfulDeleteToast) {
      toastMessage = `${location.pathname.endsWith("addEx") ? "Initiative" : "Idea"} has been deleted`;
    }
    else if (successfulResetToast) {
      toastMessage = "Password has been reset"
    }
    else if (successfulSubmitToast) {
      toastMessage = `${location.pathname.endsWith("addEx") ? "Initiative" : "Idea"} is submitted Successfully`
    }
    return toastMessage;
  }

  function toastColor(): ToastColor {
    let toastColor: ToastColor = ToastColor.Failure;
    if (successfulDeleteToast) {
      toastColor = ToastColor.Delete;
    }
    else if (successfulResetToast) {
      toastColor = ToastColor.Success;
    }
    else if (successfulSubmitToast) {
      toastColor = ToastColor.Success;
    }
    return toastColor;
  }

  try {
    token = Cookies.get('token');
    cid = Cookies.get('cid')!;
    decodedToken = jwt_decode(token);
  }
  catch (error) {
    console.error(error)
  }

  finally {
    if (typeof decodedToken === 'object' && decodedToken.hasOwnProperty('email')) {
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar color="primary">
              <IonGrid>
                <IonRow>
                  <IonCol><IonTitle size="large" style={{ color: 'white', marginTop: "8px" }}>Initiative List</IonTitle></IonCol>
                  <IonCol size="1.75">
                    <InitiativeExport />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonSearchbar color="black" value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
                </IonCol>
                <IonCol size="3">
                    <IonSelect
                      class="border shadow-sm"
                      style={{
                        marginTop: "0.45rem",
                        height: "75%"
                      }}
                      interface="popover"
                      placeholder="Select Business Unit"
                      id="ionSelect-bu"

                      onClick={() => {retrieveBusinessUnits()}}
                      onIonChange={e => { setSelectedBusinessUnit(e.detail.value) }}>

                      <IonSelectOption value="all">All</IonSelectOption>
                      {
                        businessUnits?.map(function (unit, index) {
                          return <IonSelectOption key={index} value={unit.businessUnitID}>{unit.businessUnitName}</IonSelectOption>
                        })
                      }
                    </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginTop: 6 }}>
                {statusList.map(function (status) {
                  if (getDisplay(status).length > 0) {
                    return (
                      <InitiativesList
                        isLoggedIn={isLoggedIn}
                        displayedInitiatives={getDisplay(status)}
                        userIsAdmin={userRole === Roles.Admin}
                        editAction={openEditModal}
                        deleteAction={delInitiative}
                        initiativeFetchRequest={initiativeFetchRequest}
                        status={status}
                        shownItems={getShownItems(status)}
                        setShownItems={setShownItems}
                      ></InitiativesList>)
                  }
                })}
                {allInitiatives().length <= 0 && (<div className="alert alert-secondary blankInitiatives" role="alert">
                  No category selected or No Initiatives found with the search keywords, selected category, or business unit
                </div>)}
              </IonRow>
            </IonGrid>
            <IonModal
              isOpen={showModal}
              onDidDismiss={() => setShowModal(false)}
              backdropDismiss={false}
              cssClass="initiative-modal"
            >
              <InitiativeModal
                closeAction={closeModal}
                showToast={showSubmitToast}
                isNewInitiative={isNewInitiative}
                editTargetInitiative={editInitiative}
              ></InitiativeModal>
            </IonModal>
            <IonModal
                isOpen={showIdeaModal}
                onDidDismiss={() => setShowIdeaModal(false)}
                backdropDismiss={false}
                cssClass="initiative-modal"
              >
                <VisitorInitiativeModal
                  closeAction={closeModal}
                  successAction={ideaSubmitOnSuccess}
              >
                </VisitorInitiativeModal>
            </IonModal>
            <IonToast
              isOpen={successfulSubmitToast || successfulDeleteToast || successfulResetToast}
              onDidDismiss={() => {
                setSuccessfulSubmitToast(false);
                setSuccessfullDeleteToast(false);
                setSuccessfullResetToast(false)
              }
              }
              message={toastMessage()}
              duration={1000}
              position="top"
              color={toastColor()}
            />
          </IonContent>
        </IonPage>
      )
    }
    else {
      window.open(process.env.REACT_APP_LOGIN_URL!, "_self");
    }
  }

  return (
    <></>
  )

};

export default connect<{}, UserState, {}>({

  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    username: state.user.emailAddress,
  }),
  component: withRouter(ConsolePage),
});
