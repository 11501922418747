import { KeywordType } from "@s360/common-models/keyword-type";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";


export class KeywordTranslationService {
    private readonly keywordTranslationUrl: string = process.env.REACT_APP_KEYWORD_TRANSLATION_URL!

    private authorizationToken: string;

    constructor () {
        this.authorizationToken = this.getAuthorizationToken();
    }

    private getAuthorizationToken = (): string => {
        let token: string = Cookies.get('token') + '';
        return token;
      }

    public translateKeyword (keywordType: KeywordType, keyword: string): Promise<string> {
        console.log(`[KeywordTranslationService][translateKeyword] starting`)
        return new Promise<string>((resolve, reject) => {
            console.log(`[KeywordTranslationService][translateKeyword] setting config`)

            const config = {
                headers: {
                    'Content-Type': `application/json`,
                    'authorization': `Bearer ${this.authorizationToken}`
                }
            }

            console.log(`[KeywordTranslationService][translateKeyword] creating post ${keywordType}, ${keyword}`)

            axios.post(`${this.keywordTranslationUrl}`, {keywordType: keywordType, keyword: keyword}, config)
                .then((response: AxiosResponse) => {
                    console.log(`[KeywordTranslationService][translateKeyword] response status ${response.status}`);
                    if(response.status === 200) {
                        resolve(response.data.value!);
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    reject(error);
                });
        });
    }
}