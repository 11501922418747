import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonTextarea,
  IonItem,
  IonCheckbox,
  IonPopover,
} from "@ionic/react";
import { closeOutline, helpCircleOutline } from "ionicons/icons";
import { Initiative } from "@s360/common-models/initiative";


import { allMyGoals } from "@s360/common-models/constants";
import Goal from "@s360/common-models/goal";
import Indicator from "@s360/common-models/indicator";
import Target from "@s360/common-models/target";
import './InitiativeInfo.css'

const InitiativeInfo: React.FC<{
  closeAction: () => void;
  initiative: Initiative;
}> = (props) => {
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [initiativeInfo, setInitiativeInfo] = useState<Initiative>(props.initiative)
  const [popoverContent, setPopoverContent] = useState<string>("");
  const [isMulti, setIsMulti] = useState<boolean>();
  const [categoryValue, setCategoryValue] = useState<string>(
    props.initiative?.uniqueCategories ?
      props.initiative?.uniqueCategories.length <= 1 ?
        props.initiative?.uniqueCategories.toString()
        : "Multi"
      : ""
  )

  useEffect(()=>{
    console.log(props.initiative)
  },[props.initiative])
  
  const generateOptions = (options: any[]) => {

    let optionsItem = options.map((option, index) => (
      <IonItem className="small-font" key={option.name}>
        <IonCheckbox
          key={option.name}
          checked={true}
          disabled={true}
          className={"extra-right-margins"}
        />
        {option.name}
        <IonButton
          className="see-through-button"
          onClick={(e) => {
            setShowPopover({ open: true, event: e.nativeEvent });
            setPopoverContent(option.name + ": " + option.desc);
          }}
        >
          <IonIcon icon={helpCircleOutline}> </IonIcon>
        </IonButton>
      </IonItem>
    ));

    return <div>{optionsItem}</div>;
  };

  function findGoals(checkedGoals: string[], goals: Goal[]): Goal[] {
    let result: Goal[] = [];
    checkedGoals?.forEach((item) => {
      goals?.forEach((goal) => {
        if (goal.name === item) {
          result.push(goal);
        }
      });
    });
    return result;
  }
  function findTargets(checkedTargets: string[], goals: Goal[]): Target[] {
    let result: Target[] = [];
    checkedTargets?.forEach((item) => {
      goals?.forEach((goal) => {
        if (goal.name === item.substr(0, item.indexOf("."))) {
          goal.targets.forEach((target) => {
            if (target.name === item) {
              result.push(target);
            }
          });
        }
      });
    });
    return result;
  }

  function findIndicators(
    checkedIndicators: string[],
    goals: Goal[]
  ): Indicator[] {
    let result: Indicator[] = [];
    checkedIndicators?.forEach((item) => {
      goals?.forEach((goal) => {
        if (goal.name === item.substr(0, item.indexOf("."))) {
          goal.targets.forEach((target) => {
            if (target.name === item.substr(0, item.lastIndexOf("."))) {
              target.indicators?.forEach((indicator) => {
                if (indicator.name === item) {
                  result.push(indicator);
                }
              });
            }
          });
        }
      });
    });
    return result;
  }

  return (

    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{props.initiative?.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.closeAction()}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding unscrollable">
        <IonGrid className="max-width">
          <IonRow>
            <IonCol>
              <IonRow>
                <IonCol>
                  <IonLabel position="fixed">
                    <h2>Status</h2>
                  </IonLabel>
                  <IonInput
                    value={props.initiative?.trackingStatus}
                    className={props.initiative?.trackingStatus === "Cancelled" ? "black-border gray-select" : "black-border"}
                    type={"text"}
                    readonly={true}
                  ></IonInput>
                </IonCol>
                {props.initiative?.trackingStatus === "In Progress" && (
                  <IonCol>
                    <IonLabel position="fixed">
                      <h2>Progress</h2>
                    </IonLabel>
                    <IonInput
                      value={props.initiative?.progress}
                      className="black-border"
                      type={"text"}
                      readonly={true}
                    ></IonInput>
                  </IonCol>
                )}
                {props.initiative?.businessUnitID && props.initiative?.businessUnitName && (
                  <IonCol>
                    <IonLabel position="fixed">
                      <h2>Business Unit</h2>
                    </IonLabel>
                    <IonInput
                      value={props.initiative?.businessUnitName}
                      className="black-border"
                      type="text"
                      readonly={true}
                    ></IonInput>
                  </IonCol>
                )}
                {props.initiative?.ownerEmailAddress && (
                  <IonCol>
                    <IonLabel position="fixed">
                      <h2>Owner</h2>
                    </IonLabel>
                    <IonInput
                      value={props.initiative?.ownerFullName || props.initiative?.ownerEmailAddress}
                      className="black-border"
                      type="text"
                      readonly={true}
                    ></IonInput>
                  </IonCol>
                )}
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="fixed">
                    <h2>Initiative Objective</h2>
                  </IonLabel>
                  <IonTextarea
                    readonly={true}
                    rows={2}
                    value={props.initiative?.objective}
                    className="black-border"
                  ></IonTextarea>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="fixed">
                    <h2>Description</h2>
                  </IonLabel>
                  <IonTextarea
                    readonly={true}
                    rows={10}
                    value={props.initiative?.description}
                    className="black-border"
                  ></IonTextarea>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel>Categories</IonLabel>
              <IonInput
                value={categoryValue}
                className="black-border"
                type="text"
                readonly={true}>

              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="max-width">
              <IonLabel position="fixed">
                <h2>SDGs</h2>
              </IonLabel>
              <form className="scrollable black-border">
                {generateOptions(findGoals(props.initiative?.goals, allMyGoals))}
              </form>
            </IonCol>
            <IonCol className="max-width">
              <IonLabel position="fixed">
                <h2>Targets</h2>
              </IonLabel>
              <form className="scrollable black-border">
                {generateOptions(
                  findTargets(props.initiative?.targets, allMyGoals)
                )}
              </form>
            </IonCol>
            <IonCol className="max-width">
              <IonLabel position="fixed">
                <h2>Indicators</h2>
              </IonLabel>
              <form className="scrollable black-border">
                {generateOptions(
                  findIndicators(props.initiative?.indicators, allMyGoals)
                )}
              </form>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel position="fixed">
                <h2>Note</h2>
              </IonLabel>
              <IonTextarea
                readonly={true}
                rows={4}
                value={props.initiative?.note}
                className="black-border"
              ></IonTextarea>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonPopover
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={(e) =>
            setShowPopover({ open: false, event: undefined })
          }
        >
          <p className="light-padding">{popoverContent}</p>
        </IonPopover>
      </IonContent>
    </>
  );
};

export default InitiativeInfo;
