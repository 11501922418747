import React, { useState } from "react";
import { Initiative } from "@s360/common-models/initiative";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./ReportList.css"
import InitiativeInfo from './InitiativeInfo/InitiativeInfo';
import ReportHorizontalStepper from "./ReportHorizontalStepper";
import { IonModal } from '@ionic/react';
import { stringShortenerEllipsis } from "../helper/StringShortenerEllipsis";
import DisplayTable from "./DisplayTable";

const ReportProgressList: React.FC<{
  displayedInitiatives: Initiative[];
}> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [initiative, setInitiative] = useState<Initiative>();

  const maxTitleLength: number = 50;
  const reportTableId: string = "report-progress";

  const headerLabels: string[] = [
    "INITIATIVES IN PROGRESS", "",
  ]
  
  const columnWidths: string[] = [
    "30%", "70%",
  ]

  const columnIds: string[] = [
    "report-title", "report-progress",
  ]

  const closeModal = () => {
    setShowModal(false);
  };

  const getReportHorizontalStepper = (initiative: Initiative): React.ReactElement => {
    return (
        <ReportHorizontalStepper value={initiative.trackingStatus} progress={initiative.progress ? initiative.progress : ''}></ReportHorizontalStepper>
    )
  }

  const getIdTitle = (initiative: Initiative): React.ReactElement => {
    return (
      <div onClick={() => {
        setShowModal(true);
        setInitiative(initiative);
      }}>
        <span className="titleToolTipText">
          {stringShortenerEllipsis(initiative.title, maxTitleLength)}
        </span>
      </div>
    )
  }

  return (
    <>
      <DisplayTable
        items={props.displayedInitiatives}
        headers={headerLabels}
        rowInfoGetters={[
          getIdTitle,
          getReportHorizontalStepper,
        ]}
        customWidth={columnWidths}
        customColumnId={columnIds}
        tableId={reportTableId}
      ></DisplayTable>
      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        backdropDismiss={false}
        cssClass="initiative-modal"
      >
        <InitiativeInfo
          closeAction={closeModal}
          initiative={initiative!}
        ></InitiativeInfo>
      </IonModal>
    </>
  );
};

export default ReportProgressList;
