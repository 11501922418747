import React, { useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonNote,
  IonTextarea,
  IonLabel,
  IonText,
  IonInput,
} from "@ionic/react";

import { closeOutline } from "ionicons/icons";

import { BusinessUnit } from "@s360/common-models/businessUnit";
import { BusinessUnitService } from "../services/BusinessUnitService";
import { HttpStatus } from "@s360/common-models/httpStatus";


const labelTexts = {
  businessUnitName: "Business Unit Name",
  businessUnitDescription: "Description (Optional)"
};

const errorMessages = {
  businessUnitName: "Business Unit Name is required",
  businessUnitDescription: "Description is too large",
};

const BusinessUnitForm: React.FC<{
  closeAction: () => void;
  showToast: () => void;
  showErrorToast: (msg: string) => void;
  businessUnit?: BusinessUnit;
}> = (props) => {

  const businessUnitService = new BusinessUnitService();
  const [businessUnitName, setBusinessUnitName] = useState<string>(props.businessUnit ? props.businessUnit.businessUnitName : "");
  const [businessUnitDescription, setBusinessUnitDescription] = useState<string>(props.businessUnit ? props.businessUnit.businessUnitDescription! : "");

  const [isEditMode, setIsEditMode] = useState<boolean>(props.businessUnit ? true : false);
  const [businessUnitNameErrorMessage, setBusinessUnitNameErrorMessage] = useState<string>("");
  const [businessUnitDescriptionErrorMessage, setbusinessUnitDescriptionErrorMessage] = useState<string>("");

  const [showClearAlert, setClearAlert] = useState(false);
  const [showCloseAlert, setCloseAlert] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  React.useEffect(() => {
    if (submitDisabled) {
      checkInputs();
    }
  }, [
    businessUnitName,
    businessUnitDescription
  ]);

  const submitInputs = async () => {
   
      if (businessUnitName.trim()) {
        let newBusinessUnit : BusinessUnit = {
          companyID: props.businessUnit?.companyID || '',
          businessUnitID: props.businessUnit?.businessUnitID || '',
          businessUnitName: businessUnitName,
          businessUnitDescription: businessUnitDescription,
        };
        if (isEditMode) {
          businessUnitService.updateBusinessUnit(newBusinessUnit).then((result) => {
            if (result.httpStatus === HttpStatus.OK) {
              props.closeAction();
              props.showToast();
            } else {
              props.showErrorToast("Failed to update business unit");
            }
          }).catch(err => {
            console.error(err);
            props.showErrorToast(err);
        });;
        } else {
          businessUnitService.addBusinessUnit(newBusinessUnit).then((result) => {
          if (result.httpStatus === HttpStatus.OK) {
            props.closeAction();
            props.showToast();
          } else {
            props.showErrorToast("Failed to add business unit");
          }
        }).catch(err => {
            console.error(err);
            props.showErrorToast(err);
        });
      }
    }
  };

  //Set error messages and disable/enable submit button
  const checkInputs = () => {
    businessUnitName.trim()
      ? setBusinessUnitNameErrorMessage("")
      : setBusinessUnitNameErrorMessage(errorMessages.businessUnitName);

    if (businessUnitName.trim()) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const retrieveInputs = () => {
    checkInputs();
    submitInputs();
  };

  const clearInputs = () => {
    setBusinessUnitName("");
    setBusinessUnitDescription("");
  };

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            {isEditMode ?  "Edit Business Unit" : "Add Business Unit"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={submitDisabled}
              onClick={() => retrieveInputs()}
            >
              {isEditMode ?  "Update" : "Create"}
            </IonButton>
            <IonButton onClick={() => setClearAlert(true)}>Clear</IonButton>
            <IonButton onClick={() => setCloseAlert(true)}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding scrollable">
        <IonGrid className="max-width">
          <IonRow>
            <IonCol>
              <IonLabel position="fixed">
                <h2>
                  {labelTexts.businessUnitName}
                  <IonText color="danger">*</IonText>
                </h2>
                <IonNote color="danger">{businessUnitNameErrorMessage}</IonNote>
              </IonLabel>
              <IonInput
                value={businessUnitName}
                onIonChange={(e) => setBusinessUnitName(e.detail.value!.trim())}
                maxlength={50}
                className="black-border"
              ></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel position="fixed">
                <h2>
                  {labelTexts.businessUnitDescription}
                </h2>
                <IonNote color="danger">{businessUnitDescriptionErrorMessage}</IonNote>
              </IonLabel>
              <IonTextarea
                value={businessUnitDescription}
                onIonChange={(e) => setBusinessUnitDescription(e.detail.value!.trim())}
                maxlength={500}
                rows={Math.max(1, businessUnitDescription.length/40)}
                className="black-border"
              ></IonTextarea>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonAlert
          isOpen={showClearAlert}
          backdropDismiss={false}
          onDidDismiss={() => setClearAlert(false)}
          header={"Are you sure you want to clear your inputs?"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                clearInputs();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonAlert
          isOpen={showCloseAlert}
          backdropDismiss={false}
          onDidDismiss={() => setCloseAlert(false)}
          header={"Are you sure you don't want to submit a Business Unit?"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                props.closeAction();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </>
  );
};

export default BusinessUnitForm;
