import { Session } from '@s360/common-models/session';
import Cookies from 'js-cookie';

export const setSession = (session: Session) => {
    Cookies.set('session', session, { domain: process.env.REACT_APP_DOMAIN });
}

export const activeSession = (activeSession: boolean) => {
    let session : Session = JSON.parse(Cookies.get('session')!);
    session = {...session, activeSession : activeSession}
    Cookies.set('session', session, { domain: process.env.REACT_APP_DOMAIN });
}

export const clearSession = () => {
   const session: Session = {
       companyID: null,
       token: null,
       userRole: null,
       loggedIn: false,
       activeSession: false
   }
   Cookies.set('session', session, { domain: process.env.REACT_APP_DOMAIN });
}
