import React from "react";
import { IonPage, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonText,  } from "@ionic/react";
import { RouteComponentProps } from 'react-router-dom';
import { setIsLoggedIn, setUserRole, setEmailAddress } from "../data/user/user.actions";
import { connect } from '../data/connect';
import '../css/app.scss';

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUserRole: typeof setUserRole;
  setEmailAddress: typeof setEmailAddress;
}

interface LoginProps extends OwnProps,  DispatchProps { }


const Success: React.FC<any> = () => {

  return (
    <IonPage id="success-page">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Success!</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonText>
            <p>Password information has been sent to registered user's email.</p>
            </IonText>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
    mapDispatchToProps: {
      setIsLoggedIn,
      setUserRole,
      setEmailAddress
    },
    component: Success
  })
