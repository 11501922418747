import { IonLabel } from "@ionic/react";
import React from "react";
import { Table } from "react-bootstrap";
import "../css/DisplayTable.css";


const DisplayTable: React.FC<{
  items: any[];
  headers: string[];
  rowInfoGetters: ((item: any) => React.ReactElement)[];
  tableId?: string;
  customTextColor?: string;
  customBackgroundColor?: string;
  customWidth?: string[];
  customColumnId?: string[];
  customColumnHeaderId?: string[];
  customTitleStyle?: boolean;
  popupChildRow?: boolean;
  popupChildRowID?: number | string;
  popupChildRowElement?: React.ReactElement;
}> = (props) => {
  /**
  * Create a table
  * All default CSS is stored in DisplayTable.css
  * You can create a Custom CSS file and import it to parent component
  * The following IDs are used for each tags
  * Table id = table-container OR <tableId>-container
  * thead id = table-header-container OR <tableId>-header-container
  * tbody id = table-body OR <tableId>-body
  * th id = table-title OR <tableId>-title OR <customColumnId>-title OR <customColumnHeaderId>-title
  * tr id = table-row OR <tableId>-row OR <customColumnId>
  * td id = table-data OR <tableId>-data OR <customColumnId>-data
  * @param items - The list of any object to be displayed on a table.
  * @param headers - The first row/headers of the table.
  * @param rowInfoGetters - A list of getter functions (same order as header) that returns a React.ReactElement (information) from the items
  * @param tableId - An optional id to be attached to tags as their ID
  * @param customTextColor - An optional color for the first title in the table
  * @param customBackgroundColor - An optional color for the first title in the table
  * @param customWidth - An optional width option per column (same order as header)
  * @param customColumnId - An optional customized ID for each column (same order as header)
  * @param customColumnHeaderId - An optional customized ID for each column header (same order as header), this is a priority over customColumnId
  */

  const tableName: string = 'table';

  const customTextColor = props.customTextColor ? props.customTextColor: 'white';
  const customBackgroundColor = props.customBackgroundColor ? props.customBackgroundColor : 'green';


  const checkParams = (): boolean => {
    const customWidth = props.customWidth ? props.customWidth.length : props.headers.length;
    const customColumnId = props.customColumnId ? props.customColumnId.length : props.headers.length;
    const customColumnHeaderId = props.customColumnHeaderId ? props.customColumnHeaderId.length : props.headers.length;
    const size = props.headers.length;
    return (size == props.rowInfoGetters.length &&
            size == customWidth &&
            size == customColumnId &&
            size == customColumnHeaderId)
  }

  const generateItemRows = (rowInfoGetters: ((item: any) => React.ReactElement)[]): React.ReactElement => {
    return (
      <tbody id={`${props.tableId ? props.tableId : tableName}-body`}>
          {props.items.map(item => {
            return (
              <>
              <tr id={`${props.tableId ? props.tableId : tableName}-row`}>
                {rowInfoGetters.map((getter, index) => {
                  return (
                    <td
                      id={`${props.customColumnId ?
                              props.customColumnId[index] :
                              props.tableId ? props.tableId : tableName}-data`}
                      style={
                        props.customWidth ? {
                          "width": props.customWidth[index]
                        } : {}
                      }
                    >{getter(item)}</td>
                  )
                })}
              </tr>
                {props.popupChildRow && props.popupChildRowID === item.id && (<div id="popup-child-row">{props.popupChildRowElement}</div>)}
              </>
            )
          })}
      </tbody>
    )
  }

  const getTableTitleStyle = (header: string): {} => {
    return {
      color: customTextColor,
      backgroundColor: customBackgroundColor,
      textAlign: "center",
      fontSize: "1.1rem",
      width: `${Math.max(8.3, header.length*0.6)}rem`,
      height: "1.8rem",
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      marginTop: '-1.55rem',
      position: 'absolute',
    }
  }

  const generateHeaders = (headers: string[]): React.ReactElement => {
    return (
      <thead id={`${props.tableId ? props.tableId : tableName}-header-container`}>
        <tr>
          {headers.map((header, index) => {
            return (
              <th
                id={`${props.customColumnHeaderId ? props.customColumnHeaderId[index] :
                          props.customColumnId ? props.customColumnId[index] :
                          props.tableId ? props.tableId : tableName}-title`}
                style={index === 0 ? props.customWidth ? {} : {width: `${15 + header.length}rem`} : {}}>
                <IonLabel 
                  style={(index === 0 && !props.customTitleStyle) ? getTableTitleStyle(header) : {}}
                >
                  {header}
                </IonLabel>
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  const generateTable = (): React.ReactElement => {
    return (
      <>
        {props.items.length > 0 &&
        checkParams() &&
        (<Table borderless id={`${props.tableId ? props.tableId : tableName}-container`}>
          {generateHeaders(props.headers)}
          {generateItemRows(props.rowInfoGetters)}
        </Table>)}
        {!checkParams()}
      </>
    )
  }

  return (
    <>
      {generateTable()}
    </>
  )
}

export default DisplayTable;