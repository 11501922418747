import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Idea } from "@s360/common-models/idea";
import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import '../css/IdeaStats.css';
import { IdeaService } from "../services/IdeaService";

const IdeaStats: React.FC<{
  ideaList: Idea[];
}> = (props) => {
  const [totalIdeas, setTotalIdeas] = useState<number>(0);
  const [monthIdeas, setMonthIdeas] = useState<number>(0);
  const [acceptedIdeas, setAcceptedIdeas] = useState<number>(0);
  const [acceptedMonthIdeas, setAcceptedMonthIdeas] = useState<number>(0);

  const ideaService = new IdeaService();

  useEffect(() => {
    ideaService.getTotalIdeas().then((result) => {
      setTotalIdeas(result.body || 0);
    });
  }, [props.ideaList])

  useEffect(() => {
    ideaService.getCurrentMonthTotalIdeas().then((result) => {
      setMonthIdeas(result.body || 0);
    });
  }, [props.ideaList])

  useEffect(() => {
    ideaService.getTotalAcceptedIdeas().then((result) => {
      setAcceptedIdeas(result.body || 0);
    });
  }, [props.ideaList])

  useEffect(() => {
    ideaService.getCurrentMonthTotalAcceptedIdeas().then((result) => {
      setAcceptedMonthIdeas(result.body || 0);
    });
  }, [props.ideaList])

  return (
    <IonGrid>
      <IonRow>
        <IonCol id="idea-stats-card">
          <IonRow id="idea-stats-row">
            <IonLabel id="idea-counter">
              {totalIdeas}
            </IonLabel>
          </IonRow>
          <IonRow id="idea-stats-row">
            <IonLabel id="idea-counter-label">
              TOTAL IDEAS
            </IonLabel>
          </IonRow>
        </IonCol>
        <IonCol id="idea-stats-card">
          <IonRow id="idea-stats-row">
            <IonLabel id="idea-counter">
              {monthIdeas}
            </IonLabel>
          </IonRow>
          <IonRow id="idea-stats-row">
              <IonLabel id="idea-counter-label">
                THIS MONTH
              </IonLabel>
          </IonRow>
        </IonCol>
        <IonCol id="idea-stats-card">
          <IonRow id="idea-stats-row">
            <IonLabel id="idea-counter">
              {acceptedIdeas}
            </IonLabel>
          </IonRow>
          <IonRow id="idea-stats-row">
            <IonLabel id="idea-counter-label">
              ACCEPTED IDEAS
            </IonLabel>
          </IonRow>
        </IonCol>
        <IonCol id="idea-stats-card">
          <IonRow id="idea-stats-row">
            <IonLabel id="idea-counter">
              {acceptedMonthIdeas}
            </IonLabel>
          </IonRow>
          <IonRow id="idea-stats-row">
            <IonLabel id="idea-counter-label">
              ACCEPTED IDEAS THIS MONTH
            </IonLabel>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default IdeaStats;
