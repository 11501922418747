import { Roles } from '@s360/common-models/roles';
import React from 'react';
import { ActionType } from '../types';
import { UserState } from './user.state';

export const setLoading = (isLoading: boolean) => ({
  type: 'set-user-loading',
  isLoading
} as const);

export const setData = (data: Partial<UserState>) => ({
  type: 'set-user-data',
  data
} as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setEmailAddress());
};

export const setIsLoggedIn = (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
  return ({
    type: 'set-is-loggedin',
    loggedIn
  } as const)
};

export const setEmailAddress = (emailAddress?: string) => async (dispatch: React.Dispatch<any>) => {
  return ({
    type: 'set-emailAddress',
    emailAddress
  } as const);
};

export const setUserRole = (userRole: Roles) => async (dispatch: React.Dispatch<any>) => {
  return ({
    type: 'set-user-role',
    userRole
  } as const);
};

export const setCurrentPassword = (currentPassword: string)=> async (dispatch: React.Dispatch<any>) => {
  return ({
    type: 'set-current-password',
    currentPassword
  } as const)
};

export const setNewPassword = (newPassword: string)=> async (dispatch: React.Dispatch<any>) => {
  return ({
    type: 'set-new-password',
    newPassword
  } as const)
};

export const setRetypePassword = (retypePassword: string) => async(dispatch: React.Dispatch<any>)=> {
  return ({
    type: 'set-retype-password',
    retypePassword
  } as const)
}

export const setSystemAdmin = (systemAdmin: boolean) => async (dispatch: React.Dispatch<any>) => {
  return ({
    type: 'set-system-admin',
    systemAdmin
  } as const);
}

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setEmailAddress>
  | ActionType<typeof setUserRole>
  | ActionType<typeof setSystemAdmin>
