import {
  GeneralHttpResponse,
  RetrieveInitiativesResponse,
  GetReportContentResponse
} from "@s360/common-models/apiResponses";
import {
  InitiativeCountBody
} from "@s360/common-models/getInitiativeCountResponse";
import axios, { AxiosResponse } from "axios";

import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { Plugins } from '@capacitor/core';
import { Initiative } from "@s360/common-models/initiative";

import { HttpResponse } from "@s360/common-models/httpResponse";
import { ResetPassword } from '@s360/common-models/resetPassword';
import { RetrieveInitiativesRequest } from '@s360/common-models/retrieveInitiativeRequest';
import { RetrieveAllBusinessUnitsRequest } from '@s360/common-models/retrieveAllBusinessUnitsRequest';
import { RetrieveAllBusinessUnitsResponse } from '@s360/common-models/retrieveAllBusinessUnitsResponse';
import { retrieveInitiatives } from "@s360/common-models/retrieveInitiatives";

const { Storage } = Plugins;

export class APIService {
  private sessionParams: AuthenticationResponse;

  constructor() {
    this.sessionParams = {
      token: '',
      firstName: '',
      lastName: '',
      companyID: '',
      emailAddress: '',
      userRole: ''
    }
  }

  private async getObject(): Promise<AuthenticationResponse> {
    return new Promise<AuthenticationResponse>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        resolve(JSON.parse(res.value!))
      }).catch(error => {
        reject(error);
      })
    })
  }
  
  public retrieveInitiatives(params?: retrieveInitiatives): Promise<RetrieveInitiativesResponse> {
    return new Promise(async (resolve, reject) => {
      const retrieveInitiativesURL =
        process.env.REACT_APP_API_RETRIEVE_INITIATIVES_URL ||
        "";
      const urlParams: string =
        `?query=${params?.searchQuery || ''}` + 
        `${params?.businessUnit ? `&businessUnit=${params?.businessUnit}` : ''}` +
        `${params?.items ? `&items=${params?.items}` : ''}` +
        `${params?.offset ? `&offset=${params?.offset}` : ''}` +
        `${params?.status ? `&status=${params?.status}` : ''}` +
        `${params?.isOverDue ? `&isOverdue=${params?.isOverDue}` : ''}`
      await this.getObject().then(async (value) => {
        let content: RetrieveInitiativesRequest = {
          companyID: value.companyID
        };
        if (params?.category !== 'All') {
          content.category = params?.category;
        }
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`,
          },
        }
        try {
          axios.post(retrieveInitiativesURL + urlParams, content, config).then(submitRes => {
            const responseData = JSON.parse(submitRes.data)
            let APIResponse = JSON.parse(responseData.body);
            const retrieveResponse: RetrieveInitiativesResponse = {
              statusCode: APIResponse.statusCode,
              body: APIResponse
            }
            resolve(retrieveResponse);
          }).catch(error => {
            reject(error);
          })
        }
        catch (error) {
          reject(error)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }


  //For testing
  public retrieveAllBusinessUnits(
    companyID?: string
  ): Promise<RetrieveAllBusinessUnitsResponse> {
    return new Promise(async (resolve, reject) => {
      const retrieveAllBusinessUnitsURL =
        process.env.REACT_APP_API_RETRIEVE_ALL_BUSINESS_UNIT_URL ||
        "";
      await this.getObject().then(async (value) => {
        let content: RetrieveAllBusinessUnitsRequest = {
          companyID: value.companyID
        };
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`,
          },
        }
        try {
          axios.post(retrieveAllBusinessUnitsURL, content, config).then(submitRes => {
            const responseData = JSON.parse(submitRes.data)
            let APIResponse = JSON.parse(responseData.body);
            const retrieveResponse: RetrieveAllBusinessUnitsResponse = {
              httpStatus: APIResponse.HttpStatus,
              businessUnits: APIResponse
            }
            resolve(retrieveResponse);
          }).catch(error => {
            reject(error);
          })
        }
        catch (error) {
          reject(error)
        }
      }).catch(error => {
        reject(error)
      })


    })
  }



  public getLateInitiatives(
    date: number,
    category: string
  ): Promise<RetrieveInitiativesResponse> {
    return new Promise(async (resolve, reject) => {
      const getLateInitiativesURL =
        process.env.REACT_APP_API_GET_LATE_INITIATIVES_URL ||
        "";
      await this.getObject().then(async (value) => {
        const content = {
          companyID: value.companyID,
          date: date,
          category: category,
        };
        const config = {
          headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${value.token}`
          },
        }
        try {
          axios.post(getLateInitiativesURL, content, config).then(value => {
            const apiDataResponse = JSON.parse(value.data);
            const resultBody = JSON.parse(apiDataResponse.body);
            const retrieveResponse: RetrieveInitiativesResponse = {
              statusCode: resultBody.statusCode,
              body: resultBody
            }
            resolve(retrieveResponse);
          })
        } catch (e) {
          reject(e);
        }
      }).catch(error => {
        reject(error)
      })

    });
  }

  public deleteInitiative(initiative: Initiative): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value) => {
        this.sessionParams = {
          token: value.token,
          companyID: value.companyID,
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress,
          userRole: value.userRole
        }
        initiative.companyID = value.companyID;
        initiative.lastModifiedBy = {
          firstName: this.sessionParams.firstName,
          lastName: this.sessionParams.lastName,
          emailAddress: this.sessionParams.emailAddress,
        }
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.sessionParams.token
          }
        }

        const deleteInitiativeURL =
          process.env.REACT_APP_API_DELETE_INITIATIVE_URL ||
          "";
        axios.post(deleteInitiativeURL, initiative, config).then(submitRes => {
          resolve(submitRes.headers)
        }).catch(error => {
          reject(error);
        })
      });
    })
  }

  public submitInitiative(initiative: Initiative): Promise<GeneralHttpResponse> {
    return new Promise(async (resolve, reject) => {
      await this.getObject().then(async (value: AuthenticationResponse) => {
        this.sessionParams = {
          token: value.token,
          companyID: value.companyID,
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress,
          userRole: value.userRole
        }
        initiative.companyID = value.companyID;
        initiative.lastModifiedBy = {
          firstName: this.sessionParams.firstName,
          lastName: this.sessionParams.lastName,
          emailAddress: this.sessionParams.emailAddress,
        }
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.sessionParams.token
          }
        }

        const submitAPIURL =
          process.env.REACT_APP_API_ADD_INITIATIVE_URL ||
          "";
        axios.post(submitAPIURL, initiative, config).then((res: AxiosResponse)=>{
          resolve(res.headers)
        }).catch((error: any) => {
          console.error(error);
          reject(error)
        })
      }).catch(error => {
        console.error(error);
        reject(error);
      });
    });
  }


  public getInitiativeCount(): Promise<InitiativeCountBody> {
    return new Promise(async (resolve, reject) => {
      const getInitiativeCountURL =
        process.env.REACT_APP_API_GET_INITIATIVE_COUNTS_URL ||
        "";
      await this.getObject().then(async (value) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${value.token}`,
          }
        };

        const content = {
          "CompanyID": value.companyID
        }

        try {
          axios.post(getInitiativeCountURL, content, config)
            .then((response) => {
              console.log(response);
            })

        } catch (e) {
          reject(e);
        }
      });

    });
  }

  // category: "environment", "economy", "society"
  public getReportConfig(category: string): Promise<GetReportContentResponse> {
    return new Promise((resolve, reject) => {
      this.getObject().then(async (value) => {
        this.sessionParams = {
          token: value.token,
          companyID: value.companyID,
          firstName: value.firstName,
          lastName: value.lastName,
          emailAddress: value.emailAddress,
          userRole: value.userRole
        }
    
      console.log(`Token: ${value.token}`)
      const getContentURL =
        process.env.REACT_APP_API_GET_REPORT_CONTENTS_URL ||
        "https://local.sustainability360.com:8001/local/getReportContents";

      let requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${value.token}`,
           },
        body: JSON.stringify({ category: category }),
      };
      try {
        fetch(getContentURL, requestOptions)
          .then((response) => response.json())
          .then((data: GetReportContentResponse) => resolve(data));
      } catch (e) {
        reject(e);
      }
    });
  })
  }

  // type: 0: Mission Steatement 1: Story, category: "environment", "economy", "society"
  public saveReportConfig(
    type: number,
    category: string,
    content: string
  ): Promise<GeneralHttpResponse> {
    return new Promise((resolve, reject) => {
      const saveContentURL =
        process.env.REACT_APP_API_SAVE_REPORT_CONTENT_URL ||
        "https://local.sustainability360.com:8001/local/saveReportContent";

      let requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.sessionParams.token}`, },
        body: JSON.stringify({
          type: type,
          category: category,
          content: content,
        }),
      };
      try {
        fetch(saveContentURL, requestOptions)
          .then((response) => response.json())
          .then((data: GeneralHttpResponse) => resolve(data));
      } catch (e) {
        reject(e);
      }
    });
  }

  public sendEmail(
    email: string,
    source: string
  ): Promise<HttpResponse> {
    return new Promise<HttpResponse>(async (resolve, reject) => {
      // todo Remove
      const resetLink: string = process.env.REACT_APP_API_RESET_LINK_URL || "";

      let config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const content = {
        "email": email,
        "source": source,
      }

      try {
        axios.post(resetLink, content, config)
          .then((response: any) => {
            const sendMail: HttpResponse = {
              statusCode: response.status,
              headers: response.headers,
              body: response.data
            }
            resolve(sendMail);
          }).catch(error => {
            reject(error);
          })

      } catch (e) {
        reject(e);
      }

    });
  }

  public resetForgotPassword(resetDetails: ResetPassword): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const resetForgotPasswordLink = process.env.REACT_APP_API_FORGOT_PASSWORD_RESET || "";
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      axios.post(resetForgotPasswordLink, resetDetails, config).then((response) => {
        resolve(response);
      }).catch(error => {
        console.error(error);
        reject(error);
      })
    })
  }
}
