import * as React from "react";
import { Errors, FormContext } from "./VisitorRegistration";

type Editor = "textbox";

export interface Validation {
    rule: (value: string, fieldName: string, args: any) => string;
    args?: any;
}


export interface FieldProps {
    id: string;
    label?: string;
    editor?: Editor;
    value?: any;
    validation?: Validation;
}

export const FormField: React.FunctionComponent<FieldProps> = ({
    id,
    label,
    editor,
    value
}) => {
    const getError = (errors: Errors): string => (errors ? errors[id]: "");
    const getEditorStyle = (errors: Errors): any => getError(errors) ? { borderColor: "red"} : {};
    return (
        <FormContext.Consumer>
            {(context: FormContext) => (
                <div className="form-group">
                 
                    {label && <label htmlFor={id}>{label}</label>}
                    {editor!.toLocaleLowerCase() === "textbox" && (
                        <input
                            id={id}
                            type="text"
                            value={value}
                            style={getEditorStyle(context.errors)}
                            onChange={
                                (e: React.FormEvent<HTMLInputElement>)=>{
                                    context.setValues({[id]: e.currentTarget.value})
                                    context.validate(id, e.currentTarget.value)
                                }
                            }
                            onBlur={
                                (e: React.FormEvent<HTMLInputElement>)=>{
                                    context.validate(id, e.currentTarget.value)
                                }
                            }
                            className="form-control"
                        />
                    )}
                    
                    {getError(context.errors) && (
                        
                        <div style={{color: "red", fontSize: "70%"}}>
                         <p>{getError(context.errors)}</p>
                        </div>
                    )}
                   
                </div>
            )}

        </FormContext.Consumer>
    )
};

FormField.defaultProps = {
    editor: "textbox"
};