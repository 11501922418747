import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonPage,
  IonSearchbar,
  IonIcon,
  IonModal,
  IonToast,
} from "@ionic/react";

import CSS from "csstype";
import { BusinessUnit } from "@s360/common-models/businessUnit";
import { useLocation, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import { BusinessUnitService } from "../services/BusinessUnitService";
import BusinessUnitsList from "../components/BusinessUnitsList";
import { addOutline } from "ionicons/icons";
import BusinessUnitForm from "../components/BusinessUnitForm";
import { Roles } from "@s360/common-models/roles";

interface BusinessUnitsPageProps extends RouteComponentProps, UserState {}

const BusinessUnitsPage: React.FC<BusinessUnitsPageProps> = ({ history, userRole }) => {
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [isOpenBusinessUnitDialog, setIsOpenBusinessUnitDialog] = useState<boolean>(false);
  const [showAddToast, setShowAddToast] = useState<boolean>(false);
  const [showAddFailedToast, setShowAddFailedToast] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filterText, setFilter] = useState<string>('');
  const location = useLocation();
  const sessionParams = location.state;

  const searchBarStyle: CSS.Properties = {
    color: "black"
  }
  
  const filterBusinessUnits = (text: string) => {
    if (!text || text.length<=0) return businessUnits;
    let lowerText = text.toLowerCase();
    let businessUnitList: BusinessUnit[] = businessUnits.filter(function(businessUnit) {
      return (businessUnit && (
        (businessUnit.businessUnitName && businessUnit.businessUnitName.toLowerCase().indexOf(lowerText) >= 0)
        || (businessUnit.businessUnitDescription && businessUnit.businessUnitDescription.toLowerCase().indexOf(lowerText) >= 0)
        || (businessUnit.businessUnitID && businessUnit.businessUnitID.toLowerCase().indexOf(lowerText) >=0)
        ))
    });
    return businessUnitList
  }

  const afterAddedAction= () => {
    setShowAddToast(true)
    setRefreshKey(oldKey => oldKey +1);
  }
  
  const afterEditedAction= () => {
    setRefreshKey(oldKey => oldKey +1);
  }
  const getBusinessUnits = () => {
    const businessUnitService = new BusinessUnitService();
    
    businessUnitService
      .getAllBusinessUnits()
      .then((businessUnitList: BusinessUnit[]) => {
        if (businessUnitList) {
          setBusinessUnits(businessUnitList);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  useEffect(() => {
    getBusinessUnits();
  }, [refreshKey]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle color="light" size="large">Business Units</IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* This is to prevent any other Users other than an Admin to access Business Units Page
          Implemented for security reasons because a user can just go to /business_units,
          see information and potentially make unauthorized changes */}
      {(userRole === Roles.Admin) && (<IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonSearchbar style={searchBarStyle} value={filterText} onIonChange={e => setFilter(e.detail.value!)}></IonSearchbar>
            </IonCol>
            <IonCol>
              <IonButton class="ion-float-right" 
                onClick={() => setIsOpenBusinessUnitDialog(true)}>
                  <IonIcon slot="start" icon={addOutline} />
                  Add Business Unit
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <BusinessUnitsList displayedBusinessUnits={filterBusinessUnits(filterText)} afterEditedAction={afterEditedAction}></BusinessUnitsList>
          </IonRow>
        </IonGrid>
      </IonContent>)}
      <IonModal
          isOpen={isOpenBusinessUnitDialog}
          onDidDismiss={() => setIsOpenBusinessUnitDialog(false)}
          backdropDismiss={false}
        >
        <BusinessUnitForm
          showToast={() => afterAddedAction()}
          showErrorToast={() => setShowAddFailedToast(true)}
          closeAction={() => setIsOpenBusinessUnitDialog(false)}
        ></BusinessUnitForm>
      </IonModal>
      <IonToast
        isOpen={showAddToast}
        onDidDismiss={() => setShowAddToast(false)}
        message="Business Unit is added successfully"
        duration={1000}
        position="top"
        color="success"
      />
      <IonToast
        isOpen={showAddFailedToast}
        onDidDismiss={() => setShowAddFailedToast(false)}
        message={"Failed to add Business Unit"}
        duration={1000}
        position="top"
        color="danger"
      />
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(BusinessUnitsPage),
});
