import { IonCol, IonContent, IonGrid, IonHeader, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import AuthActivityStats from "../components/AuthActivityStats";
import { UserState } from "../data/user/user.state";
import { connect } from "../data/connect";
import DatePicker from 'react-datepicker';
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/AuthActivity.css';

interface AuthActivityPage extends RouteComponentProps, UserState {
  startDate: number,
  endDate: number,
}

const AuthActivityPage: React.FC<RouteComponentProps> = () => {

  const [startDate, setStartDate] = useState<number>(new Date().setHours(0, 0, 0, 0)); // Initiatilize the start date from start of the current day
  const [endDate, setEndDate] = useState<number>(new Date().setHours(23, 59, 59, 0)); // Initialize the end date to the end of the current day

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle color="light" size="large">Authentication Activity</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow className="activity-date-picker">
            <IonCol size="2.5">
              <IonLabel>
                Start Date: &nbsp;
              </IonLabel>
                <DatePicker
                        dateFormat="dd/MM/yyyy"
                        id="activity-start-date"
                        onChange={(date: Date) => setStartDate(date!.getTime())}
                        placeholderText="DD/MM/YYYY"
                        selected={new Date(startDate)}
                        maxDate={new Date(endDate)}
                />
            </IonCol>
            <IonCol size="2.5">
              <IonLabel>
                End Date: &nbsp;
              </IonLabel>
              <DatePicker
                      dateFormat="dd/MM/yyyy"
                      id="activity-end-date"
                      onChange={(date: Date) => setEndDate(date!.setHours(23, 59, 59, 0))}
                      placeholderText="DD/MM/YYYY"
                      selected={new Date(endDate)}
                      minDate={new Date(startDate)}
                      maxDate={new Date()}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <AuthActivityStats
              startDate={startDate}
              endDate={endDate}
            />
          </IonRow>
        </IonGrid>
      </IonContent>     
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(AuthActivityPage),
});
