import React from "react";
import { CartesianGrid, Bar, Line, ComposedChart, Tooltip, XAxis, YAxis, Legend, LabelList, ReferenceLine, ResponsiveContainer } from "recharts";
import arrow_right from "../images/arrow_right.png";
import arrow_up from "../images/arrow_up.png";
import arrow_down from "../images/arrow_down.png";
import {CarbonChartData} from "@s360/common-models/carbonReport"

const CarbonChart: React.FC<{chartData: CarbonChartData[]; isYearly: boolean; drillDown: (year: string) => void;}> = (props) => {
  /* <sub></sub> cannot work inside recharts label --- */
  const yLabel: string = "Emissions (kg CO2-e)";
  const getArrowRef = (value: string): string => {
    switch (value) {
      case "up":
        return String(arrow_up);
      case "down":
        return String(arrow_down);
      case "right":
        return String(arrow_right);
      default:
        return "";
    }
  } 

  const renderCustomizedLabel = () => {
    return (props: {x: number, y: number, width: number, value: string}): React.SVGProps<SVGGElement> => {
      const { x, y, width, value } = props;

      return (
        <g>
          <image
            x={x + width / 2 - 12}
            width="24"
            height="24"
            y={value !== "down" ? y - 30 : y + 10}
          />
        </g>
      );
    };
  };

  const handleClick = (e: unknown) => {
    if (props.isYearly) {
      props.drillDown((e as CarbonChartData).date);
    }
  };

  return (
    <ResponsiveContainer className="summaryChart" width="100%" height={600}>
      <ComposedChart
        width={900}
        height={400}
        data={props.chartData}
        margin={{ top: 25, right: 5, left: 5, bottom: 5 }}
      >
        <XAxis
          dataKey="date"
          padding={{ left: 40, right: 40 }}
          axisLine={false}
          tick={{dy: 25}} 
        />
        <YAxis
          tickFormatter={(tickValue: number): string => {
            let tickString: string = "";
            if (Math.abs(tickValue).toString().length >= 13) {
                tickString = (0.1 * (tickValue / 100000000000)).toFixed(1).replace(".0", "") + "T";
            } else if (Math.abs(tickValue).toString().length >= 10) {
                tickString = (0.1 * (tickValue / 100000000)).toFixed(1).replace(".0", "") + "B";
            } else if (Math.abs(tickValue).toString().length >= 7) {
                tickString = (0.1 * (tickValue / 100000)).toFixed(1).replace(".0", "") + "M";
            } else if (Math.abs(tickValue).toString().length >= 5) {
                tickString = (0.1 * (tickValue / 100)).toFixed(1).replace(".0", "") + "K";
            } else {
                tickString = tickValue.toLocaleString()
            }
            return tickString;
          }}
          label={{
            value: yLabel,
            angle: -90,
            position: "insideLeft",
            style: {textAnchor: 'middle', fontSize: "1.25em"}
        }}
        />
        <Tooltip />
        <Legend wrapperStyle={{position: 'relative'}}/>
        <CartesianGrid stroke="#f5f5f5" />
        <ReferenceLine y={0} stroke="black" />
        <Bar name="Actual" dataKey="value" fill="green" minPointSize={0} maxBarSize={35} onClick={handleClick}>
          <LabelList data={props.chartData} dataKey="arrow" content={renderCustomizedLabel()} />    
        </Bar>
        <Line name="Target" type="linear" dataKey="target" strokeWidth={2} stroke="#23e87f" dot={{ stroke: '#13bf64', strokeWidth: 3 }}/>
        {/* <Line name="Trend" type="linear" dataKey="trend" strokeWidth={2} stroke="#38b6e0" dot={{ stroke: '#138bbf', strokeWidth: 3 }}/> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default CarbonChart;
