import { IonCol, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import BcorpOverviewCard from "../../components/BcorpOverviewCard/BcorpOverviewCard";
import { BcorpReportOverviewProps } from "./BcorpReportOverviewProps";
import donutImage from "../../images/InitiativePieChart-image.png";
import { useHistory } from "react-router-dom";

import '../../css/InitiativesTop.css'
import "../../components/modal.css";

const BcorpReportOverview: React.FC<BcorpReportOverviewProps> = (props) => {
    const [errorMessage, setErrorMessage] = useState<string>('');

    const history = useHistory();

    const redirectToInitiativeList = (category: string) => {
        history.push(`/console/${category.toLowerCase()}`)
        window.location.reload();
    }

    return (
        <>
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonTitle
                                        size="large"
                                        style={{ color: 'white', marginTop: "8px" }}
                                    >
                                        B Corp Report Overview
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid
                        id="grid"
                        fixed
                    >
                        <IonRow>
                            <IonCol
                                size="1"
                            >
                                <BcorpOverviewCard
                                    id="col-stats"
                                    title="COLLABORATION"
                                    setErrorMessage={setErrorMessage}
                                    onCardClick={() => redirectToInitiativeList('collaboration')}
                                />
                                <BcorpOverviewCard
                                    id="eco-stats"
                                    title="GOVERNANCE"
                                    setErrorMessage={setErrorMessage}
                                    onCardClick={() => redirectToInitiativeList('economy')}
                                />
                            </IonCol>
                            <IonCol
                                className="center"
                                size="10"
                            >
                                <div
                                    className="container"
                                >
                                    <IonImg
                                        id="logo-pie"
                                        src={donutImage}
                                        alt="logo"
                                    />
                                    <span
                                        id="col-button"
                                        onClick={() => redirectToInitiativeList('collaboration')}
                                    />
                                    <span
                                        id="soc-button"
                                        onClick={() => redirectToInitiativeList('society')}
                                    />
                                    <span
                                        id="env-button"
                                        onClick={() => redirectToInitiativeList('environment')}
                                    />
                                    <span
                                        id="eco-button"
                                        onClick={() => redirectToInitiativeList('economy')}
                                    />
                                </div>
                            </IonCol>
                            <IonCol
                                size="1"
                            >
                                <BcorpOverviewCard
                                    id="soc-stats"
                                    title="SOcIETY"
                                    setErrorMessage={setErrorMessage}
                                    onCardClick={() => redirectToInitiativeList('society')}
                                />
                                <BcorpOverviewCard
                                    id="env-stats"
                                    title="ENVIRONMENT"
                                    setErrorMessage={setErrorMessage}
                                    onCardClick={() => redirectToInitiativeList('environment')}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
            <IonToast
                isOpen={errorMessage !== ''}
                onDidDismiss={() => setErrorMessage('')}
                message={errorMessage}
                duration={2000}
                position="top"
                color="warn"
            />
        </>
    )
}

export default BcorpReportOverview;