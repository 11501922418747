import React, { useState } from "react";
import "./InitiativeList.css";
import CSS from "csstype";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from "react-bootstrap";
import "./UsersList.css"
import { IonAlert, IonButton, IonGrid, IonIcon, IonModal, IonToast } from "@ionic/react";
import {
  createOutline,
  trashOutline
} from "ionicons/icons";
import { BusinessUnit } from "@s360/common-models/businessUnit";
import { BusinessUnitService } from "../services/BusinessUnitService";
import { GeneralHttpResponse } from "@s360/common-models/apiResponses";
import BusinessUnitForm from "./BusinessUnitForm";
import { stringShortenerEllipsis } from "../helper/StringShortenerEllipsis";
import { initial } from "lodash";
import { initiativeBUCleaner } from "../helper/initiativeBUCleaner";
import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

const BusinessUnitsList: React.FC<{
  displayedBusinessUnits: BusinessUnit[];
  afterEditedAction: () => void;
}> = (props) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [showEditToast, setShowEditToast] = useState(false);
  const [showEditFailedToast, setShowEditFailedToast] = useState(false);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [showDeleteFailedToast, setShowDeleteFailedToast] = useState(false);

  const [targetBusinessUnit, setTargetBusinessUnit] = useState<BusinessUnit>();
  const businessUnitService = new BusinessUnitService();

  const onDeleteButton = (businessUnit: BusinessUnit) => {
    setTargetBusinessUnit(businessUnit);
    setIsOpenDeleteDialog(true);
  }
  
  const onEditButton = (businessUnit: BusinessUnit) => {
    setTargetBusinessUnit(businessUnit);
    setIsOpenEditDialog(true);
  }
  
  const deleteBusinessUnit = () => {
    if (!targetBusinessUnit) return;
    let targetBusinessUnitID: string = targetBusinessUnit.businessUnitID!;
    businessUnitService.deleteBusinessUnit(targetBusinessUnit).then((result : GeneralHttpResponse) => {
        if (result.httpStatus === 200) {
          setShowDeleteToast(true);
          props.afterEditedAction();
          initiativeBUCleaner(targetBusinessUnitID);
          Storage.set({key: 'reloadInitiatives', value: 'true'});
        } else {
          setShowDeleteFailedToast(true);
        }
      })
      .catch((error) => {
        console.error("Error delete business unit:", error);
        setShowDeleteFailedToast(true);
      });
  }

  const generateBusinessUnitHeaders = (businessUnit: BusinessUnit[]) => {
    let businessUnitList = generateBusinessUnitList(businessUnit);
    return (
      <>
        <tr>
          {/* <th className="idColumn">Id</th> */}
          <th style={{width: '20rem'}}>Business Unit</th>
          <th> Description</th>
          <th style={{width: '8.1rem'}}></th>
        </tr>
        {businessUnitList.businessUnitList}
      </>
    );
  };

  const afterUpdated = () => {
    setShowEditToast(true);
    props.afterEditedAction();
  }

  const generateBusinessUnitList = (businessUnits: BusinessUnit[]) => {
    let businessUnitList;
    if (businessUnits.length > 0) {
      businessUnitList = businessUnits.map(function (businessUnit) {
        return (
          <tr key={businessUnit.businessUnitID!}>
            <td >{businessUnit.businessUnitName}</td>
            <td >{stringShortenerEllipsis(businessUnit.businessUnitDescription!, 110)}</td>
            <td>
              <IonButton color="light" onClick={() => {onEditButton(businessUnit)}} >
                  <IonIcon slot="icon-only" icon={createOutline} />
              </IonButton>
              <IonButton color="light" onClick={() => {onDeleteButton(businessUnit)}}>
                  <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            </td>
          </tr>
        );
      });
    }
    return { businessUnitList };
  };

  return (
    <IonGrid>
      <Table style={{marginRight: '0.5rem'}} striped bordered hover>
        <tbody>{generateBusinessUnitHeaders(props.displayedBusinessUnits)}</tbody>
      </Table>
      <IonModal
          isOpen={isOpenEditDialog}
          onDidDismiss={() => setIsOpenEditDialog(false)}
          backdropDismiss={false}
        >
          <BusinessUnitForm
            showToast={() => afterUpdated()}
            showErrorToast={(msg: string) => setShowEditFailedToast(true)}
            closeAction={() => setIsOpenEditDialog(false)}
            businessUnit={targetBusinessUnit}
          ></BusinessUnitForm>
        </IonModal>
        
        <IonAlert
          isOpen={isOpenDeleteDialog}
          backdropDismiss={false}
          onDidDismiss={() => setIsOpenDeleteDialog(false)}
          header={"Are you sure you want to delete this Business Unit?"}
          subHeader={targetBusinessUnit?.businessUnitName}
          buttons={[
            {
              text: "OK",
              handler: () => {
                deleteBusinessUnit();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonToast
          isOpen={showDeleteToast}
          onDidDismiss={() => setShowDeleteToast(false)}
          message="Business Unit is deleted successfully"
          duration={1000}
          position="top"
          color="success"
        />
        <IonToast
          isOpen={showDeleteFailedToast}
          onDidDismiss={() => setShowDeleteFailedToast(false)}
          message="Failed to delete Business Unit"
          duration={1000}
          position="top"
          color="danger"
        />
        <IonToast
          isOpen={showEditToast}
          onDidDismiss={() => setShowEditToast(false)}
          message="Business Unit is edited successfully"
          duration={1000}
          position="top"
          color="success"
        />
        <IonToast
          isOpen={showEditFailedToast}
          onDidDismiss={() => setShowEditFailedToast(false)}
          message={"Failed to edit Business Unit"}
          duration={1000}
          position="top"
          color="danger"
        />
      </IonGrid>
  );
};

export default BusinessUnitsList;
