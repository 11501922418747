import { IonButton, IonIcon, IonList, IonPopover } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import React, { ReactElement, MouseEvent, useState } from "react";
import { DropDownProps } from "./DropDownProps";


const DropDown = ( { children, isDisabled }: DropDownProps ): ReactElement<any, any> => {
    const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
        open: false,
        event: undefined,
    });

    const handleOpenPopOver = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setShowPopover({ open: true, event: event.nativeEvent });
    }

    const handleOnClick = (event: MouseEvent<HTMLIonListElement>): void => {
        event.stopPropagation();
        setShowPopover({ open: false, event: undefined});
    }

    const handleOnDidDismiss = (): void => {
        setShowPopover({ open: false, event: undefined});
    }

    return (
        <>
            <IonButton
                color="light"
                onClick={handleOpenPopOver}
                disabled={isDisabled}
            >
                <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
            <IonPopover
                isOpen={showPopover.open}
                event={showPopover.event}
                showBackdrop={false}
                onDidDismiss={handleOnDidDismiss}
            >
                <IonList onClick={handleOnClick}>
                    {children}
                </IonList>
            </IonPopover>
        </>
    )
}

export default DropDown;