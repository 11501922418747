import React, { useState, useEffect } from "react";
import Select from 'react-select';
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonCard,
  IonCardContent,
  IonToast,
  IonText
} from "@ionic/react";

import { useLocation, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import "./ReportPage.css";
import CarbonChart from "../components/CarbonChart";
import { CarbonChartData } from "@s360/common-models/carbonReport";
import { CarbonEmissionFactor } from "@s360/common-models/carbon";
import { CarbonService } from "../services/CarbonService";
import { HttpStatus } from "@s360/common-models/httpStatus";
import { HttpResponse } from "@s360/common-models/httpResponse";

interface SelectOption {
    value: string[],
    label: string
}

interface OwnProps extends RouteComponentProps {}
interface ReportProps extends RouteComponentProps, UserState {}

const ReportCarbon: React.FC<ReportProps> = ({}) => {
  const [chartData, setChartData] = useState<CarbonChartData[]>([]);
  const [showGettingError, setShowGettingError] = useState<boolean>(false);
  const defaultOption: SelectOption = {value: [], label: "All"};
  const [factorOptions, setFactorOptions] = useState<SelectOption[]>([defaultOption]);
  const [selectedOption, setSelectedOption] = useState<SelectOption>(defaultOption);
  const [drilledDownYear, setDrilledDownYear] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.endsWith("report_carbon")) {
      let paths = location.pathname.split("/");
      let factorName = undefined;
      if (paths && paths.length > 0) {
        factorName = paths[paths.length - 1];
      }
      if(factorName) {
        setSelectedOption({value: [factorName], label: factorName});
      }
    }  
  }, []);
  
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: 'black',
    }),
    control: (provided: any) => ({
      ...provided,
      border: '1px solid black',
      borderRadius: '0',
    }),
  }

  useEffect(() => {
    getReportData();
  }, [selectedOption]);

  useEffect(() => {
    getFactorSelections();
  }, []);

  const getFactorSelections = () => {
    console.log('start get factors +--------------------')
    const carbonService = new CarbonService();
    let options: SelectOption[] = [{value: [], label: "All"}];
    carbonService
      .getEmissionFactors()
      .then((response: HttpResponse) => {
        console.log('got factors +--------------------')
        // console.log(response);
        if (response.statusCode === HttpStatus.OK) {
            let factors: CarbonEmissionFactor[] =  response.body;
            factors.forEach(factor => {
            let label = factor.level1;
            if (!options.find(s=>s.label === factor.level1)) {
              let levels: string[] = [];
              levels.push(factor.level1);
              let option: SelectOption = {
                value: levels,
                label: label
              }
              options.push(option);
            }
            
            if (factor.level2.length > 0) {
              label = " - " + factor.level2;
              if (!options.find(s=>s.label === label)) {
                let levels: string[] = [];
                levels.push(factor.level1);
                levels.push(factor.level2);
                let option: SelectOption = {
                  value: levels,
                  label: label
                }
                options.push(option);
              }
            }

            if (factor.level3.length > 0) {
              label = " -- " + factor.level3;
              if (!options.find(s=>s.label === label)) {
                let levels: string[] = [];
                levels.push(factor.level1);
                levels.push(factor.level2);
                levels.push(factor.level3);
                let option: SelectOption = {
                  value: levels,
                  label: label
                }
                options.push(option);
              }
            }
            if (factor.level4.length > 0) {
              label = " --- " + factor.level4;
              if (!options.find(s=>s.label === label)) {
                let levels: string[] = [];
                levels.push(factor.level1);
                levels.push(factor.level2);
                levels.push(factor.level3);
                levels.push(factor.level4);
                let option: SelectOption = {
                  value: levels,
                  label: label
                }
                options.push(option);
              }
            }
          });
          setFactorOptions(options);
        } else {
          setShowGettingError(true);
        }
      })
      .catch((error) => {
        console.error("Error get factors:", error);
        setShowGettingError(true);
      });
      setFactorOptions(options);
  }

  const handleChange = (option: SelectOption) => {
    setSelectedOption(option);
    console.log(`Option selected:`, option);
  };

  const getReportData = (year?: string) => {
    console.log('start get data +--------------------')
    const service = new CarbonService();
    
    if (year) {
      service
      .getMonthlyReportData(selectedOption.value, year)
        .then((response: HttpResponse) => {
          console.log('got monthly data +--------------------')
          console.log(response);
          if (response.statusCode === HttpStatus.OK) {
            setChartData(response.body);
          } else {
            setShowGettingError(true);
          }
        })
        .catch((error) => {
          console.error("Error get report data:", error);
          setShowGettingError(true);
        });
    } else {
      setDrilledDownYear("");
    service
      .getYearlyReportData(selectedOption.value)
        .then((response: HttpResponse) => {
          console.log('got yearly data +--------------------')
          console.log(response);
          if (response.statusCode === HttpStatus.OK) {
            setChartData(response.body);
          } else {
            setShowGettingError(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setShowGettingError(true);
        });
      }
  };

  const drillDown = (year: string) => {
    setDrilledDownYear(year);
    getReportData(year);
  }

  const clickBackToYear = (e:any) => {
    getReportData();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle color="light" size="large">Carbon</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          {/* <IonRow>
            <IonLabel>Select Emission Factor</IonLabel>
          </IonRow> */}
          
          <IonRow>
            <IonCard style={{width: "100%"}}>
                {/* <IonCardHeader>
                  
              </IonCardHeader> */}
              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    {drilledDownYear ? (
                      <IonCol>
                        <h1>
                          <IonText color="success" className="linkToBack" style={{textDecoration: "underline", cursor : "pointer"}} onClick={clickBackToYear}>
                            {selectedOption.label.split('-').join('').trim()}
                          </IonText>
                          <IonText>
                            {" > " + drilledDownYear}
                          </IonText>
                        </h1>
                      </IonCol>
                      ) : (
                      <IonCol size="6">
                        <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={factorOptions}
                            styles={customStyles}
                          />  
                      </IonCol>
                    )}
                  </IonRow>
                  <IonRow>
                    <CarbonChart 
                      chartData={chartData}
                      isYearly={(drilledDownYear === "")}
                      drillDown={drillDown}
                    >
                    </CarbonChart>
                  </IonRow>
                </IonGrid>
                
              </IonCardContent>
            </IonCard>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonToast
        isOpen={showGettingError}
        onDidDismiss={() => setShowGettingError(false)}
        message="Failed to get carbon report data"
        duration={1000}
        position="top"
        color="danger"
      />
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(ReportCarbon),
});
