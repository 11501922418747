import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { IonAlert, IonGrid, IonIcon, IonLabel, IonModal, IonToast } from "@ionic/react";
import {
  closeSharp,
  checkmarkSharp,
} from "ionicons/icons";
import { Idea } from "@s360/common-models/idea";
import { IdeaService } from "../services/IdeaService";
import InitiativeModal from "./InitiativeModal";
import DisplayTable from "./DisplayTable";
import "../css/IdeaList.css";
import { stringShortenerEllipsis } from "../helper/StringShortenerEllipsis";
import { IdeaStatus } from "@s360/common-models/ideaStatus";
import { dateToLocale } from "../helper/DateConverter";
import IdeaDetails from "./IdeaDetails";

const IdeaList: React.FC<{
  ideaList: Idea[];
  afterEditedAction: () => void;
}> = (props) => {
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
  const [showInitiativeModal, setShowInitiativeModal] = useState<boolean>(false);
  const [showIdeaDetails, setShowIdeaDetails] = useState<boolean>(false);
  const [showAcceptedToast, setShowAcceptedToast] = useState<boolean>(false);
  const [showRejectedToast, setShowRejectedToast] = useState<boolean>(false);
  const [showRejectFailedToast, setShowRejectFailedToast] = useState<boolean>(false);
  const [newIdeasList, setNewIdeasList] = useState<Idea[]>([]);
  const [targetIdea, setTargetIdea] = useState<Idea>();
  const ideaService = new IdeaService();

  React.useEffect(() => {
    let ideasList: Idea[] = props.ideaList.filter(idea => {
      return (idea && idea.ideaStatus === IdeaStatus.New);
    })
    setNewIdeasList(ideasList);
  }, [props.ideaList])

  const onReject = (idea: Idea) => {
    setTargetIdea(idea);
    setIsOpenRejectDialog(true);
    closeModal();
  }
  
  const onAccept = (idea: Idea) => {
    setTargetIdea(idea);
    setShowIdeaDetails(false);
    setShowInitiativeModal(true);
  }
  
  const rejectIdea = () => {
    if (!targetIdea) return;
    ideaService.rejectIdea(targetIdea).then((result) => {
        if (result.status === 200) {
          setShowRejectedToast(true);
          props.afterEditedAction();
        } else {
          setShowRejectFailedToast(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowRejectFailedToast(true);
      });
  }

  const showToast = () => {
    setShowAcceptedToast(true);
    props.afterEditedAction();
  }

  const closeModal = () => {
    setShowInitiativeModal(false);
    setShowIdeaDetails(false);
  }

  const ideaListHeaders: string[] = [
    "TITLE",
    "DESCRIPTION",
    "DATE",
    "",
  ]

  const ideaColumnIds: string[] = [
    "idea-title",
    "idea-description",
    "idea-date",
    "idea-operation"
  ]

  const ideaColumnWidths: string[] = ['20%', '45%', '20%', '15%']

  const getIdeaTitle = (idea: Idea): React.ReactElement => {
    return (<IonLabel
          className="view-idea"
          onClick={() => {
            setTargetIdea(idea);
            setShowIdeaDetails(true);
          }}
        >
        {stringShortenerEllipsis(idea.title, 50)}
        </IonLabel>
    )
  }

  const getIdeaDescription = (idea: Idea): React.ReactElement => {
    return (<IonLabel
          className="view-idea"
          onClick={() => {
            setTargetIdea(idea);
            setShowIdeaDetails(true);
          }}
        >
        {stringShortenerEllipsis(idea.description, 50)}
      </IonLabel>
    )
  }

  const getIdeaDate = (idea: Idea): React.ReactElement => {
    return (<IonLabel
        className="view-idea"
        onClick={() => {
          setTargetIdea(idea);
          setShowIdeaDetails(true);
        }}
      >
      {idea.createdAt ? dateToLocale({ dateNumber: idea.createdAt, time: true }) : ''}
      </IonLabel>
    )
  }

  const getIdeaOperations = (idea: Idea): React.ReactElement => {
    return (
      <>
          <span className="idea-operation-button" onClick={() => {onAccept(idea)}}>
            <IonIcon className="idea-icon" slot="start" icon={checkmarkSharp} />
              Accept
          </span>
          <span className="idea-operation-button" onClick={() => {onReject(idea)}} >
            <IonIcon className="idea-icon" slot="start" icon={closeSharp} />
              Reject
          </span>
      </>
    )
  }
  
  const rowInfoGetters: ((idea: Idea) => React.ReactElement)[] = [
    getIdeaTitle,
    getIdeaDescription,
    getIdeaDate,
    getIdeaOperations
  ]

  return (
    <IonGrid>
      <DisplayTable
        items={newIdeasList}
        headers={ideaListHeaders}
        rowInfoGetters={rowInfoGetters}
        customColumnId={ideaColumnIds}
        customWidth={ideaColumnWidths}
        tableId='idea-table'
        customTextColor="black"
        customBackgroundColor="000000"
        customTitleStyle={true}
      />
      <IonModal
        isOpen={showInitiativeModal}
        onDidDismiss={() => setShowInitiativeModal(false)}
        backdropDismiss={false}
        cssClass="initiative-modal"
      >
        <InitiativeModal
          closeAction={closeModal}
          showToast={showToast}
          isNewInitiative={true}
          addByNewIdea={targetIdea}
        ></InitiativeModal>
      </IonModal>

      <IonModal
        isOpen={showIdeaDetails}
        onDidDismiss={() => setShowIdeaDetails(false)}
        backdropDismiss={false}
        cssClass="initiative-modal"
      >
        <IdeaDetails
          closeAction={closeModal}
          acceptIdea={onAccept}
          rejectIdea={onReject}
          idea={targetIdea!}
        ></IdeaDetails>
      </IonModal>
      
      <IonAlert
        isOpen={isOpenRejectDialog}
        backdropDismiss={false}
        onDidDismiss={() => setIsOpenRejectDialog(false)}
        header={"Are you sure you want to reject this idea?"}
        buttons={[
          {
            text: "OK",
            handler: () => {
              rejectIdea();
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ]}
      />
      <IonToast
        isOpen={showAcceptedToast}
        onDidDismiss={() => setShowAcceptedToast(false)}
        message="Idea is accepted successfully"
        duration={1000}
        position="top"
        color="success"
      />
      <IonToast
        isOpen={showRejectedToast}
        onDidDismiss={() => setShowRejectedToast(false)}
        message="Idea is rejected successfully"
        duration={1000}
        position="top"
        color="success"
      />
      <IonToast
        isOpen={showRejectFailedToast}
        onDidDismiss={() => setShowRejectFailedToast(false)}
        message="Failed to reject idea"
        duration={1000}
        position="top"
        color="danger"
      />
        
    </IonGrid>
  );
};

export default IdeaList;
