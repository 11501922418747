import React, { useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonAlert,
  IonToast,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { Plugins } from '@capacitor/core';
import { User } from "@s360/common-models/user";
import { UserService } from "../services/UserService";
import { Roles } from "@s360/common-models/roles";
import { HttpStatus } from "@s360/common-models/httpStatus";
import { csvParser } from "../helper/CsvParser";

const { Storage } = Plugins;


const labelTexts = {
  title: "Add Multiple User",
};

const MultiUserForm: React.FC<{
  allUsers: User[];
  closeAction: () => void;
  showToast: () => void;
  showErrorToast: (msg: string) => void;
}> = (props) => {

  const userService = new UserService();
  
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showUploadErrorToast, setShowUploadErrorToast] = useState<boolean>(false);
  const [showCloseAlert, setCloseAlert] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [existingAccounts, setExistingAccounts] = useState<string[]>([]);

  const max_admin_accounts: number = 2;
  const max_staff_accounts: number = 20;
  const max_visitor_accounts: number = 1;
  const emailRegex: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const getRoleStr = (role: Roles) => {
    if(role == Roles.Admin) {
      return 'Administrator';
    } else if(role == Roles.Visitor) {
      return 'Visitor';
    } else {
      return 'Staff';
    }
  }

  const submitUser = async (firstName: string, lastName: string, email: string, userRole: string) => {

    if (
      (lastName && lastName.trim()) &&
      (firstName && firstName.trim()) &&
      (email && email.trim()) 
      ) {
      let role = Roles.User;
      if (userRole.toLowerCase() === 'administrator' || userRole.toLowerCase() === 'admin') {
        role = Roles.Admin;
      } else if (userRole.toLowerCase() === 'visitor') {
        role = Roles.Visitor;
      }

      let newUser : User = {
        id: '',
        companyID: '',
        lastName: lastName,
        firstName: firstName,
        emailAddress: email,
        userRole: role
      };

      userService.addUser(newUser).then((result) => {
        if (result.httpStatus === HttpStatus.OK) {
          props.closeAction();
          props.showToast();
        } else {
          props.showErrorToast("Failed to add user");
        }
      }).catch(err => {
        console.error(err);
        props.showErrorToast(err);
      });
    }
  };

  React.useEffect(() => {
    let emails: string[] = [];
    props.allUsers.filter(user => {
      emails.push(user.emailAddress);
    });
    setExistingAccounts(emails);
  },[])

  const checkInputs = (firstName: string, lastName: string, email: string, role: string): boolean => {
    if (firstName.trim() && lastName.trim() && email.trim() && role.trim() &&
        emailRegex.test(email) &&
        (role.toLowerCase() === "admin" || role.toLowerCase() === "administrator" ||
        role.toLowerCase() === "staff" ||
        role.toLowerCase() === "visitor")) {
      return true
    } else {
      return false;
    }
  };

  const registerUsers = (data: string[][]): void => {
    data.forEach(async user => {
      await submitUser(user[0], user[1], user[2], user[3]);
    })
  }

  const filterUpcomingAccounts = (users: string[][], role: Roles): number => {
    let accounts: string[][] = users.filter(user => {
      return (user && ((user[3] === role) || (role === Roles.Admin && user[3] === "admin")))
    });
    return accounts.length;
  }

  const filterCurrentAccounts = (users: User[], role: Roles): number => {
    let accounts: User[] = users.filter(user => {
      return (user && (user.userRole === role));
    });
    return accounts.length;
  }
  
  const checkNoExistingAccounts = (data: string[][]): boolean => {
    let checkResult = true;
    data.forEach(user => {
      if (existingAccounts.includes(user[2])) {
        checkResult = false;
        setErrorMessage(`Account ${user[2]} already exists!`);
        setShowUploadErrorToast(true);
      }
    })
    return checkResult;
  }

  const validateAccountsAvailable = (data: string[][]): boolean => {
    let currentAdminAccounts: number = filterCurrentAccounts(props.allUsers, Roles.Admin);
    let currentStaffAccounts: number = filterCurrentAccounts(props.allUsers, Roles.User);
    let currentVisitorAccounts: number = filterCurrentAccounts(props.allUsers, Roles.Visitor);
    let upcomingAdminAccounts: number = filterUpcomingAccounts(data, Roles.Admin);
    let upcomingStaffAccounts: number = filterUpcomingAccounts(data, Roles.User);
    let upcomingVisitorAccounts: number = filterUpcomingAccounts(data, Roles.Visitor);

    let checkResult: boolean = (
      ((currentAdminAccounts + upcomingAdminAccounts) <= max_admin_accounts) &&
      ((currentStaffAccounts + upcomingStaffAccounts) <= max_staff_accounts) &&
      ((currentVisitorAccounts + upcomingVisitorAccounts) <= max_visitor_accounts)
    )

    if (!checkResult) {
      setErrorMessage(`You have reached your limit of account roles!\nPlease contact S360 to enquire about adding more user roles.`)
      setShowUploadErrorToast(true);
    }

    return checkResult;
  }

  const validateData = (data: string[][]): boolean => {
    if (data.length === 0) {
      setErrorMessage(`No User Details Found: ${uploadedFile ? uploadedFile.name : 'Uploaded File'}`);
      setShowUploadErrorToast(true);
      return false;
    }
    let checkResult = true;
    data.forEach(user => {
      if (user.length === 4 && checkInputs(user[0], user[1], user[2], user[3])) {
      } else if (user.length < 4 || user === [""] || data.indexOf(user) === -1) {
      } else {
        checkResult = false;
        setErrorMessage(`Uploaded Account: ${user[2]} is invalid!`);
        setShowUploadErrorToast(true);
      }
    })
    return checkResult;
  }

  const validateCsvFile = (file: File): boolean => {
    const fileExt = file.name.slice(-4) || '';
    return (fileExt === ".csv")
  }

  const checkUsersTemplate = async (): Promise<void> => {
    if (uploadedFile &&
      validateCsvFile(uploadedFile)) {
      let data = (await csvParser(uploadedFile)).splice(1);
      if (validateData(data) &&
          validateAccountsAvailable(data) &&
          checkNoExistingAccounts(data)) {
        registerUsers(data);
      } else {
        setErrorMessage(`Invalid Format: ${uploadedFile ? uploadedFile.name : 'Uploaded File'}`)
        setShowUploadErrorToast(true);
      }
    } else {
      setErrorMessage(`Invalid File: ${uploadedFile ? uploadedFile.name : 'Uploaded File'}`)
      setShowUploadErrorToast(true);
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            {labelTexts.title}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={submitDisabled}
              onClick={() => checkUsersTemplate()}
            >Submit
            </IonButton>
            <IonButton onClick={() => setCloseAlert(true)}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid style={{margin: "1rem"}}>
          <IonRow className="max-width w-100">
            <form>
              <input
                type="file"
                accept=".csv, text/csv"
                onChange={(event) => {setUploadedFile(event.target.files![0])}}/>
            </form>
          </IonRow>
          <IonRow>
            <a color="light" href="/assets/s360_mutliple_user_upload_template.csv" download>Download Template</a>
            <p>&nbsp;(save as CSV file)</p>
          </IonRow>
        </IonGrid>
        <IonToast
          isOpen={showUploadErrorToast}
          onDidDismiss={() => setShowUploadErrorToast(false)}
          message={errorMessage}
          duration={5000}
          position="top"
          color="danger"
        />
        <IonAlert
          isOpen={showCloseAlert}
          backdropDismiss={false}
          onDidDismiss={() => setCloseAlert(false)}
          header={"Are you sure you don't want to upload users?"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                props.closeAction();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </>
  );
};

export default MultiUserForm;
