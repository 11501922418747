import React, { useState } from "react";
import { IonPage, IonHeader, IonContent, IonToolbar, IonTitle, IonButton, IonAlert,IonList, IonItem, IonText, IonInput, IonLabel, IonRouterLink, IonIcon,  } from "@ionic/react";
import { RouteComponentProps } from 'react-router-dom';
import { PhoneNumberUtil } from "google-libphonenumber";
import { RegisterService } from "../services/RegisterService";
import { RegisterResponse } from '@s360/common-models/apiResponses'
import { setIsLoggedIn, setUserRole, setEmailAddress } from "../data/user/user.actions";
import { connect } from '../data/connect';
import { User } from '@s360/common-models/user';
import { Roles } from '@s360/common-models/roles';
import { CompanyAddress } from '@s360/common-models/companyAddress'
import { Company } from '@s360/common-models/company'
import '../css/app.scss';
import { UserState } from "../data/user/user.state";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUserRole: typeof setUserRole;
  setEmailAddress: typeof setEmailAddress;
}

interface RegisterProps extends OwnProps, DispatchProps, UserState { }


const Register: React.FC<RegisterProps> = ({
  setIsLoggedIn,
  setUserRole,
  history,
  systemAdmin
}) => {
  
  const [inputCompanyName, setInputCompanyName] = useState('');
  const [inputAddress, setInputAddress] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputPostCode, setInputPostCode] = useState('');
  const [inputCompanyNumber, setInputCompanyNumber] = useState('');
  const [inputNumberOfEmployees, setInputNumberOfEmplyees] = useState<number>(0);
  const [inputIndustry, setInputIndustry] = useState('');
  const [inputAdminFirstName, setAdnminFirstName] = useState('');
  const [inputAdminLastName, setAdminLastName] = useState('');
  const [inputPhoneNumber, setPhoneNumber] = useState('');
  const [inputEmailAddress, setEmailAddress] = useState('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [canChangePages, setCanChangePages] = useState<boolean>(false);
  const [canRegister, setCanRegister] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formPage, setFormPage] = useState<number>(1);
  const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  const emailTestRegEx: RegExp = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$", "g")
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(false);
  const [companyNameBlurred, setCompanyNameBlurred] = useState(false);
  const [addressBlurred, setAddressBlurred] = useState(false);
  const [cityBlurred, setCityBlurred] = useState(false);
  const [postCodeBlurred, setPostCodeBlurred] = useState(false);
  const [companyNumberBlurred, setCompanyNumberBlurred] = useState(false);
  const [numberOfEmployeesBlurred, setNumberOfEmployeesBlurred] = useState(false);
  const [industryBlurred, setIndustryBlurred] = useState(false);
  const [adminFirstNameBlurred, setAdminFirstNameBlurred] = useState(false);
  const [adminLastNameBlurred, setAdminLastNameBlurred] = useState(false);
  const [phoneNumberBlurred, setPhoneNumberBlurred] = useState(false);
  const [emailAddressBlurred, setEmailAddressBlurred] = useState(false);

  React.useEffect(() => {
    checkInputs();
  
  });
  
  const checkInputs = () => {
    /* setUserNameError(inputUserName.trim() === "");
    setPasswordError(inputPassword.trim() === ""); */
    try {
      setValidPhoneNumber(phoneUtil.isPossibleNumber(phoneUtil.parse(inputPhoneNumber)))
    }
    catch {
      setValidPhoneNumber(false);
    }
    setIsValidEmail(emailTestRegEx.test(inputEmailAddress))
    setCanChangePages((inputCompanyName !== "" && inputAddress !== "" && inputCity !== "" && inputPostCode !== "" && inputCompanyNumber !== "" && inputNumberOfEmployees !== 0 && inputIndustry !== ""));
    setCanRegister((canChangePages && inputAdminFirstName !== "" && inputAdminLastName !== "" && isValidEmail && isValidPhoneNumber))
  };


  async function registerCompany() {

    setFormSubmitted(true);

    try {
      const registerService: RegisterService = new RegisterService();
      const adminInfo: User = {firstName: inputAdminFirstName, lastName: inputAdminLastName, emailAddress: inputEmailAddress, phoneNumber: inputPhoneNumber, userRole: Roles.Admin}
      const addressInfo: CompanyAddress = {streetAddress: inputAddress, city: inputCity, postCode: inputPostCode}
      const companyInfo: Company = {companyName: inputCompanyName, address: addressInfo, uniqueNumber: inputCompanyNumber, numberOfEmployees: inputNumberOfEmployees, industry: inputIndustry, admin: adminInfo}
      const response : RegisterResponse=  await registerService.register(companyInfo);
      if (response.status === 200) {
        history.push('/success');
      }
      else {
        setShowAlert(true);
      }

    } catch (e) {
      console.error(e);
      setShowAlert(true);
    }

  }
  
  async function pageForward() {
    setFormPage(2);
  }

  async function pageBackward() {
    setFormPage(1); // this shouldn't really be hardcoded but if we have to add another page anyway for whatever reason we're already changing the file and this'll take minimal effort.
  }
  
  return (
    <IonPage id="login-registration-page">
    <IonHeader>
      <IonToolbar color="primary">
        <IonTitle>Register</IonTitle>
      </IonToolbar>
    </IonHeader>
    {!systemAdmin &&
      (<IonContent>
        <div className="alert alert-secondary text-center" role="alert">
          Please contact Sustainability360 team to register
        </div>
      </IonContent>
    )}
    {systemAdmin && 
      (<IonContent>
          <form className="login-registration-form ion-padding">
            {formPage == 1 && <IonItem>
              <IonLabel position="stacked" color="primary">Company Name</IonLabel>
              <IonInput name="companyName" type="text" value={inputCompanyName} onIonChange={e => setInputCompanyName(e.detail.value!)}
                onIonBlur={() => setCompanyNameBlurred(true)} onIonFocus={() => setCompanyNameBlurred(false)} required={true}>
              </IonInput>
              {companyNameBlurred && inputCompanyName.trim() == "" && <IonText color="danger">
                  <p className="ion-padding-start">
                    Company Name is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 1 && <IonItem>
              <IonLabel position="stacked" color="primary">Address</IonLabel>
              <IonInput name="streetAddress" type="text" value={inputAddress} onIonChange={e => setInputAddress(e.detail.value!)}
              onIonBlur={() => setAddressBlurred(true)} onIonFocus={() => setAddressBlurred(false)} required={true}>
              </IonInput>
              {addressBlurred && inputAddress.trim() === "" && <IonText color="danger">
                  <p className="ion-padding-start">
                    Address is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 1 && <IonItem>
              <IonLabel position="stacked" color="primary">City</IonLabel>
              <IonInput name="city" type="text" value={inputCity}  onIonChange={e => setInputCity(e.detail.value!)}
              onIonBlur={() => setCityBlurred(true)} onIonFocus={() => setCityBlurred(false)} required={true}>
              </IonInput>
              {cityBlurred && inputCity.trim() === "" && <IonText color="danger">
                  <p className="ion-padding-start">
                    City is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 1 && <IonItem>
              <IonLabel position="stacked" color="primary">Post Code</IonLabel>
              <IonInput name="postCode" type="text" value={inputPostCode} onIonChange={e => setInputPostCode(e.detail.value!)}
              onIonBlur={() => setPostCodeBlurred(true)} onIonFocus={() => setPostCodeBlurred(false)} required={true}>
              </IonInput>
              {postCodeBlurred && inputPostCode.trim() === "" && <IonText color="danger">
                  <p className="ion-padding-start">
                    Post Code is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 1 && <IonItem>
              <IonLabel position="stacked" color="primary">Unique Company Identifier</IonLabel>
              <IonInput name="companyNumber" type="text" value={inputCompanyNumber} onIonChange={e => setInputCompanyNumber(e.detail.value!)}
              onIonBlur={() => setCompanyNumberBlurred(true)} onIonFocus={() => setCompanyNumberBlurred(false)} required={true}>
              </IonInput>
              {companyNumberBlurred && inputCompanyNumber.trim() === "" && <IonText color="danger">
                  <p className="ion-padding-start">
                    Unique Company Identifier is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 1 && <IonItem>
              <IonLabel position="stacked" color="primary">Number of Employees</IonLabel>
              <IonInput name="numberOfEmployees" type="number"  value={inputNumberOfEmployees} onIonChange={e => setInputNumberOfEmplyees(parseInt(e.detail.value!,10))}
              onIonBlur={() => setNumberOfEmployeesBlurred(true)} onIonFocus={() => setNumberOfEmployeesBlurred(false)} required={true}>
              </IonInput>
              {numberOfEmployeesBlurred && inputNumberOfEmployees === 0 && <IonText color="danger">
                  <p className="ion-padding-start">
                    Please enter your number of employees.
                  </p>
                </IonText>}
            </IonItem>}
            
            {formPage === 1 && <IonItem>
              <IonLabel position="stacked" color="primary">Industry</IonLabel>
              <IonInput name="industry" type="text" value={inputIndustry} onIonChange={e => setInputIndustry(e.detail.value!)}
              onIonBlur={() => setIndustryBlurred(true)} onIonFocus={() => setIndustryBlurred(false)} required={true}>
              </IonInput>
              {industryBlurred && inputIndustry.trim() ==="" && <IonText color="danger">
                  <p className="ion-padding-start">
                    Industry is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 2 && <IonItem>
              <IonLabel position="stacked" color="primary">Admin First Name</IonLabel>
              <IonInput name="adminFirstName" type="text" value={inputAdminFirstName} onIonChange={e => setAdnminFirstName(e.detail.value!)}
              onIonBlur={() => setAdminFirstNameBlurred(true)} onIonFocus={() => setAdminFirstNameBlurred(false)} required={true}>
              </IonInput>
              {adminFirstNameBlurred && inputAdminFirstName.trim() === "" && <IonText color="danger">
                  <p className="ion-padding-start">
                    Admin First Name is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 2 && <IonItem>
              <IonLabel position="stacked" color="primary">Admin Last Name</IonLabel>
              <IonInput name="adminLastName" type="text" value={inputAdminLastName} onIonChange={e => setAdminLastName(e.detail.value!)}
              onIonBlur={() => setAdminLastNameBlurred(true)} onIonFocus={() => setAdminLastNameBlurred(false)} required={true}>
              </IonInput>
              {adminLastNameBlurred && inputAdminLastName.trim() === "" && <IonText color="danger">
                  <p className="ion-padding-start">
                    Admin Last Name is required.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 2 && <IonItem>
              <IonLabel position="stacked" color="primary">Email Address</IonLabel>
              <IonInput name="emailAddress" type="email" value={inputEmailAddress} onIonChange={e => setEmailAddress(e.detail.value!)}
              onIonBlur={() => setEmailAddressBlurred(true)} onIonFocus={() => setEmailAddressBlurred(false)} required={true}>
              </IonInput>
              {emailAddressBlurred && !isValidEmail && <IonText color="danger">
                  <p className="ion-padding-start">
                    Please enter a valid Email Address.
                  </p>
                </IonText>}
            </IonItem>}

            {formPage === 2 && <IonItem>
              <IonLabel position="stacked" color="primary">Phone Number</IonLabel>
              <IonInput name="phoneNumber" type="tel" value={inputPhoneNumber} onIonChange={e => setPhoneNumber(e.detail.value!)}
              onIonBlur={() => setPhoneNumberBlurred(true)} onIonFocus={() => setPhoneNumberBlurred(false)} required={true}>
              </IonInput>
              {phoneNumberBlurred && !isValidPhoneNumber && <IonText color="danger">
                  <p className="ion-padding-start">
                    Please enter a valid Phone Number.
                  </p>
                </IonText>}
            </IonItem>}


            <IonItem lines="none">
              <IonRouterLink className="link" routerLink={'/login'}>Already have an account?</IonRouterLink>
            </IonItem>

            {formPage === 1 && <IonButton onClick={pageForward} disabled={!canChangePages} expand="block">Next</IonButton>}
            {formPage === 2 && <IonButton onClick={pageBackward} expand="block">Previous</IonButton>}
            {formPage === 2 && <IonButton onClick={registerCompany} disabled={!canRegister} expand="block">Register</IonButton>}
          
          </form>
        <IonAlert
          isOpen={showAlert}
          backdropDismiss={false}
          onDidDismiss={() => setShowAlert(false)}
          header={"Registration Failed"}
          buttons={[
            {
              text: "OK",
            }
          ]}
        />
      </IonContent>
    )}
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    systemAdmin: state.user.systemAdmin,
  }),
  mapDispatchToProps: {
    setIsLoggedIn,
    setUserRole,
    setEmailAddress
  },
  component: Register
})