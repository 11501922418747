import React, { useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,

  IonPage,
} from "@ionic/react";

import InitiativesTop, { CountInfo } from "../components/InitiativesTop";
import { Initiative } from "@s360/common-models/initiative";

import "../components/modal.css";
import { useLocation, withRouter, RouteComponentProps, Link } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import { APIService } from "../services/APIService";
import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { Plugins } from '@capacitor/core';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { retrieveInitiatives } from "@s360/common-models/retrieveInitiatives";


let token: any;
let cid: string;
let decodedToken: any;

const { Storage } = Plugins;


interface OwnProps extends RouteComponentProps { }
interface ConsoleProps extends RouteComponentProps, UserState {
  props: any,
  token: string;
  sessionParams: any;
}

const ConsolePage: React.FC<ConsoleProps> = ({
  history,
  userRole
}) => {

  const [showModal, setShowModal] = useState(false);
  const [isNewInitiative, setIsNewInitiative] = useState(false);
  const [displayedInitiatives, setDisplayedInitiatives] = useState<
    Initiative[]
  >([]);
  const [categoryChanged, setCategoryChanged] = useState<boolean>();
  const [initiativeFetchRequest, setInitiativeFetchRequest] = useState(false);
  const [lastCategory, setLastCategory] = useState<string>();
  const location: any = useLocation();
  const [envCountInfo, setEnvCountInfo] = useState<CountInfo>();
  const [ecoCountInfo, setEcoCountInfo] = useState<CountInfo>();
  const [socCountInfo, setSocCountInfo] = useState<CountInfo>();
  const [colCountInfo, setColCountInfo] = useState<CountInfo>();


  React.useEffect(() => {
    retrieveInitiatives('');
  }, [])

  const setCountInfo = (initiatives: Initiative[]) => {
    let info = {
      env: { newCount: 0, progressCount: 0, doneCount: 0, allCount: 0 },
      eco: { newCount: 0, progressCount: 0, doneCount: 0, allCount: 0 },
      soc: { newCount: 0, progressCount: 0, doneCount: 0, allCount: 0 },
      col: { newCount: 0, progressCount: 0, doneCount: 0, allCount: 0 }
    }
    initiatives.forEach(initiative => {
      switch (initiative.category) {
        case 'Economy':
          info.eco.allCount++;
          if (initiative.trackingStatus === "New") info.eco.newCount++;
          if (initiative.trackingStatus === "In Progress") info.eco.progressCount++;
          if (initiative.trackingStatus === "Implemented") info.eco.doneCount++;
          break;
        case 'Society':
          info.soc.allCount++;
          if (initiative.trackingStatus === "New") info.soc.newCount++;
          if (initiative.trackingStatus === "In Progress") info.soc.progressCount++;
          if (initiative.trackingStatus === "Implemented") info.soc.doneCount++;
          break;
        case 'Environment':
          info.env.allCount++;
          if (initiative.trackingStatus === "New") info.env.newCount++;
          if (initiative.trackingStatus === "In Progress") info.env.progressCount++;
          if (initiative.trackingStatus === "Implemented") info.env.doneCount++;
          break;
        case 'Collaboration':
          info.col.allCount++;
          if (initiative.trackingStatus === "New") info.col.newCount++;
          if (initiative.trackingStatus === "In Progress") info.col.progressCount++;
          if (initiative.trackingStatus === "Implemented") info.col.doneCount++;
          break;
        case 'multi':
          info.env.allCount++;
          initiative.uniqueCategories!.forEach(category => {
            switch (category) {
              case 'Economy':
                info.eco.allCount++;
                if (initiative.trackingStatus === "New") info.eco.newCount++;
                if (initiative.trackingStatus === "In Progress") info.eco.progressCount++;
                if (initiative.trackingStatus === "Implemented") info.eco.doneCount++;
                break;
              case 'Society':
                info.soc.allCount++;
                if (initiative.trackingStatus === "New") info.soc.newCount++;
                if (initiative.trackingStatus === "In Progress") info.soc.progressCount++;
                if (initiative.trackingStatus === "Implemented") info.soc.doneCount++;
                break;
              case 'Environment':
                info.env.allCount++;
                if (initiative.trackingStatus === "New") info.env.newCount++;
                if (initiative.trackingStatus === "In Progress") info.env.progressCount++;
                if (initiative.trackingStatus === "Implemented") info.env.doneCount++;
                break;
              case 'Collaboration':
                info.col.allCount++;
                if (initiative.trackingStatus === "New") info.col.newCount++;
                if (initiative.trackingStatus === "In Progress") info.col.progressCount++;
                if (initiative.trackingStatus === "Implemented") info.col.doneCount++;
                break;
              default:
                break;
            }
          })
        default:
          break;
      }
    });
    setEnvCountInfo({ newCount: info.env.newCount, progressCount: info.env.progressCount, donePercentage: (info.env.allCount > 0 ? info.env.doneCount / info.env.allCount * 100 : 0) });
    setEcoCountInfo({ newCount: info.eco.newCount, progressCount: info.eco.progressCount, donePercentage: (info.eco.allCount > 0 ? info.eco.doneCount / info.eco.allCount * 100 : 0) });
    setSocCountInfo({ newCount: info.soc.newCount, progressCount: info.soc.progressCount, donePercentage: (info.soc.allCount > 0 ? info.soc.doneCount / info.soc.allCount * 100 : 0) });
    setColCountInfo({ newCount: info.col.newCount, progressCount: info.col.progressCount, donePercentage: (info.col.allCount > 0 ? info.col.doneCount / info.col.allCount * 100 : 0) });
  }


  const retrieveInitiatives = (category?: string) => {
    if (category !== lastCategory) {
      setCategoryChanged(true);
    }
    const apiService = new APIService();
    setLastCategory(category!);
    getObject().then(value => {
      let params: retrieveInitiatives = {
        category: category
      }
      apiService.retrieveInitiatives(params).then((result) => {
        setDisplayedInitiatives(result.body.initiatives);

        if (!category) setCountInfo(result.body.initiatives);
        setInitiativeFetchRequest(true);
      }).catch(error => {
        console.error(error)
      });
    })
  };



  if (!showModal && location.pathname.endsWith("addEx")) {
    setIsNewInitiative(false);
    setShowModal(true);
  }

  if (!showModal && location.pathname.endsWith("addNew")) {
    setIsNewInitiative(true);
    setShowModal(true);
  }

  async function getObject(): Promise<AuthenticationResponse> {
    return new Promise<AuthenticationResponse>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        resolve(JSON.parse(res.value!))
      }).catch(error => {
        reject(error);
      })
    })
  }

  try {
    token = Cookies.get('token');
    cid = Cookies.get('cid')!;
    decodedToken = jwt_decode(token);
  }
  catch (error) {
    console.error(error)
  }

  finally {
    if (typeof decodedToken === 'object' && decodedToken.hasOwnProperty('email')) {
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar color="primary">
              <IonGrid>
                <IonRow>
                  <IonCol><IonTitle size="large" style={{ color: 'white', marginTop: "8px" }}>Report Overview</IonTitle></IonCol>
                </IonRow>
              </IonGrid>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <InitiativesTop
              retrieveInitiatives={retrieveInitiatives}
              envCount={envCountInfo}
              ecoCount={ecoCountInfo}
              socCount={socCountInfo}
              colCount={colCountInfo}
            />
          </IonContent>
        </IonPage>
      )
    }
    else {
      window.open(process.env.REACT_APP_LOGIN_URL!, "_self");
    }
  }

  return (
    <></>
  )

};

export default connect<{}, UserState, {}>({

  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    username: state.user.emailAddress,
  }),
  component: withRouter(ConsolePage),
});
