import {
  IonNote,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonLabel,
  IonSelect,
  IonTextarea,
  IonSelectOption,

} from "@ionic/react";
import UserIonSelect from '../UserIonSelect'
import UserIonInput from '../UserIonInput'
import React, { useEffect, useState } from "react";
import { InitiativeDetailsProps } from "./InitiativeDetailsProps";
import UserIonTextarea from "../UserIonTextarea";
import { BusinessUnitService } from "../../services/BusinessUnitService";
import { BusinessUnit } from "@s360/common-models/businessUnit";
import { User } from "@s360/common-models/user";
import DatePicker from 'react-datepicker'
import { statusOptions } from "@s360/common-models/constants";
import { UserService } from "../../services/UserService";

const InitiativeDetails: React.FC<InitiativeDetailsProps> = (props) => {

  const businessUnitService: BusinessUnitService = new BusinessUnitService();
  const userService: UserService = new UserService()

  const [businessUnits, setBusinessUnits] = useState<Array<BusinessUnit>>();
  const [users, setUsers] = useState<Array<User>>();

  useEffect(() => {
    businessUnitService.getAllBusinessUnits().then((businessUnitsArray: Array<BusinessUnit>) => {
      setBusinessUnits(businessUnitsArray);
    }).catch(error => {
      console.error(error);
    });

    userService.getUsers().then((userList: Array<User>) => {
      setUsers(userList);
    }).catch(error => {
      console.error(error);
    });

  }, []);

  const labelTexts = {
    title: "Title",
    status: "Status",
    desc: "Description",
    progress: "Progress",
    goal: "UN Global Goals",
    target: "UN Targets",
    indi: "UN Indicators",
    obj: "Objective",
    category: "Category",
    businessUnit: "Business Unit",
    targetDate: "Target Date",
    ownerFullName: "Owner"
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size="9">
            <UserIonInput
              value={props.titleText}
              setValue={props.setTitleText}
              labelText={labelTexts.title}
              errorMessage={props.titleErrorMessage}
              maxLength={50}
              className={"border shadow-sm"}
              id={'initiative-title-input'}
            ></UserIonInput>
            <IonNote>{50 - props.titleText.length} characters left</IonNote>
          </IonCol>
          <IonCol>
            <UserIonSelect
              value={props.statusText}
              setValue={props.setStatusText}
              options={statusOptions}
              labelText={labelTexts.status}
              errorMessage={props.statusErrorMessage}
              disabled={props.statusDisabled}
              extraInfo={props.statusDescriptions}
              grayOptions={["Cancelled"]}
              required={true}
              className={"border shadow-sm"}
              id={'initiative-status-input'}
            ></UserIonSelect>
          </IonCol>
          {props.statusText === statusOptions[3] && (
            <IonCol >
              <UserIonSelect
                value={props.progressText}
                setValue={props.setProgressText}
                options={props.validProgress}
                labelText={labelTexts.progress}
                errorMessage={props.progressErrorMessage}
                disabled={props.progressDisabled}
                required={true}
                className={"border shadow-sm"}
                id={'initiative-progress-input'}
              ></UserIonSelect>
            </IonCol>
          )}

        </IonRow>

        <IonRow>
          <IonCol size="5">
            <IonRow>
              <IonLabel>{labelTexts.category}<IonLabel color="danger">*</IonLabel>&nbsp;</IonLabel>
              <IonNote color="danger">{props.categoryErrorMessage}</IonNote>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  className="category-btn w-100"
                  color={props.collaboration ? "primary" : 'light'}
                  onClick={() => props.setCollaboration(!props.collaboration)}>
                  Collaboration
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  className="category-btn w-100"
                  color={props.environment ? "primary" : 'light'}
                  onClick={() => props.setEnvironment(!props.environment)}>
                  Environment
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  className="category-btn w-100"
                  color={props.governance ? "primary" : 'light'}
                  onClick={() => props.setGovernance(!props.governance)}>
                  Governance
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  className="category-btn w-100"
                  color={props.society ? "primary" : 'light'}
                  onClick={() => props.setSociety(!props.society)}>
                  Society
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>

          <IonCol id="bu-select-area" size="2.5">
            <IonLabel>{labelTexts.businessUnit}</IonLabel>
            <IonSelect
              className="border shadow-sm w-100"
              interface="popover"
              placeholder={props.businessUnitName}
              id="bu-select"
              value={{
                businessUnitID: props.businessUnitID,
                businessUnitName: props.businessUnitName
              }}
              onIonChange={e => {
                props.setBusinessUnitID(e.detail.value.businessUnitID)
                props.setBusinessUnitName(e.detail.value.businessUnitName)
              }}>
              <IonSelectOption value={
                {
                  businessUnitID: undefined,
                  businessUnitName: "Assign to a Business Unit",
                }
              }>No Business Unit</IonSelectOption>
              {
                businessUnits?.map(function (unit, index) {
                  return <IonSelectOption key={index} value={
                    {
                      businessUnitID: unit.businessUnitID,
                      businessUnitName: unit.businessUnitName
                    }
                  }>{unit.businessUnitName}</IonSelectOption>
                })
              }
            </IonSelect>
          </IonCol>

          <IonCol id="owner-select-area" size="2.5">
            <IonLabel>{labelTexts.ownerFullName}</IonLabel>
            <IonSelect
              className="border shadow-sm w-100"
              interface="popover"
              placeholder={props.ownerFullName}
              id="owner-select"
              value={{
                fullName: props.ownerFullName,
                emailAddress: props.ownerEmailAddress
              }}
              onIonChange={e => {
                props.setOwnerFullName(e.detail.value.fullName)
                props.setOwnerEmailAddress(e.detail.value.emailAddress)
              }}>
              {
                users?.map(function (user: User, index: number) {
                  return <IonSelectOption key={index} value={
                    {
                      fullName: `${user.firstName} ${user.lastName}`,
                      emailAddress: user.emailAddress
                    }
                  }>{`${user.firstName} ${user.lastName}`}</IonSelectOption>
                })
              }
            </IonSelect>
          </IonCol>
          <IonCol id="target-date" size="1.95">
            <IonLabel>{labelTexts.targetDate}</IonLabel>
            <IonRow>
              <DatePicker
                className="shadow-sm"
                dateFormat="dd/MM/yyyy"
                id="target-date-input"
                onChange={(date: Date) => props.setTargetDate(date!.getTime())}
                placeholderText="DD/MM/YYYY"
                selected={props.targetDate ? new Date(props.targetDate) : undefined}
              />
            </IonRow>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12">
            <UserIonTextarea
              value={props.objText}
              setValue={props.setObjText}
              rows={1}
              labelText={labelTexts.obj}
              errorMessage={props.objErrorMessage}
              mandatory={false}
              className={"border shadow-sm"}
              id={'initiative-objective-input'}
            ></UserIonTextarea>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <UserIonTextarea
              value={props.descriptionText}
              setValue={props.setDesriptionText}
              rows={6}
              labelText={labelTexts.desc}
              errorMessage={props.descErrorMessage}
              mandatory={true}
              className={"border shadow-sm"}
              id={'initiative-description-input'}
            ></UserIonTextarea>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel position="fixed">
              <h2>Note</h2>
            </IonLabel>
            <IonTextarea
              rows={1}
              value={props.noteText}
              onIonChange={(e) => props.setNoteText(e.detail.value!.trim())}
              className="border shadow-sm"
              id={'initiative-note-input'}
            ></IonTextarea>
          </IonCol>
        </IonRow>
        <IonRow>

          {props.initiative && (<>
            <IonCol size="10" />
            <IonCol>
              <IonButton
                className="float-right"
                shape="round"
                onClick={() => {
                  props.retrieveInputs();
                }}>
                Save
              </IonButton>
            </IonCol>
          </>)}
          <IonCol>
            <IonButton

              className="float-right"
              shape="round"
              onClick={() => {
                props.checkInputs();
                props.setCurrentPage(2)
              }}>
              Next
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

    </>
  );

};

export default InitiativeDetails;