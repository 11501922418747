import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonPage,
  IonToast,
} from "@ionic/react";

import { useLocation, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import "./ReportPage.css";
import { Idea } from "@s360/common-models/idea";
import IdeaList from "../components/IdeaList";
import { HttpResponse } from "@s360/common-models/httpResponse";
import { HttpStatus } from "@s360/common-models/httpStatus";
import { IdeaService } from "../services/IdeaService";
import IdeaStats from "../components/IdeaStats";

interface OwnProps extends RouteComponentProps {}
interface IdeaPageProps extends RouteComponentProps, UserState {}

const IdeaPage: React.FC<IdeaPageProps> = ({ history }) => {
  const [ideaList, setIdeaList] = useState<Idea[]>([]);
  const [showGettingError, setShowGettingError] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const location = useLocation();
  const sessionParams = location.state;

  const afterAddedAction= () => {
    // setShowAcceptedToast(true)
    setRefreshKey(oldKey => oldKey +1);
  }
  
  const afterEditedAction= () => {
    setRefreshKey(oldKey => oldKey +1);
  }
  const getIdeas = () => {
    const ideaService = new IdeaService();
    
    ideaService
      .getIdeas()
      .then((response: HttpResponse) => {
        if (response.statusCode === HttpStatus.OK) {
          setIdeaList(response.body);
        } else {
          setShowGettingError(true);
        }
      })
      .catch((error) => {
        console.error("Error get ideas:", error);
        setShowGettingError(true);
      });
  };

  

  useEffect(() => {
    getIdeas();
  }, [refreshKey]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle color="light" size="large">Ideas List</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IdeaStats ideaList={ideaList}></IdeaStats>
          </IonRow>
          <IonRow>
            <IdeaList ideaList={ideaList} afterEditedAction={afterEditedAction}></IdeaList>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonToast
          isOpen={showGettingError}
          onDidDismiss={() => setShowGettingError(false)}
          message="Failed to get ideas"
          duration={1000}
          position="top"
          color="danger"
        />
        
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(IdeaPage),
});
