import React from "react";
import donutImage from "../images/InitiativePieChart-image.png"
import { IonGrid, IonImg, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel, IonButton } from "@ionic/react";
import '../css/InitiativesTop.css'
import { useHistory } from "react-router-dom";
export interface CountInfo {
  newCount: number;
  progressCount: number;
  donePercentage: number;
}

const InitiativesTop: React.FC<{
  retrieveInitiatives: (category: string) => void;
  envCount?: CountInfo;
  ecoCount?: CountInfo;
  socCount?: CountInfo;
  colCount?: CountInfo;
}> = (props) => {
  let history = useHistory();
  const redirectToInitiativeList = (category: string) => {

    history.push(`/console/${category}`)
    window.location.reload();

  }


  return (
    <IonGrid fixed id="grid">
      <IonRow>
        <IonCol size="1">
          <IonCard id="col-stats" onClick={() => { redirectToInitiativeList("collaboration") }}>
            <IonCardHeader>
              <IonCardTitle id="collaboration-card">COLLABORATION</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow><IonLabel>{props.colCount ? props.colCount.donePercentage.toFixed(1) : '--'}% DONE</IonLabel></IonRow>
                <IonRow><IonLabel>{props.colCount ? props.colCount.progressCount : '--'} IN PROGRESS</IonLabel></IonRow>
                <IonRow><IonLabel>{props.colCount ? props.colCount.newCount : '--'} NEW</IonLabel></IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard id="eco-stats" onClick={() => { redirectToInitiativeList("economy") }}>
            <IonCardHeader>
              <IonCardTitle id="economy-card">GOVERNANCE</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow><IonLabel>{props.ecoCount ? props.ecoCount.donePercentage.toFixed(1) : '--'}% DONE</IonLabel></IonRow>
                <IonRow><IonLabel>{props.ecoCount ? props.ecoCount.progressCount : '--'} IN PROGRESS</IonLabel></IonRow>
                <IonRow><IonLabel>{props.ecoCount ? props.ecoCount.newCount : '--'} NEW</IonLabel></IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol className="center" size="10">
          <div className="container">
            <IonImg src={donutImage} alt="logo" id="logo-pie"></IonImg>
            <span
              onClick={() => { redirectToInitiativeList("collaboration") }}
              id="col-button"
            ></span>
            <span
              onClick={() => { redirectToInitiativeList("society") }}
              id="soc-button"
            ></span>
            <span
              onClick={() => { redirectToInitiativeList("environment") }}
              id="env-button"
            ></span>
            <span
              onClick={() => { redirectToInitiativeList("economy") }}
              id="eco-button"
            ></span>
          </div>
        </IonCol>
        <IonCol size="1">
          <IonCard id="soc-stats" onClick={() => { redirectToInitiativeList("society") }}>
            <IonCardHeader>
              <IonCardTitle id="society-card">SOCIETY</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow><IonLabel>{props.socCount ? props.socCount.donePercentage.toFixed(1) : '--'}% DONE</IonLabel></IonRow>
                <IonRow><IonLabel>{props.socCount ? props.socCount.progressCount : '--'} IN PROGRESS</IonLabel></IonRow>
                <IonRow><IonLabel>{props.socCount ? props.socCount.newCount : '--'} NEW</IonLabel></IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard id="env-stats" onClick={() => { redirectToInitiativeList("environment") }}>
            <IonCardHeader>
              <IonCardTitle id="environment-card">ENVIRONMENT</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow><IonLabel>{props.envCount ? props.envCount.donePercentage.toFixed(1) : '--'}% DONE</IonLabel></IonRow>
                <IonRow><IonLabel>{props.envCount ? props.envCount.progressCount : '--'} IN PROGRESS</IonLabel></IonRow>
                <IonRow><IonLabel>{props.envCount ? props.envCount.newCount : '--'} NEW</IonLabel></IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>

  );
};

export default InitiativesTop;
