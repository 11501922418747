import React, { useState } from "react";
import { User } from "@s360/common-models/user";
import "./InitiativeList.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from "react-bootstrap";
import "./UsersList.css"
import { IonAlert, IonButton, IonGrid, IonIcon, IonModal, IonToast } from "@ionic/react";
import {
  createOutline,
  lockOpenOutline,
  trashOutline
} from "ionicons/icons";
import { dynamicSort } from "../helper/dynamicSort";
import { UserService } from "../services/UserService";
import { GeneralHttpResponse } from "@s360/common-models/apiResponses";
import UserForm from "./UserForm";
import { Roles } from "@s360/common-models/roles";

const UsersList: React.FC<{
  displayedUsers: User[];
  afterEditedAction: () => void;
}> = (props) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenResetPasswordDialog, setIsOpenResetPasswordDialog] = useState(false);
  const [showEditToast, setShowEditToast] = useState(false);
  const [showEditFailedToast, setShowEditFailedToast] = useState(false);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [showDeleteFailedToast, setShowDeleteFailedToast] = useState(false);
  const [showResetPasswordToast, setShowResetPasswordToast] = useState(false);
  const [showResetPasswordFailedToast, setShowResetPasswordFailedToast] = useState(false);
  
  const [targetUser, setTargetUser] = useState<User>();
  const userService : UserService= new UserService();

  const onResetPasswordButton = (user: User): void =>{
    setTargetUser(user);
    setIsOpenResetPasswordDialog(true);
  }

  const onDeleteButton = (user: User): void => {
    setTargetUser(user);
    setIsOpenDeleteDialog(true);
  }
  
  const onEditButton = (user: User): void => {
    setTargetUser(user);
    setIsOpenEditDialog(true);
  }
  
  const sendResetPasswordRequest = (): void => {
    if (!targetUser) return;

    userService.resetUserPassword(targetUser).then((result : GeneralHttpResponse) => {
        if (result.httpStatus == 200) {
          setShowResetPasswordToast(true);
        } else {
          setShowResetPasswordFailedToast(true);
        }
      })
      .catch((error) => {
        console.error("Error reset password:", error);
        setShowResetPasswordFailedToast(true);
      });
  }
  const deleteUser = (): void => {
    if (!targetUser) return;
    userService.deleteUser(targetUser).then((result : GeneralHttpResponse) => {
        if (result.httpStatus === 200) {
          setShowDeleteToast(true);
          props.afterEditedAction();
        } else {
          setShowDeleteFailedToast(true);
        }
      })
      .catch((error) => {
        console.error("Error delete user:", error);
        setShowDeleteFailedToast(true);
      });
  }

  const generateUserHeaders = (users: User[]): JSX.Element => {
    const userList:{userList: JSX.Element[]} = generateUserList(users);
    return (
      <>
        <tr>
          {/* <th className="idColumn">Id</th> */}
          <th >First Name</th>
          <th >Last Name</th>
          <th >Email Address</th>
          <th >Role</th>
          <th style={{width: '12rem'}}></th>
        </tr>
        {userList.userList}
      </>
    );
  };

  const getRoleStr = (role: Roles):'Administrator' | 'Staff' | 'Ideas' => {
    if(role == Roles.Admin) {
      return 'Administrator';
    } else if(role == Roles.Visitor) {
      return 'Ideas';
    } else {
      return 'Staff';
    }
  }
  const afterUpdated = (): void => {
    setShowEditToast(true);
    props.afterEditedAction();
  }

  const generateUserList = (users: User[]):{userList: JSX.Element[];} => {
    const sortedUsers: User[] = [...users].sort(dynamicSort('firstName'));
    const userList: JSX.Element[] = sortedUsers.map(function (user) {
        return (
          <tr key={user.id!}>
            {/* <td>{user.id}</td> */}
            <td >{user.firstName}</td>
            <td >{user.lastName}</td>
            <td >{user.emailAddress}</td>
            <td >{user.userRole ? getRoleStr(user.userRole) : ''}</td>
            <td >
              <IonButton color="light" onClick={() => {onResetPasswordButton(user)}}>
                  <IonIcon slot="icon-only"  icon={lockOpenOutline} />
              </IonButton >
              <IonButton color="light" onClick={() => {onEditButton(user)}} >
                  <IonIcon slot="icon-only" icon={createOutline} />
              </IonButton>
              <IonButton color="light" onClick={() => {onDeleteButton(user)}}>
                  <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            </td>
          </tr>
        );
      }
    );
    return { userList };
  };

  return (
    <IonGrid>
      <Table style={{marginRight: '0.5rem'}} striped bordered hover>
        <tbody>{generateUserHeaders(props.displayedUsers)}</tbody>
      </Table>
      <IonModal
          isOpen={isOpenEditDialog}
          onDidDismiss={() => setIsOpenEditDialog(false)}
          backdropDismiss={false}
        >
          <UserForm
            allUsers={props.displayedUsers}
            showToast={() => afterUpdated()}
            showErrorToast={(msg: string) => setShowEditFailedToast(true)}
            closeAction={() => setIsOpenEditDialog(false)}
            user={targetUser}
          ></UserForm>
        </IonModal>
      <IonAlert
          isOpen={isOpenResetPasswordDialog}
          backdropDismiss={false}
          onDidDismiss={() => setIsOpenResetPasswordDialog(false)}
          header={"Are you sure you want to reset the password?"}
          buttons={[
            {
              text: "OK",
              handler: () => {
                sendResetPasswordRequest();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        
        <IonAlert
          isOpen={isOpenDeleteDialog}
          backdropDismiss={false}
          onDidDismiss={() => setIsOpenDeleteDialog(false)}
          header={"Are you sure you want to delete this user?"}
          buttons={[
            {
              text: "OK",
              handler: () => {
                deleteUser();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonToast
          isOpen={showDeleteToast}
          onDidDismiss={() => setShowDeleteToast(false)}
          message="User is deleted successfully"
          duration={1000}
          position="top"
          color="success"
        />
        <IonToast
          isOpen={showDeleteFailedToast}
          onDidDismiss={() => setShowDeleteFailedToast(false)}
          message="Failed to delete user"
          duration={1000}
          position="top"
          color="danger"
        />
        <IonToast
          isOpen={showEditToast}
          onDidDismiss={() => setShowEditToast(false)}
          message="User is edited successfully"
          duration={1000}
          position="top"
          color="success"
        />
        <IonToast
          isOpen={showEditFailedToast}
          onDidDismiss={() => setShowEditFailedToast(false)}
          message={"Failed to edit user"}
          duration={1000}
          position="top"
          color="danger"
        />
        <IonToast
          isOpen={showResetPasswordToast}
          onDidDismiss={() => setShowResetPasswordToast(false)}
          message="Password Reset Email has been sent"
          duration={1000}
          position="top"
          color="success"
        />
        <IonToast
          isOpen={showResetPasswordFailedToast}
          onDidDismiss={() => setShowResetPasswordFailedToast(false)}
          message="Failed to send Password Reset Email"
          duration={1000}
          position="top"
          color="danger"
        />
      </IonGrid>
    
  );
};

export default UsersList;
