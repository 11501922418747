import { LoginResponse } from "@s360/common-models/apiResponses";
import Axios from "axios";
import { Plugins } from '@capacitor/core';
import { AuthenticationResponse } from '@s360/common-models/authenticationResponse';
import { setSession } from '../helper/sessionManagement';
import { Session } from '@s360/common-models/session'

const { Storage } = Plugins;

export class LoginService {
  private authParams: AuthenticationResponse;

  constructor() {
    this.authParams = {
      token: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      companyID: '',
      userRole: '',
      systemAdmin: false,
    }
  }
  public async Login(emailAddress: string, plainPassword: string): Promise<LoginResponse> {
    console.log(`Login is being called`);
    return new Promise(async (resolve, reject) => {
      // let encodedPassword: string = Buffer.from(plainPassword).toString(
      //   "base64"
      // );
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      }
      const loginAPIURL = process.env.REACT_APP_API_LOGIN_URL || "";
      const params = {
        emailAddress: emailAddress,
        password: plainPassword
      }

      try {
        Axios.interceptors.request.use(req => {
          return req;
        })
         Axios.interceptors.response.use((res) => {
           if(res){
            if (res.status === 200) {
              if (res.data.companyID) {
                const bearerToken = res.data.token;
                const session: Session = {
                  companyID: res.data.companyID,
                  token: res.data.token,
                  activeSession: true,
                  userRole: res.data.userRole,
                  loggedIn: true
                }
                setSession(session);
                const userLoginResponse: LoginResponse = {
                  status: res.status,
                  token: bearerToken,
                  user: {
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    emailAddress: res.data.emailAddress,
                    companyID: res.data.companyID,
                    userRole: res.data.userRole,
                    systemAdmin: res.data.systemAdmin
                  }
                }
                console.log(`[userLoginResponse]: ${JSON.stringify(userLoginResponse)}`)
                this.authParams = {
                  token: bearerToken,
                  firstName: res.data.firstName,
                  lastName: res.data.lastName,
                  emailAddress: res.data.emailAddress,
                  companyID: res.data.companyID,
                  userRole: res.data.userRole,
                  systemAdmin: res.data.systemAdmin
                }
  
                //console.log(`This is the userLoginResponse ---->${JSON.stringify(userLoginResponse)}`)
                this.setObject();
                resolve(userLoginResponse)
              }
            }
            return res;
           }

           else {
            const loginErrorResponse: LoginResponse = {
              status: 401
            }
            resolve(loginErrorResponse);
           }
           return res;  //This must be changed. Tricking the function by this return. If there is a second failed login attempt, the above reolve be used to return the errorResponse. Did this because for some reason, every subsequent failed login attempts is also going into the success scenario.
            
          
        }, (error) => {
          if (error.response.status === 401 || 500) {
            const userLoginResponse: LoginResponse = {
              status: error.response.status,
            }
            console.log(`[userLoginResponse][error]: ${userLoginResponse}`);
            resolve(userLoginResponse);
          }
        })
        await Axios.post(loginAPIURL, params, config);

      } catch (e) {
        console.log(`Request failed: ${e}`);

      }
    });
  }

  async setObject() {
    await Storage.set({
      key: 'sessionParams',
      value: JSON.stringify({
        token: this.authParams.token,
        firstName: this.authParams.firstName,
        lastName: this.authParams.lastName,
        emailAddress: this.authParams.emailAddress,
        companyID: this.authParams.companyID,
        isLoggedIn: true,
        userRole: this.authParams.userRole,
        systemAdmin: this.authParams.systemAdmin
      })
    })
  }
}