import { IonButton } from "@ionic/react";
import { retrieveInitiatives } from "@s360/common-models/retrieveInitiatives";
import { Initiative } from "@s360/common-models/initiative"
import React from "react";
import "../css/InitiativeExport.css";
import { exportAsExcel, exportAsExcelParams } from "../helper/exportAsExcel"
import { APIService } from "../services/APIService";
import { dateConverter } from "../helper/DateConverter";

const InitiativeExport: React.FC<{}> = () => {

  const apiService = new APIService();

  const getLateInitiatives = (): Promise<any[][]> => {
    let params: retrieveInitiatives = {
      isOverDue: true
    }
    return new Promise<any[][]>(async (resolve, reject) => {
      apiService.retrieveInitiatives(params).then(result => {
        const data = result.body.initiatives.map((item: Initiative ) => {
          return [item.title, item.category, dateConverter(new Date(item.targetDate!)), item.progress, item.trackingStatus]
        })
        resolve(data);
      })
    })
  }

  const exportInitiatives = () => {
    const columnNames = [
      "Title",
      "Category",
      "Target Date",
      "Progress",
      "Status",
    ]

    getLateInitiatives().then(data => {
      const exportParams: exportAsExcelParams = {
        data: data,
        workSheetColumnNames: columnNames,
        workSheetName: "Initiatives",
        filePath: `Initiatives_Overdue_Generated_${dateConverter(new Date())}.xlsx`,
      }
      exportAsExcel(exportParams);
    })
  }

  return (
      <IonButton
        id="initiative-export"
        onClick={() => exportInitiatives()}>
          Export Initiatives
      </IonButton>
  )
}

export default InitiativeExport;