import React, { useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonNote,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import UserIonInput from "./UserIonInput";
import UserIonTextarea from "./UserIonTextarea";
import { Idea } from "@s360/common-models/idea";
import { IdeaStatus } from "@s360/common-models/ideaStatus";
import { IdeaService } from "../services/IdeaService";
import { useHistory } from "react-router";
import { Plugins } from '@capacitor/core';
import '../css/app.scss';
import { Roles } from "@s360/common-models/roles";
import { User } from "@s360/common-models/user";
const { Storage } = Plugins;

const labelTexts = {
  title: "Title",
  desc: "Description",
  obj: "Objective"
};

const errorMessages = {
  title: "Title text is required",
  desc: "Description is required",
  general: "This information is required",
};

const VisitorInitiativeModal: React.FC<{
  closeAction: () => void;
  // showToast: () => void;
  successAction: () => void;
}> = (props) => {

  const ideaService = new IdeaService();
  const [titleText, setTitleText] = useState<string>("");
  const [descriptionText, setDesriptionText] = useState<string>("");
  const [statusDisabled, setStatusDisabled] = useState<boolean>(false);

  const [showClearAlert, setClearAlert] = useState(false);
  const [showCloseAlert, setCloseAlert] = useState(false);

  const [titleErrorMessage, setTitleErrorMessage] = useState<string>("");
  const [descErrorMessage, setDescErrorMessage] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const history = useHistory();

  React.useEffect(() => {

    if (submitDisabled) {
      checkInputs();
    }
  }, [
    descriptionText,
    titleText,
  ]);


  async function getUserName(): Promise<User> {
    return new Promise<User>(async (resolve, reject) => {
      await Storage.get({ key: 'sessionParams' }).then(res => {
        const authDetails = JSON.parse(res.value!);
        const user: User = {
          firstName: authDetails.firstName,
          lastName: authDetails.lastName,
          emailAddress: authDetails.emailAddress,
          userRole: authDetails.userRole,
        }
        resolve(user)
      }).catch(error => {
        reject(error);
      })
    })
  }

  //Check if inputs validation are correct, then send POST to API, then show reminder alert
  const submitInputs = async () => {

    await getUserName().then((user: User) => {
      const userName = user.firstName + ' ' + user.lastName;
      if (
        titleText.trim() &&
        descriptionText.trim()
      ) {
        let idea: Idea = {
          id: null,
          ideaId: null,
          title: titleText,
          description: descriptionText,
          ideaStatus: IdeaStatus.New,
        };

        if (user.userRole === Roles.User) {
          idea.author = userName;
        }

        ideaService.submitIdea(idea).then((result) => {
          if (result.status === 200) {
            props.successAction();
          }
        
        });
      }
    });

  };

  //Set error messages and disable/enable submit button
  const checkInputs = () => {
    titleText.trim()
      ? setTitleErrorMessage("")
      : setTitleErrorMessage(errorMessages.title);
    descriptionText.trim()
      ? setDescErrorMessage("")
      : setDescErrorMessage(errorMessages.desc);
    if (
      titleText.trim() &&
      descriptionText.trim()
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const retrieveInputs = () => {
    checkInputs();
    submitInputs();
  };

  const clearInputs = () => {
    setDesriptionText("");
    setTitleText("");
  };

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            {"Submit your idea here"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setClearAlert(true)}>Clear</IonButton>
            <IonButton onClick={() => setCloseAlert(true)}>
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding scrollable">
        <IonGrid className="max-width">
          <IonRow>
            <IonCol>
              <UserIonInput
                value={titleText}
                setValue={setTitleText}
                labelText={labelTexts.title}
                errorMessage={titleErrorMessage}
                maxLength={50}
              ></UserIonInput>
              <IonNote>{50 - titleText.length} characters left</IonNote>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <UserIonTextarea
                value={descriptionText}
                setValue={setDesriptionText}
                rows={10}
                labelText={labelTexts.desc}
                errorMessage={descErrorMessage}
                mandatory={true}
              ></UserIonTextarea>
            </IonCol>
          </IonRow>
          <IonRow>
            
              <div className="buttonContainer">
                <button
                  id="ideaSubmitButton"
                  onClick={e => {
                    e.preventDefault();
                    retrieveInputs();
                  }}
                  className="btn btn-success"><h6>Submit</h6></button>
              </div>
            
          </IonRow>
        </IonGrid>
        <IonAlert
          isOpen={showClearAlert}
          backdropDismiss={false}
          onDidDismiss={() => setClearAlert(false)}
          header={"Are you sure you want to clear all entered inputs"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                clearInputs();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
        <IonAlert
          isOpen={showCloseAlert}
          backdropDismiss={false}
          onDidDismiss={() => setCloseAlert(false)}
          header={"Are you sure you want to exit back to console page"}
          buttons={[
            {
              text: "Okay",
              handler: () => {
                props.closeAction();
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </>
  );
};

export default VisitorInitiativeModal;
