import React, { useState } from "react";
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText } from '@ionic/react';
import { RouteComponentProps } from "react-router";
import ForgotUsernameMessage from "../components/ForgotUsernameMessage";

const ForgotUsername: React.FC<RouteComponentProps> = (props) => {
  const [email, setEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailBlankError, setEmailBlankError] = useState(false);
  const [emailInvalidError, setEmailInvalidError] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  
  const sendMail = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if(!email) {
      setEmailBlankError(true);
      return;
    }

    const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if(!emailValid) {
      setEmailInvalidError(true);
      return;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle size="large">Forgot Your Username</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        { sentEmail 
          ? <ForgotUsernameMessage email={email}></ForgotUsernameMessage>
          : <div>
              <p className="ion-padding-start">
                Please confirm the email address you signed up with and we will send your username to you.
              </p>
              <form noValidate onSubmit={sendMail}>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked" color="primary">Email</IonLabel>
                    <IonInput name="email" type="text" value={email} spellCheck={false} autocapitalize="off" onIonChange={e => setEmail(e.detail.value!)}
                      required>
                    </IonInput>
                  </IonItem>

                  {formSubmitted && emailBlankError && <IonText color="danger">
                    <p className="ion-padding-start">
                      Email Address is required
                    </p>
                  </IonText>}
                  {formSubmitted && emailInvalidError && <IonText color="danger">
                    <p className="ion-padding-start">
                      Email Address is invalid
                    </p>
                  </IonText>}
                </IonList>

                <IonRow>
                  <IonCol>
                    <IonButton type="submit" expand="block">Send Email</IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton routerLink="/login" color="light" expand="block">Cancel</IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default ForgotUsername;
