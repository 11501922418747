import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonPage,
  IonIcon,
  IonModal,
  IonToast,
  IonText,
} from "@ionic/react";

import _ from "lodash";

import { useLocation, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import "./ReportPage.css";
import { addOutline } from "ionicons/icons";
import { MainObjective } from "@s360/common-models/mainObjective";
import MainObjectiveList from "../components/MainObjectiveList";
import { HttpResponse } from "@s360/common-models/httpResponse";
import { HttpStatus } from "@s360/common-models/httpStatus";
import { MainObjectiveService } from "../services/MainObjectiveService";
import MainObjectiveForm from "../components/MainObjectiveForm";
import "../components/modal.css";
import { APIService } from './../services/APIService';
import { Initiative } from '@s360/common-models/initiative';
import ChildTable from "../components/ChildTable";
import Pagination from "../common/Pagination";
import { paginate } from './../utils/Paginate';


interface OwnProps extends RouteComponentProps { }
interface MainObjectivePageProps extends RouteComponentProps, UserState { }

const MainObjectivePage: React.FC<MainObjectivePageProps> = ({ history }) => {
  const [mainObjectiveList, setMainObjectiveList] = useState<MainObjective[]>([]);
  const [showGettingError, setShowGettingError] = useState<boolean>(false);
  const [isOpenMainObjectiveDialog, setIsOpenMainObjectiveDialog] = useState<boolean>(false);
  const [showAddedToast, setShowAddedToast] = useState<boolean>(false);
  const [showAddFailedToast, setShowAddFailedToast] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [currentInitiatives, setCurrentInitiatives] = useState<Initiative[]>();
  const [displayedInitiatives, setDisplayedInitiatives] = useState<Initiative[]>();
  const location = useLocation();
  const [targetTakenInitiativesList, setTargetTakenInitiativesList] = useState<string[]>([]);
  const [generateTable, setGenenerateTable] = useState<boolean>(false);
  const [currentMainObjective, setCurrentMainObjective] = useState<MainObjective>();
  const [showAll, setShowAll] = useState<boolean>();
  const sessionParams = location.state;


  const [pageSize, setPageSize] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);


  const afterAddedAction = () => {
    setShowAddedToast(true);
    setRefreshKey(oldKey => oldKey + 1);
  }

  const afterEditedAction = () => {
    setRefreshKey(oldKey => oldKey + 1);
  }
  const getMainObjectives = () => {
    const mainObjectiveService = new MainObjectiveService();
    setGenenerateTable(false);

    mainObjectiveService
      .getMainObjectives()
      .then((response: HttpResponse) => {
        if (response.statusCode === HttpStatus.OK) {
          setMainObjectiveList(response.body);
          generateTargetTakenInitiativesList(response.body);
        } else {
          setShowGettingError(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowGettingError(true);
      });
  };

  const generateTargetTakenInitiativesList = (mainObjectives: MainObjective[]) => {
    let targetTakenInitiativesList: string[] = [];
    mainObjectives.forEach(objective => {
      objective.initiativeIds.forEach(initiativeId => {
        targetTakenInitiativesList.push(initiativeId);
      })
    })
    setTargetTakenInitiativesList(targetTakenInitiativesList);
  };

  const generateCurrentInitiatives = () => {
    const apiService = new APIService();
    apiService.retrieveInitiatives().then((result) => {

      handlePageChange(currentPage, result.body.initiatives);
      setCurrentInitiatives(result.body.initiatives);
    }).catch(error => {
      console.error(error);
    })



  };

  const handlePageChange = (page: number, data: Initiative[] | undefined, size?: number) => {
    if (size) {
      setPageSize(size);
    }

    setCurrentPage(page);



    if (data === undefined) {
      data = currentInitiatives;
    }
    getPageData(data, page, size);
    if (page === 1 && size !== data?.length) {
      setShowAll(true);
    }

    else if (pageSize === size) {
      setShowAll(false);
    }
    else if (page !== 1) {
      setShowAll(false);
    }

    else {
      setShowAll(false)
    }

  }

  const getPageData = (data: any, newPageNumber: number, size?: number) => {
    const initiatives = paginate(data, newPageNumber, size ? size : pageSize);
    setDisplayedInitiatives(initiatives);

    return { totalCount: data?.length, data: initiatives };
  };

  const targetClicked = (mainObjective: MainObjective) => {
    setPageSize(5);
    setCurrentMainObjective(mainObjective);
    displayInitiatives(mainObjective, true);
    setShowAll(true);
  }

  const showAllClicked = (mainObjecive: MainObjective) => {
    setCurrentMainObjective(mainObjecive);
    displayInitiatives(mainObjecive, false);
    setPageSize(mainObjecive.initiativeIds.length)
    setShowAll(false);

  }

  const displayInitiatives = (mainObjective: MainObjective, initialLoad?: boolean) => {
    const targetInitiativeIds = mainObjective.initiativeIds;


    //retrieve all initiatives
    const apiService = new APIService();
    apiService.retrieveInitiatives().then((result) => {
      let allInitiatives: Initiative[] = result.body.initiatives;


      let filteredInitiatives: Initiative[] = [];
      targetInitiativeIds.forEach(iID => {
        allInitiatives.forEach(initiative => {
          if (iID === initiative.id) {
            filteredInitiatives.push(initiative);
          }
        })
      })
    
      if (initialLoad) {
        handlePageChange(currentPage, filteredInitiatives);
        setCurrentInitiatives(filteredInitiatives);
        setGenenerateTable(true);

      }
      else {
        setPageSize(filteredInitiatives.length)
        handlePageChange(currentPage, filteredInitiatives, filteredInitiatives.length);

        setCurrentInitiatives(filteredInitiatives);
        setGenenerateTable(true);
      }




    }).catch(error => {
      console.error(error);
    })

  }

  useEffect(() => {
    getMainObjectives();
    generateCurrentInitiatives();

  }, [refreshKey]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle size="large" style={{ color: 'white' }}>Goals</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton class="ion-float-right"
                onClick={() => setIsOpenMainObjectiveDialog(true)}>
                <IonIcon slot="start" icon={addOutline} />
                  Add Goal
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <MainObjectiveList
              mainObjectiveList={mainObjectiveList}
              afterEditedAction={afterEditedAction}
              targetTakenInitiativesList={targetTakenInitiativesList}
              onTargetClicked={targetClicked}
            ></MainObjectiveList>
          </IonRow>
        </IonGrid>
        {
          generateTable && (
            <>
              <ChildTable displayedInitiatives={displayedInitiatives} />
              <IonGrid>
                <IonRow>
                  <IonCol size="9"></IonCol>
                  <div

                    onClick={() => showAllClicked(currentMainObjective!)}

                    style={{
                      marginRight: "1rem",
                      paddingTop: ".75rem",
                      cursor: "pointer"
                    }}>
                    <IonCol size="1" >
                      {
                        currentInitiatives?.length! > 5 && showAll &&

                        <IonText>Show all
                        </IonText>

                      }
                      {
                        !showAll &&

                        <IonText style={{
                          marginRight: "4rem",
                          paddingTop: ".75rem",
                          cursor: "pointer"
                        }}>
                        </IonText>
                      }
                    </IonCol>
                  </div>

                  <IonCol>

                    <Pagination
                      itemsCount={currentInitiatives?.length}
                      //Change later to be flexible
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />


                  </IonCol>
                </IonRow>

              </IonGrid>



            </>

          )

        }

      </IonContent>


      <IonModal
        isOpen={isOpenMainObjectiveDialog}
        onDidDismiss={() => setIsOpenMainObjectiveDialog(false)}
        backdropDismiss={false}
        cssClass="objective-modal"
      >
        <MainObjectiveForm
          showToast={() => afterAddedAction()}
          showErrorToast={(msg: string) => setShowAddFailedToast(true)}
          closeAction={() => setIsOpenMainObjectiveDialog(false)}
          targetTakenInitiativesList={targetTakenInitiativesList}
        ></MainObjectiveForm>
      </IonModal>
      <IonToast
        isOpen={showGettingError}
        onDidDismiss={() => setShowGettingError(false)}
        message="Failed to get targets"
        duration={1000}
        position="top"
        color="danger"
      />
      <IonToast
        isOpen={showAddedToast}
        onDidDismiss={() => setShowAddedToast(false)}
        message={"Successfully added target"}
        duration={1000}
        position="top"
        color="success"
      />
      <IonToast
        isOpen={showAddFailedToast}
        onDidDismiss={() => setShowAddFailedToast(false)}
        message={"Failed to add target"}
        duration={1000}
        position="top"
        color="danger"
      />
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(MainObjectivePage),
});
