import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonSplitPane, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonImg, IonCol } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Session } from '@s360/common-models/session';

import Menu from "./components/Menu"
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPasswordReset from './pages/ForgotPasswordReset';
import { ResetForm } from "./pages/ResetPasswordForm";
import { VisitorRegistrationForm } from "./pages/VisitorRegistrationForm";
import ChangePassword from "./pages/ChangePassword";
import VisitorLanding from "./pages/VisitorLanding";
import AnotherIdea from './pages/ideaSubmitSuccess';
import Success from "./pages/Success";
import ConsolePage from "./pages/ConsolePage";
import ForgotUsername from "./pages/ForgotUsername";
import ForgotPassword from "./pages/ForgotPassword";
import ReportPage from "./pages/ReportPage";
import BusinessUnitsPage from "./pages/BusinessUnitsPage"
import UsersPage from "./pages/UsersPage";
import IdeaPage from "./pages/IdeaPage";
import MainObjectivePage from "./pages/MainObjectivePage";
import ReportMainObjective from "./pages/ReportMainObjective";
import { CookiesProvider } from "react-cookie";
import ReportCarbon from "./pages/ReportCarbon";
import * as serviceWorker from "./serviceWorker";
import { Roles } from "@s360/common-models/roles";
import { activeSession } from './helper/sessionManagement';


import "./css/app.scss"
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { connect } from './data/connect';
import { AppContextProvider } from "./data/AppContext";
import { setIsLoggedIn, setEmailAddress, setUserRole } from './data/user/user.actions';
import ReportOverview from "./pages/ReportOverview";
import logo from "./images/S360.png"
import GlobalGoalsPage from "./pages/GlobalGoalsPage";
import Cookies from 'js-cookie';
import { SessionAlert } from './components/sessionAlert';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import AuthActivityPage from "./pages/AuthActivityPage";
import BcorpPage from "./pages/BcorpPage/BcorpPage";
import BcorpReportOverview from "./pages/BcorpReportOverview/BcorpReportOverview";



serviceWorker.unregister();

serviceWorker.register({
  onUpdate: registration => {
    registration.unregister().then(() => {
      window.location.reload();
    })
  }
})


const App: React.FC = () => {

  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUserRole: typeof setUserRole;
  setUsername: typeof setEmailAddress;
}

interface IonicAppProps extends DispatchProps { }

const appsessionCheck = async (): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    let loginCheckComplete: boolean = false;
    let userType: Roles = Roles.User;
    try {
      let token = Cookies.get('token');
      if (token?.length == 0) {
        loginCheckComplete = true;
      }
    }
    catch (error) {
      console.error(error)
      loginCheckComplete = true
    }

    resolve({
      loginCheckComplete,
      userType
    })
  })
}

const IonicApp: React.FC<IonicAppProps> = () => {

  const [loginCheck, setLoginCheck] = useState<boolean>(false);
  const [showTimeOutAlert, setShowTimeOutALert] = useState<boolean>(false);

  React.useEffect(() => {
    appsessionCheck().then(setLoginCheck).catch(error => {
      console.error(error);
    })
  }, [setLoginCheck])
  

  setInterval(()=>{
    try {
    const session: Session = JSON.parse(Cookies.get('session')!);
    
    if(session.loggedIn){
      const sessionToken: string = Cookies.get('token')!;
      const cognitoAccessToken: CognitoAccessToken = new CognitoAccessToken({AccessToken: sessionToken});
      const exp: number = cognitoAccessToken.getExpiration();
      const currentTime: number = Math.round(Date.now() / 1000);
      if(currentTime > exp) {
        activeSession(false)
        setShowTimeOutALert(true)
      } else{
        activeSession(true) 
        setShowTimeOutALert(false);
      } 
    }
  }
   catch(error){
    console.error(error)
   }
  },2000)

  return (
    <CookiesProvider>
      <IonApp>
        <IonReactRouter>
          <IonHeader >
            <IonToolbar >
              <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
              </IonButtons>
              <IonTitle><IonImg src={String(logo)} alt="logo" id="logo"></IonImg></IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
              <IonSplitPane contentId="main">
                <Menu />
                {loginCheck && <IonRouterOutlet id="main">
                  <Route path="/report" component={ReportOverview} exact={true} />
                  <Route path="/bcorp_report" component={BcorpReportOverview} exact={true} />
                  <Route path="/report_detail" component={ReportPage} exact={false} />
                  <Route path="/console" component={ConsolePage} exact={false} />
                  <Route path="/forgot_username" component={ForgotUsername} exact={true} />
                  <Route path="/forgot_reset/:source" component={ForgotPassword} exact={true} />
                  <Route path="/login/:source" component={Login} exact={true} />
                  <Route path="/register" component={Register} exact={true} />
                  <Route path="/reset_password/:email_address" component={ResetForm} />
                  <Route path="/visitor_account_registration/:company_id" component={VisitorRegistrationForm} />
                  <Route path="/success" component={Success} exact={true} />
                  <Route path="/idea-new" component={VisitorLanding} exact={true} />
                  <Route path="/ideaSubmit" component={AnotherIdea} exact={true} />
                  <Route path="/business_units" component={BusinessUnitsPage} exact={true} />
                  <Route path="/users" component={UsersPage} exact={true} />
                  <Route path="/auth_activity" component={AuthActivityPage} exact={true} />
                  <Route path="/idea" component={IdeaPage} exact={true} />
                  <Route path="/goals" component={MainObjectivePage} exact={true} />
                  <Route path="/bcorp" component={BcorpPage} exact={true} />
                  <Route exact path="/" render={() => <Redirect to="/login/initiativePortal" />} />
                  <Route path="/report_carbon" component={ReportCarbon} exact={false} />
                  <Route path="/report_target" component={ReportMainObjective} exact={true} />
                  <Route path="/forgot_password/:email_address/:source" component={ForgotPasswordReset} exact={true} />
                  <Route path="/change_password" component={ChangePassword} exact={true} />
                  <Route path="/global_goals_dashboard" component={GlobalGoalsPage} exact={true} />
                </IonRouterOutlet>}
              </IonSplitPane>
            { showTimeOutAlert &&
              <SessionAlert showAlert={showTimeOutAlert} />}
          </IonContent>
        </IonReactRouter>
      </IonApp>
    </CookiesProvider>
  );
}

export default App;

const IonicAppConnected = connect<{}, DispatchProps>({
  mapDispatchToProps: { setIsLoggedIn, setEmailAddress, setUserRole },
  component: IonicApp
});
