import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import BcorpIdeaList from "../../components/BcorpIdeaList/BcorpIdeaList"
import React, { useState } from "react";
import BcorpUploadModal from "../../components/BcorpUploadModel/BcorpUploadModal";


const BcorpPage: React.FC = () => {
    const [isUploadModelOpen, setIsUptloadModelOpen] = useState(false);

    const handleUpload = (): void => {
        setIsUptloadModelOpen(true)
    }

    const handleOnDidDismiss = (): void => {
        setIsUptloadModelOpen(false)
    }

    const handleCloseAction = (): void => {
        setIsUptloadModelOpen(false)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonTitle size="large" style={{ color: 'white', marginTop: "8px" }}>B Corp</IonTitle>
                            </IonCol>
                            <IonCol size="2.52">
                                <IonButton
                                    onClick={handleUpload}
                                >
                                    Upload Improvement Report
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <BcorpIdeaList/>
                <IonModal
                    isOpen={isUploadModelOpen}
                    onDidDismiss={handleOnDidDismiss}

                    backdropDismiss={false}
                >
                    <BcorpUploadModal
                        closeAction={handleCloseAction}
                    />
                </IonModal>
            </IonContent>
        </IonPage>
    )
}

export default BcorpPage;