import React from "react";
import { Link } from "react-router-dom";

const ForgotPasswordMessage: React.FC<{
  email: string;
  source: string;
}> = (props) => {
  return (
    <>
    <div className="alert alert-success" role="alert"><p>An email with a reset link has been sent to <b>{props.email}</b></p>
     <p><Link to={'/login/'+props.source}>Back to login page</Link></p></div>
        <br/>
        {/* <Link to='/login'>Back to Login Page</Link> */}
    </>
  );
};

export default ForgotPasswordMessage;
