import React from "react";
import { ProgressBar } from "react-bootstrap";
import "./ReportHorizontalStepper.css"

function isActiveStep(value: string, checkName:string): boolean {
  switch (checkName) {
    case "Reviewed":
      return (value !== "New");
    case "Scoped":
      return (value === "Scoped" || value === "In Progress");
    case "In Progress":
      return (value === "In Progress");
    default:
      return false;
  }
}

const getProgressValue = (status: string, progress: string): number => {
  let currentProgress: number = 0;
  switch (status) {
    case 'In Progress':
      currentProgress = progress ? Number(progress.replace('%', '')) : 0;
      break;
    case 'Implemented':
      currentProgress = 100;
    default:
      break;
  }
  return currentProgress;
};


const ReportHorizontalStepper: React.FC<{
  value: string;
  progress: string;
}> = (props) => {
  return (
    <div className="progressbar-wrapper">
      <ul className="progressbar">
          <li className="active">New</li>
          <li className={(isActiveStep(props.value,"Reviewed") ? "active" : "")}>Reviewed</li>
          <li className={(isActiveStep(props.value,"Scoped") ? "active" : "")}>Scoped</li>
          <li id="in-progress-bar" className={(isActiveStep(props.value,"In Progress") ? "active" : "")}>
            <ProgressBar
              className='in-progress-bar'
              striped
              variant="info"
              now={getProgressValue(props.value, props.progress)}
            />
            In Progress
          </li>
          <li>Implemented</li>
      </ul>
    </div>
  );
};

export default ReportHorizontalStepper;
