import Axios from "axios";
import { Plugins } from '@capacitor/core';
import { AuthenticationResponse } from "@s360/common-models/authenticationResponse";
import { HttpResponse } from '@s360/common-models/httpResponse';
import Cookies from 'js-cookie';

const { Storage } = Plugins;

export class CarbonService {

    private authorizationToken: string;
    constructor() {
        this.authorizationToken = this.getAuthorizationToken();
    }

    getAuthorizationToken = (): string => {
        let token = Cookies.get('token') + '';
        return token;
      }
    
    private async getObject() : Promise<AuthenticationResponse> {
        return new Promise<AuthenticationResponse>(async (resolve, reject)=>{
             Storage.get({key: 'sessionParams'}).then(res=>{
                 resolve(JSON.parse(res.value!))
             }).catch(error=>{
                 reject(error);
             })
        })
    }


    public async getEmissionFactors(): Promise<HttpResponse> {
        return new Promise(async (resolve,reject)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request = {};
                const getFactorsURL = process.env.REACT_APP_API_CARBON_GET_EMISSION_FACTORS_API_URL || "";
                Axios.post(getFactorsURL, request, config).then((value)=>{
                    let result : HttpResponse = { body: value.data, statusCode: value.status, headers: value.headers};
                    resolve(result);
                }).catch((error)=> {
                    reject(error);
                })
        })
    }

    public async getYearlyReportData(levels: string[]): Promise<HttpResponse> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request = {
                    companyID: value.companyID,
                    levels: levels
                }
                const getDataURL = process.env.REACT_APP_API_GET_CARBON_YEAR_REPORT_URL || "";
                Axios.post(getDataURL, request, config).then((value)=>{
                    let result : HttpResponse = { body: value.data, statusCode: value.status, headers: value.headers};
                    resolve(result);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }

    public async getMonthlyReportData(levels: string[], year: string): Promise<HttpResponse> {
        return new Promise(async (resolve,reject)=>{
            this.getObject().then(async (value)=>{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${this.authorizationToken}`
                    }
                }
                const request = {
                    companyID: value.companyID,
                    levels: levels,
                    year: year
                }
                const getDataURL = process.env.REACT_APP_API_GET_CARBON_MONTH_REPORT_URL || "";
                Axios.post(getDataURL, request, config).then((value)=>{
                    let result : HttpResponse = { body: value.data, statusCode: value.status, headers: value.headers};
                    resolve(result);
                }).catch((error)=> {
                    reject(error);
                })
            })
            
        })
    }
    
}