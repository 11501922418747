import React, { useState, useEffect } from 'react';
import { IonItem, IonPage, IonInput, IonLabel, IonButton, IonHeader, IonToolbar, IonContent, IonTitle, IonLoading } from '@ionic/react';
import { useParams } from 'react-router-dom';
import '../css/app.scss';
import { ResetPassword } from '@s360/common-models/resetPassword'
import { APIService } from '../services/APIService';
import Cookies from 'js-cookie';

const ForgotPasswordReset: React.FC = () => {

    const [newPassword, setNewPassword] = useState<string>("");
    const [retypePassword, setRetypePassword] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [validPassword, setValidPassword] = useState<boolean>(false);
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
    const [incorrectPasswordError, setIncorrectPasswordError] = useState<string>("");
    const [passwordMismatchError, setPasswordMismatchError] = useState<string>("");
    const handle: any = useParams();
    const [encryptedEmail, setEncryptedEmail] = useState<string>("");
    const [source, setSource] = useState<string>("");
    const [showLoading, setShowLoading] = useState<boolean>(false);

    
    useEffect(()=>{
       setSource(handle['source']);
    },[])
    useEffect(() => {
        if (newPassword === retypePassword && (newPassword !== "" && retypePassword !== "")) {
            setPasswordMatch(true);
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
            setPasswordMatch(false);
        }
        if (!validPassword) {
            setIncorrectPasswordError("The password must be of minimum eight characters containing at least one letter, one number and one special character")
        } else {
            setIncorrectPasswordError("");
        }

        if (!passwordMatch) {
            setPasswordMismatchError("The passwords do not match");
        }
        else {
            setPasswordMismatchError("");
        }
        newPassword &&
            newPassword.search(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)
            ? setValidPassword(false)
            : setValidPassword(true);
    })
     


    async function resetSubmit() {
        setShowLoading(true);
        const resetDetails : ResetPassword = {
            newPassword: newPassword,
            emailAddress: encryptedEmail
        }
        const apiService = new APIService();
        await apiService.resetForgotPassword(resetDetails).then((value: any)=>{
            if(value.status===200){
                const reset = 'reset';
                Cookies.set('passwordReset', reset, {domain:process.env.REACT_APP_DOMAIN });
                setShowLoading(false);
                if(source==='initiativePortal'){
                    const consoleUrl = process.env.REACT_APP_CONSOLE_URL!;
                    window.open(consoleUrl, '_self')
                }
                else if(source==='cc'){
                    const ccUrl = process.env.REACT_APP_CC_URL!;
                    window.open(ccUrl,'_self');
                }
                
            }
        }).catch(error=>{
            console.error(error)
            setShowLoading(false);
        })
    }

    useEffect(()=>{
        setEncryptedEmail(handle['email_address'])
    },[handle])
    
    document.onkeypress = (e) => {
        if (e.keyCode == 13 && canSubmit) {
          resetSubmit();
        }
      }

    return (
        <IonPage id='login-registration-page'>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonTitle>Forgot Password</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <form className="login-registration-form ion-padding">
                    <IonItem>
                        <IonLabel position="stacked" color='primary'>New Password:</IonLabel>
                        <IonInput name="newPassword" type="password" value={newPassword} onIonChange={e => setNewPassword(e.detail.value!)}></IonInput>
                    </IonItem>
                    <div style={{ color: "red", fontSize: "70%" }}>
                        <p>{!validPassword && incorrectPasswordError}</p>
                    </div>
                    <br />
                    <IonItem>
                        <IonLabel position="stacked" color='primary'>Retype Password:</IonLabel>
                        <IonInput name="retypePassword" type="password" value={retypePassword} onIonChange={e => setRetypePassword(e.detail.value!)}></IonInput>
                    </IonItem>
                    <div style={{ color: "red", fontSize: "70%" }}>
                        <p>{!passwordMatch && retypePassword !== "" && passwordMismatchError}</p>
                    </div>
                    <br />
                    <IonButton disabled={!canSubmit} onClick={()=>{
                        resetSubmit();
                        }} expand="block">Submit</IonButton>
                </form>
                <IonLoading
                  isOpen={showLoading}
                  onDidDismiss={()=>{
                      setShowLoading(false);
                  }}
                  message={'Please wait while we reset your password.....'}
                />
            </IonContent>
        </IonPage>
    )
}

export default ForgotPasswordReset;