import { IonCol, IonContent, IonHeader, IonImg, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../data/connect";
import { UserState } from "../data/user/user.state";
import { getSdgIcon } from "../helper/SdgIconGetter";
import "../css/GlobalGoal.css"
import { APIService } from "../services/APIService";
import { Initiative } from "@s360/common-models/initiative";
import { Roles } from "@s360/common-models/roles";
import { retrieveInitiatives } from "@s360/common-models/retrieveInitiatives";


interface GlobalGoalsProps extends RouteComponentProps, UserState {}

const GlobalGoalsPage: React.FC<GlobalGoalsProps> = ({
  history,
  userRole
}) => {

  const goals: string[] = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09',
    '10', '11', '12', '13', '14', '15', '16', '17']

  const statusOptions: string[] = [
    "IN PROGRESS",
    "NEW",
    "REVIEWED",
    "SCOPED",
    "ON HOLD",
    "IMPLEMENTED",
    "CANCELLED",
 ]

  const [statusFilter, setStatusFilter] = useState<string>('');
  const [displayedInitiatives, setDisplayedInitiatives] = useState<Initiative[]>([]);
  const [goalCount, setGoalCount] = useState<number[]>(Array(17).fill(0));

  const apiService: APIService = new APIService();

  const goalCounter = (initiatives: Initiative[]): void => {
    let tempGoalCount: Array<number> = new Array(17).fill(0);
    goals.forEach((goal, index) => {
      const goalNumber = parseInt(goal).toString();
      initiatives.forEach(initiative => {
        if (initiative.goals.includes(goalNumber)) tempGoalCount[index] += 1;
      })
    })
    setGoalCount(tempGoalCount);
  }

  React.useEffect(() => {
    let params: retrieveInitiatives = {
      status: statusFilter
    }
    apiService.retrieveInitiatives(params)
      .then(result => {
        setDisplayedInitiatives(result.body.initiatives);
        goalCounter(result.body.initiatives);
      })
      .catch(error => {
        console.error(error);
      })
  }, [statusFilter])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle color="light" size="large">SDGs</IonTitle>
        </IonToolbar>
      </IonHeader>
      {userRole === Roles.Admin && (<IonContent>
        <IonRow>
          <IonCol></IonCol>
          <IonCol size="3">
          <IonSelect
                      class="border shadow-sm"
                      style={{marginTop: "0.45rem", height: "75%"}}
                      interface="popover"
                      placeholder="ALL"
                      id="ionSelect-bu"
                      onIonChange={e => { setStatusFilter(e.detail.value) }}>
                      <IonSelectOption value="all">ALL</IonSelectOption>
                      {statusOptions?.map(function (status, index) {
                          return <IonSelectOption key={index} value={status}>{status}</IonSelectOption>
                      })}
                    </IonSelect>
          </IonCol>
        </IonRow>
        <div id="global-goals">
          {goals.map(function(goal, index) {
            return (<div id="sdg-icon">
              {goalCount[index] > 0 && (<IonLabel id="number-bubble">{goalCount[index]}</IonLabel>)}
              <IonImg id="img-goal" src={getSdgIcon(goal)}/>
            </div>
            )
          })}
          <IonImg id="img-wheel" src={getSdgIcon('wheel')} />
        </div>
      </IonContent>)}
    </IonPage>
  );
};

export default connect<{}, UserState, {}>({
  mapStateToProps: (state) => ({
    loading: false,
    isLoggedin: state.user.isLoggedin,
    userRole: state.user.userRole,
    emailAddress: state.user.emailAddress,
  }),
  component: withRouter(GlobalGoalsPage),
});