import React, { useEffect, useState } from "react";
import { UserActivityLog, UserActivityLogRequest } from "@s360/common-models/activityLog";
import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import { AuthActivityService } from "../services/AuthActivityService";
import AuthActivityList from "./AuthActivityList";
import { UserCognitoLog } from "@s360/common-models/user";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/AuthActivity.css';

const AuthActivityStats: React.FC<{
  startDate: number;
  endDate: number;
}> = (props) => {
  const [uniqueLogins, setUniqueLogins] = useState<UserActivityLog[]>([]);
  const [allLoginAttempts, setAllLoginAttempts] = useState<UserActivityLog[]>([]);
  const [successfulLoginAttempts, setSuccessfulLoginAttempts] = useState<UserActivityLog[]>([]);
  const [failedLoginAttempts, setFailedLoginAttempts] = useState<UserActivityLog[]>([]);
  const [activatedUsers, setActivatedUsers] = useState<UserCognitoLog[]>([]);
  const [nonActivatedUsers, setNonActivatedUsers] = useState<UserCognitoLog[]>([]);
  const [displayedUserActivityLogs, setDisplayedUserActivityLogs] = useState<UserActivityLog[]>([]);
  const [displayedUserCognitoLogs, setDisplayedUserCognitoLogs] = useState<UserCognitoLog[]>([]);
  const [cognito, setCognito] = useState<boolean>(false);
  const [activated, setActivated] = useState<boolean>(false);
  const [selectedStats, setSelectedStats] = useState<number>();

  const authActivityService: AuthActivityService = new AuthActivityService();

  let request: UserActivityLogRequest = {
    companyID: '',
    startDate: props.startDate,
    endDate: props.endDate,
  }

  const authActivityList: (UserActivityLog[] | UserCognitoLog[])[] = [
    uniqueLogins,
    allLoginAttempts,
    successfulLoginAttempts,
    failedLoginAttempts,
    activatedUsers,
    nonActivatedUsers,
  ]

  const authActivityLabels: string[] = [
    "USERS LOGGED IN",
    "LOGIN ATTEMPTS",
    "SUCCESSFUL LOGINS",
    "FAILED LOGINS",
    "ACTIVATED USERS",
    "NON-ACTIVATED USERS",
  ]

  useEffect(() => {
    authActivityService.getActivatedUsers().then((result: UserCognitoLog[]) => {
      setActivatedUsers(result);
    }).catch((error: any) => {
      console.log(error);
    })
  }, [selectedStats]);

  useEffect(() => {
    authActivityService.getNonActivatedUsers().then((result: UserCognitoLog[]) => {
      setNonActivatedUsers(result);
    }).catch((error: any) => {
      console.log(error);
    })
  }, [selectedStats])

  useEffect(() => {
    request.startDate = props.startDate;
    request.endDate = props.endDate;
  }, [props.startDate, props.endDate, selectedStats])

  useEffect(() => {
    authActivityService.getUniqueUserLogins(request).then((result: UserActivityLog[]) => {
      setUniqueLogins(result);
    }).catch((error: any) => {
      console.log(error);
    })
  }, [props.startDate, props.endDate, selectedStats])

  useEffect(() => {
    authActivityService.getAllLoginAttempts(request).then((result: UserActivityLog[]) => {
      setAllLoginAttempts(result);
    }).catch((error: any) => {
      console.log(error);
    })
  }, [props.startDate, props.endDate, selectedStats])

  useEffect(() => {
    authActivityService.getSuccessfulLoginAttempts(request).then((result: UserActivityLog[]) => {
      setSuccessfulLoginAttempts(result);
    }).catch((error: any) => {
      console.log(error);
    })
  }, [props.startDate, props.endDate, selectedStats])

  useEffect(() => {
    authActivityService.getFailedLoginAttempts(request).then((result: UserActivityLog[]) => {
      setFailedLoginAttempts(result);
    }).catch((error: any) => {
      console.log(error);
    })
  }, [props.startDate, props.endDate, selectedStats]);

  const setDisplay = (authActivityLogs: any[], index: number): void => {
    setSelectedStats(index);
    if (index <= 3) {
      setCognito(false);
      setActivated(false);
      setDisplayedUserActivityLogs(authActivityLogs);
      setDisplayedUserCognitoLogs([]);
    } else if (index === 4) {
      setCognito(true);
      setActivated(true);
      setDisplayedUserCognitoLogs(authActivityLogs);
      setDisplayedUserActivityLogs([]);
    } else if (index === 5) {
      setCognito(true);
      setActivated(false);
      setDisplayedUserCognitoLogs(authActivityLogs);
      setDisplayedUserActivityLogs([]);
    }
  }

  return (
    <IonGrid>
      <IonRow>
        { 
          authActivityList.map((authActivityLogs: UserActivityLog[] | UserCognitoLog[], index: number) => {
            return (
              <IonCol className={`activity-stats-card${index === selectedStats ? ' active' : ''}`} size="3.92"
                onClick={() => {setDisplay(authActivityLogs, index)}}
              >
                <IonRow className="activity-stats-row">
                  <IonLabel className="activity-counter">
                    {authActivityLogs.length}
                  </IonLabel>
                </IonRow>
                <IonRow className="activity-stats-row">
                    <IonLabel className="activity-counter-label">
                      {authActivityLabels[index]}
                    </IonLabel>
                </IonRow>
              </IonCol>
          )})
        }
      </IonRow>
      <IonRow>
        <AuthActivityList
          userActivityList={displayedUserActivityLogs}
          userCognitoList={displayedUserCognitoLogs}
          cognito={cognito}
          activated={activated}
        />
      </IonRow>
    </IonGrid>
  );
};

export default AuthActivityStats;
